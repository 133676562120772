import { uploadMasterLeadsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
import { UPLOAD_MASTER_LEAD, UPLOAD_MASTER_LEAD_ERR } from 'context/actionTypes/MasterType';
export const uploadMasterLead = (payload) => async (dispatch) => {
    try {
        let results = await axios.post(`${uploadMasterLeadsApi}`, payload, await accessToken())
        //  console.log("createBookingApi", payload)
        let data = results && results.data
        dispatch({
            type: UPLOAD_MASTER_LEAD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_MASTER_LEAD_ERR,
            payload: error.response,
        });
    }
};


