import React from 'react';
import { styles } from "./MasterTableStylePage";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom'
import MasterTable from 'components/masterTable';

const useStyles = makeStyles(styles);

const MasterTablePage = React.memo((props) => {
    return (
        <div>
            <MasterTable />
        </div>
    )
})

export default withRouter(MasterTablePage);
