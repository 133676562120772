import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./AssignProjectStyle";
// import AssignProject from "../../../components/project/assignProject";
import ProjectActions from "../../../components/project/projectActions";
const Booking = React.memo((props) => {
  useEffect(() => {
  }, [])
  return (
    <>
     <ProjectActions/>
    </>
  );
});

export default withRouter(Booking);
