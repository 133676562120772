/***********************  ADD PROJECT ****************************************/

import { CREATE_LEADS_TABLE,CREATE_LEADS_TABLE_ERR} from '../../actionTypes/leadsType'
import { createLeadsTableApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'


export const createLeadsTable = payload => async dispatch => {
    try {
        let results = await axios.post(`${createLeadsTableApi}`, payload, await accessToken())
         console.log("CREATE LEADS TABLE", results)
        let data = results && results.data
        dispatch({
            type: CREATE_LEADS_TABLE,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: CREATE_LEADS_TABLE_ERR,
            payload: error.response
        });
    }
}

/***********************  ADD PROJECT ****************************************/