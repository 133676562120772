import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CallMissedIcon from '@mui/icons-material/CallMissed';
import { withRouter, Link, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Pagination from "@material-ui/lab/Pagination";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AssignLeadTableStyle";
import PlusIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Avatar, CardContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import { useForm, Form } from "../../useForm";
import CallIcon from "@mui/icons-material/Call";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import IconButton from "@material-ui/core/IconButton";
import { TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import InfiniteScroll from "react-infinite-scroll-component";
import { GlobalContext } from "../../../context/GlobalState";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import VerifiedIcon from "@mui/icons-material/Verified";
import XLSX from "xlsx";
import LinearProgress from '@mui/material/LinearProgress'
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, DialogContent, DialogTitle } from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { getUserLeads } from "context/actions/leadsAction/getUserLeads";
import { getLeadsFields } from "context/actions/leadsAction/getLeadsFields";
import { getProjectUsers } from "context/actions/projectAction/getProjectUsers";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { deleteAssignLead } from "context/actions/leadsAction/removeAssignLead";
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { getUserLeadsDownload } from "context/actions/leadsAction/getUserLeadsDownload";
import { getLeadHistory } from "context/actions/leadsAction/getLeadHistory";
import LeadHistory from "../leadHistory";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getUserLeadsDownloadReport } from "context/actions/leadsAction/getUserLeadsReportDownload";
import { reassignLead } from "context/actions/leadsAction/reassignLead";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: "#B8B8B8",
  },
  selected: {
    color: "#602994",
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: 'white',
    },
  },
}))(TableRow);


const ProjectTable = React.memo((props) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [paginationCount, setPaginationCount] = React.useState(1);
  const [loadingLoop, setLoadingLoop] = useState(true);
  const descriptionElementRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loaderData, setLoaderData] = useState(false);

  const [scroll, setScroll] = React.useState("paper");
  const [openShow, setOpenShow] = React.useState(false);
  const [selectedDate, handleDateChanges] = useState([null, null]);
  const [page, setPage] = React.useState(1);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [filterDate, setFilterDate] = React.useState(
    moment(new Date()).utc().format("YYYY-MM-DD")
  );
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [statusChange, setStatusChange] = React.useState("");
  const [attendanceStatus, setAtteandanceStatus] = React.useState("all");
  const [taskerValue, setTaskerValue] = React.useState("");
  const [searched, setSearched] = useState("");
  const [subStatus, setSubStatus] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [popupValue, setPopupValue] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [selected, setSelected] = React.useState([]);
  let project_id =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [popupImage, setPopupImage] = useState("");
  const [leadsAssignTableHeader, setLeadsAssignTableHeader] = useState([]);
  const [leadsAssignTableBody, setLeadsAssignTableBody] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [totalAcceptLead, setTotalAcceptLead] = React.useState(0);
  const [totalFollowupLead, setTotalFollowupLead] = React.useState(0);
  const [nonContactedLead, setNonContactedLead] = React.useState(0);
  const [totalFreshLead, setTotalFreshLead] = React.useState(0);
  const [totalLeads, setTotalLeads] = React.useState(0);
  const [totalRejectLead, setTotalRejectLead] = React.useState(0);
  const [totalCallDuration, setTotalCallDuration] = React.useState(0);
  const [totalNonContactLeads, setTotalNonContactLeads] = React.useState(0);
  const [subStatusChange, setSubStatusChange] = useState('all');

  const acceptSubStatus = [
    { id: 'key_done_taskmo', title: 'Key Done Taskmo' },
    { id: 'key_done_customer_itself', title: 'Key Done Customer Itself' }]

  const followUpSubStatus = [
    { id: 'technical_issue', title: 'Technical Issue' },
    { id: 'call_back_request', title: 'Call Back Request' },
    { id: 'will_do_on_my_own', title: 'Will Do On My Own' },
    { id: 'language_issue', title: 'Language Issue' },
    { id: 'partially_onboard', title: 'Partially Onboard' },
    { id: 'others', title: 'Others' }
  ]

  const nonContactSubStatus = [
    { id: 'call_not_getting_connected', title: 'Call Not Getting Connected' },
    { id: 'rnr', title: 'RNR' },
    { id: 'switched_off', title: 'Switched Off' },
    { id: 'disconnecting_the_call', title: 'Disconnecting the Call' }]

  const rejectSubStatus = [
    { id: 'wrong_number', title: 'Wrong Number' },
    { id: 'dnd', title: 'DND' },
    { id: 'no_smart_phone', title: 'No Smartphone' },
    { id: 'number_does_not_exist', title: 'Number Does Not Exist' },
    { id: 'using_abusive_language', title: 'Using Abusive Language' },
    { id: 'not_in_lead_of_money', title: 'Not In Lead of Money' },
    { id: 'not_intimated_by_company/employer', title: 'Not Intimated by Company/Employer' },
    { id: 'fake_call', title: 'Fake Call' },
    { id: 'hr_inform_not_to_use_refyne', title: 'Hr Inform not to use Refyne' },
    { id: 'no_document', title: 'No Document' },
    { id: 'wrong_onboarding', title: 'Wrong Onboarding' },
  ]

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsAssignTableBody.map((n) => n.lead_data.lead_id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    console.log("projectState", projectState);
    let status = projectState.getProjectUsers.status;
    if (status === true) {
      const verifyFiltered = projectState.getProjectUsers.data.filter(
        (project) => project.onboard_status == "completed"
      );
      setUsersData(verifyFiltered);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProjectUsers);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProjectUsers);
    }
  }, [projectState]);

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        style={{ width: "100%" }}
        sx={{
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Grid container spacing={2}>
          <Grid sm={2} item style={{ display: "flex", alignItems: "center" }}>
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              ></Typography>
            )}
          </Grid>
          <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
            <FormControl variant="standard" style={{ width: "100%" }} required>
              <InputLabel id="demo-simple-select-standard-label">
                Tasker
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={userId}
                label="Select Tasker"
                onChange={handleChangeTasker}
              >
                {usersData.map((item) => {
                  return (
                    <MenuItem value={item.user_id}>
                      {item.full_name} ({item.mobile_number})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
            <FormControl variant="standard" style={{ width: "100%" }} required>
              <InputLabel id="demo-simple-select-standard-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={statusChange}
                label="Select Status"
                onChange={handleChangeAssignStatus}
              >
                <MenuItem value="fresh">Fresh</MenuItem>
                <MenuItem value="followup">Follow Up</MenuItem>
                <MenuItem value="accept">Accept</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sm={2} item>
            {numSelected > 0 ? (
              <Tooltip title="Re-Assign" style={{ float: "right" }}>
                <IconButton>
                  {/* <DeleteIcon /> */}
                  <Button
                    className={classes.saveBtn}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#125693" }}
                  >
                    {loader ? (
                      <CircularProgress style={{ color: "#fff" }} size={28} />
                    ) : (
                      "ReAssign"
                    )}
                  </Button>
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" align="center">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Tasker
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Lead ID
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Status
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Creation Date
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Update Date
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Total Call Duration
          </TableCell>
          {leadsAssignTableHeader.map((headCell) => {
            console.log("headCell", headCell);
            return headCell.key != "created_on" &&
              headCell.key != "updated_on" &&
              headCell.key != "rn" &&
              headCell.key != "durations" ? (
              <TableCell align="left">{headCell.key}</TableCell>
            ) : (
              ""
            );
          })}
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            Action
          </TableCell>
          <TableCell align="left" style={{ minWidth: 100 }} key={1}>
            History
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    let count = newPage - 1;
    setPage(newPage);
    setLoaderData(true)
    //console.log('count', count)
    setPaginationCount(newPage);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        }
      }
    }
  };

  useEffect(() => {
    getProjectUsers({ project_id: project_id, page: "all" })(projectDispatch);
  }, []);

  function handleClr(e) {
    setLoaderData(true)
    e.stopPropagation();
    handleDateChanges([null, null]);
    setPage(1);
    setLoadingLoop(true);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    if (searched == "") {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: searched,
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  }

  const handleDateChange = (event) => {
    //console.log('event', event)
    handleDateChanges(event);
  };

  useEffect(() => {
    setLoaderData(true)
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log("leadsState", leadsState);

    /************************** ASSIGN LEAD ************************************* */
    let assign_lead_status =
      leadsState.reassignLead && leadsState.reassignLead.status;
    // SUCCESS RESPONSE

    if (assign_lead_status === true) {
      setSuccessMessage("Lead  Re-Assigned successfully");

      handleDeleteOpen();
      leadsState.reassignLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleDeleteClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (assign_lead_status === 500) {
      console.log("Server Error:", leadsState.reassignLead);
      // setFailureMessage('Lead Already Assigned')
      handleDeleteOpen();
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (assign_lead_status === 401) {
      // setFailureMessage("Worng username or password")
      console.log("Validation Error:", leadsState.reassignLead);
    }

    /*****************************  ASSIGN LEAD ********************************** */

    let status =
      leadsState.getUserLeads &&
      leadsState.getUserLeads.data &&
      leadsState.getUserLeads.status;
    if (status == true) {
      setLoaderData(false)
      console.log("leadsState.getUserLeads", leadsState.getUserLeads.data);
      if (leadsState.getUserLeads.data.res.length != 0) {
        setLeadsAssignTableHeader(
          leadsState.getUserLeads.data.res[0].lead_details
        );
        setLeadsAssignTableBody(leadsState.getUserLeads.data.res);
        setTotalPage(leadsState.getUserLeads.data.total_page);
        setTotalAcceptLead(leadsState.getUserLeads.data.total_accept_lead);
        setTotalFollowupLead(leadsState.getUserLeads.data.total_followup_lead);
        setNonContactedLead(leadsState.getUserLeads.data.total_non_contacted_lead)
        setTotalFreshLead(leadsState.getUserLeads.data.total_fresh_lead);
        setTotalLeads(leadsState.getUserLeads.data.total_leads);
        setTotalRejectLead(leadsState.getUserLeads.data.total_reject_lead);
        setTotalCallDuration(leadsState.getUserLeads.data.total_call_duration);
        setTotalNonContactLeads(leadsState.getUserLeads.data.total_non_contacted_lead)
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
  }, [leadsState]);

  useEffect(() => {
    // console.log('leadsState', leadsState)
    let status =
      leadsState.getUserLeadsDownload &&
      leadsState.getUserLeadsDownload.data &&
      leadsState.getUserLeadsDownload.status;
    if (status == true) {
      console.log("leadsState.getUserLeads", leadsState.getUserLeadsDownload);
      setLoader(false);
      if (leadsState.getUserLeadsDownload.data.res.length != 0) {
        // setLeadsAssignTableHeader(leadsState.getUserLeads.data.res[0].lead_details)
        // setLeadsAssignTableBody(leadsState.getUserLeads.data.res)
        // setTotalPage(leadsState.getUserLeads.data.total_page)
        downloadExcel(leadsState.getUserLeadsDownload.data.res);
        leadsState.getUserLeadsDownload = {
          loading: false,
          error: null,
          data: null,
        };
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
  }, [leadsState]);

  useEffect(() => {
    // console.log('leadsState', leadsState)
    let status =
      leadsState.getUserLeadsDownloadReports &&
      leadsState.getUserLeadsDownloadReports.data &&
      leadsState.getUserLeadsDownloadReports.status;
    if (status == true) {
      console.log(
        "leadsState.getUserLeads",
        leadsState.getUserLeadsDownloadReports
      );
      setLoader1(false);
      if (leadsState.getUserLeadsDownloadReports.data.res.length != 0) {
        downloadExcelReport(leadsState.getUserLeadsDownloadReports.data.res);
        leadsState.getUserLeadsDownloadReports = {
          loading: false,
          error: null,
          data: null,
        };
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
  }, [leadsState]);

  useEffect(() => {
    console.log("getDownloadRecord", leadsState.getDownloadRecord);
    let status =
      leadsState.getDownloadRecord &&
      leadsState.getDownloadRecord.data &&
      leadsState.getDownloadRecord.status;
    if (status == true) {
      leadsState.getDownloadRecord = {
        loading: false,
        error: null,
        data: null,
      };
    }
  }, [leadsState]);

  const requestSearch = (event) => {
    setLoaderData(true)
    if (event.key === "Enter") {
      setPage(1);
      setLeadsAssignTableHeader([]);
      setLeadsAssignTableBody([]);
      setLoadingLoop(true);
      setSearched(event.target.value);

      if (selectedDate[0] == null && selectedDate[1] == null) {
        if (event.target.value == "") {
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: "all",
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: taskerValue,
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          }
        } else {
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: "all",
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: taskerValue,
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          }
        }
      } else {
        if (searched == "") {
          let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: "all",
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: taskerValue,
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          }
        } else {
          let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: "all",
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: taskerValue,
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          }
        }
      }
    }
  };

  const cancelSearch = () => {
    setLoaderData(true)
    setSearched("");
    requestSearch(searched);
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    } else {
      let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
      let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    }
  };

  const handleChangeStatus = (event) => {
    setAtteandanceStatus(event.target.value);
  };

  const history = useHistory();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ITEM_HEIGHT = 48;

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const report_columns = [
    {
      label: "No",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      label: "Tasker",
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "City", label: "City", minWidth: 60 },
    { id: "Phone", label: "Phone", minWidth: 60 },
    { id: "Check In ", label: "Check In Selfie" },
    { id: "Check In ", label: "Check In Time" },
    {
      id: "Check Out Selfie",
      label: "Check Out Selfie",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Check Out Time",
      label: "Check Out Time",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Total Hours (hh:mm)",
      label: "Total Hours (h:m)",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Target",
      label: "Target",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Achieved Target",
      label: "Achieved Target",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Status",
      label: "Status",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  const downloadExcel = (downloadData) => {
    const headers = [
      "No",
      "Tasker",
      "Mobile Number",
      "Status",
      "Date",
      "Note",
      "Follwup Date",
      "Followup Status",
      "call Duration",
      "Roundup Call Duration",
    ];
    leadsAssignTableHeader &&
      leadsAssignTableHeader.map((item) => {
        if (
          item.key != "created_on" &&
          item.key != "updated_on" &&
          item.key != "rn"
          // item.key != "total_count"
        ) {
          headers.push(item.key);
        }
      });
    let finalHeader = [...headers];
    const records = [];
    downloadData &&
      downloadData.map((item, index) => {
        const innerData = [];

        if (item.lead_data.call_duration != "00:00:00" && item.lead_data.call_duration) {
          var m = moment(item.lead_data.call_duration, "hh:mm:ss");
          var roundUp =
            m.second() || m.millisecond()
              ? m.add(1, "minute").startOf("minute")
              : m.startOf("minute") ||
                m.minute() ||
                m.second() ||
                m.millisecond()
                ? m.add(1, "hour").startOf("hour")
                : m.startOf("hour");
          console.log("roundUp", new Date(roundUp.toString()));
          var date = new Date(roundUp.toString());
          var hours = ("0" + date.getHours()).slice(-2);
          var minutes = ("0" + date.getMinutes()).slice(-2);
          var roundUpDuration = `${hours}:${minutes}:00`;
        } else {
          item.lead_data.call_duration="00:00:00"
          roundUpDuration = "00:00:00";
        }

        if (item.lead_data.call_status == "followup") {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,
            moment(item.lead_data.created_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.created_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            moment(item.lead_data.call_followup_date).format(
              "DD-MM-YYYY hh:mm A"
            ),
            item.lead_data.call_followup_status,
            item.lead_data.call_duration,
            roundUpDuration
          );
        } else {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,
            moment(item.lead_data.created_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.created_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            "",
            "",
            item.lead_data.call_duration,
            roundUpDuration
          );
        }
        const innerData1 = [];
        {
          item.lead_details.map((inside_row, inside_index) => {
            if (
              inside_row.key != "created_on" &&
              inside_row.key != "updated_on" &&
              inside_row.key != "rn"
            ) {
              if (inside_row.key == "durations") {
                var d = Number(inside_row.value);
                var h = Math.floor(d / 3600);
                var m = Math.floor((d % 3600) / 60);
                var s = Math.floor((d % 3600) % 60);

                var hDisplay =
                  h > 0 ? ("0" + h).slice(-2) + (h == 1 ? "" : "") : "00";
                var mDisplay =
                  m > 0 ? ("0" + m).slice(-2) + (m == 1 ? "" : "") : "00";
                var sDisplay =
                  s > 0 ? ("0" + s).slice(-2) + (s == 1 ? "" : "") : "00";
                var durations = `${hDisplay} : ${mDisplay} : ${sDisplay}`;
                innerData1.push(durations);
              } else {
                innerData1.push(inside_row.value);
              }
            }
          });
        }
        records.push([...innerData, ...innerData1]);
      });
    const data = [finalHeader, ...records];
    const newData = records;
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    //Download

    XLSX.writeFile(workBook, "lead-history-report.xlsx");
  };

  const downloadExcelReport = (downloadData) => {
    console.log('downloadData', downloadData)
    const headers = [
      "No",
      "Tasker",
      "Mobile Number",
      "Status",
      "Date",
      "Note",
      "Follwup Date",
      // "Followup Status",
      // "Call Non Contacted Status",
      // "Call Accept Status",
      // "Call Reject Status",
      "Sub Status",
      "Total call Duration",
    ];
    leadsAssignTableHeader &&
      leadsAssignTableHeader.map((item) => {
        if (
          item.key != "created_on" &&
          item.key != "updated_on" &&
          item.key != "rn"
        ) {
          headers.push(item.key);
        }
      });
    let finalHeader = [...headers];
    const records = [];
    downloadData &&
      downloadData.map((item, index) => {
        const innerData = [];

        if (item.lead_data.call_status == "followup") {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,
            moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.updated_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            moment(item.lead_data.call_followup_date).format(
              "DD-MM-YYYY hh:mm A"
            ),
            item.lead_data.call_followup_status,

            item.lead_data.total_call_duration
          );
        }
        else if (item.lead_data.call_status == "non_contacted") {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,

            moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.updated_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            "",


            item.lead_data.call_non_contacted_status,

            item.lead_data.total_call_duration
          );
        } else if (item.lead_data.call_status == "accept") {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,

            moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.updated_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            "",

            item.lead_data.call_accept_status,

            item.lead_data.total_call_duration
          );
        }
        else if (item.lead_data.call_status == "reject") {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,

            moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.updated_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            "",

            item.lead_data.call_reject_status,
            item.lead_data.total_call_duration
          );
        }
        else {
          innerData.push(
            index + 1,
            item.user_data.full_name,
            item.user_data.mobile_number,
            item.lead_data.call_status,
            moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY") +
            " " +
            moment(item.lead_data.updated_at, "DD-MM-YYYY hh:mm A").format(
              "hh:mm A"
            ),
            item.lead_data.call_note,
            "",
            "",
          
            item.lead_data.total_call_duration
          );
        }
        const innerData1 = [];
        {
          item.lead_details.map((inside_row, inside_index) => {
            if (
              inside_row.key != "created_on" &&
              inside_row.key != "updated_on" &&
              inside_row.key != "rn"
            ) {
              innerData1.push(inside_row.value);
            }
          });
        }
        records.push([...innerData, ...innerData1]);
      });
    console.log('records', records)
    const data = [finalHeader, ...records];
    const newData = records;
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    //Download

    XLSX.writeFile(workBook, "lead-report.xlsx");
  };

  const editHandleAssignClickOpen = (item) => {
    setShow(true);
  };

  const handleAssignClose = () => {
    setShow(false);
  };

  const handleClickOpen = (
    img,
    location,
    checkin_lat,
    checkin_long,
    date,
    attendance_status,
    attendance_id,
    type
  ) => {
    setScroll("paper");
    setPopupValue({
      location: location,
      checkin_lat: checkin_lat,
      checkin_long: checkin_long,
      date: date,
      attendance_status: attendance_status,
      attendance_id: attendance_id,
      type: type,
    });

    setPopupImage(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setScroll("body");
  };

  const handleEditAssignClose = () => {
    setOpenAttendance(false);
  };

  const handleEditAttendanceOpen = (item) => {
    setOpenAttendance(true);
    setUserDetail(item);
    setPage(1);
  };

  const handleDelete = (userLeadID) => {
    confirm().then(() => {
      let postData = {
        user_lead_id: userLeadID,
      };
      deleteAssignLead(postData)(leadsDispatch);
    }).catch = () => console.log("Deletion cancelled.");
  };

  // const handleDelete = (userLeadID) => {
  //   console.log("Delete Detail userLeadID", userLeadID)
  //   let postData = {
  //     user_lead_id: userLeadID
  //   }
  //   setTimeout(() => {
  //     console.log('Delete Detail', postData)
  //     deleteAssignLead(postData)(leadsDispatch)
  //   }, 500)
  // }

  useEffect(() => {
    let status =
      leadsState.deleteAssignLead &&
      leadsState.deleteAssignLead.data &&
      leadsState.deleteAssignLead.status;
    if (status == true) {
      setSuccessMessage("Lead Removed successfully");
      handleDeleteOpen();

      leadsState.deleteAssignLead = {
        loading: false,
        error: null,
        data: null,
      };
    }
  }, [leadsState]);

  const handleDeleteOpen = () => {
    setOpenNotification(true);
  };

  const handleDeleteClose = () => {
    setLoaderData(true)
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
    setOpenNotification(false);
  };

  const handleOnBlur = () => {
    setLoaderData(true)
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  };

  const handleChangeAssignStatus = (e) => {
    setStatusChange(e.target.value);
  };

  const handleChangeTasker = (e) => {
    setUserId(e.target.value);
  };

  const handleStatusChange = (event) => {
    setLoaderData(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setAtteandanceStatus(event.target.value);
    setLoadingLoop(true);


    if (event.target.value == "followup") {
      setSubStatus(followUpSubStatus)
    }
    else if (event.target.value == "accept") {
      setSubStatus(acceptSubStatus)
    }
    else if (event.target.value == "reject") {
      setSubStatus(rejectSubStatus)
    }
    else if (event.target.value == "non_contacted") {
      setSubStatus(nonContactSubStatus)
    }
    else {
      setSubStatus([])
      setSubStatusChange('all')
    }



    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: event.target.value,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  };


  const handleSubStatusChange = (event) => {
    setLoaderData(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setSubStatusChange(event.target.value);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  }

  const handleTaskerChange = (event) => {
    setLoaderData(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setTaskerValue(event.target.value);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: event.target.value,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: event.target.value,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: event.target.value,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: event.target.value,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  };

  const excelApi = () => {
    setLoader(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    }
  };

  const excelDownloadApi = () => {
    setLoader1(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    }
  };

  const handleAudio = (soundFile) => {
    if (soundFile) {
      // getDownloadRecord({file:soundFile})(leadsDispatch)
      // const audio = new Audio("https://developers-sounds-v2.s3.ap-south-1.amazonaws.com/611b7547a60cc440/" + soundFile);
      // if (!isPlay) {
      //   setIsPlay(true);
      //   audio.play();
      // } else {
      //   audio.currentTime=0
      //   setIsPlay(false);
      //   audio.pause();
      // }

      var config = {
        method: "get",
        url: "https://developers.myoperator.co/recordings/link/?token=d005e15b381cd74af84329ebb473bcc0&file=c656206fec792be4ccd7d0223a121cf6ccf4e127d7ca7fe76a7c83b5631a2b0b9d5382498147f335-v2.mp3",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };

      axios(config)
        .then(function (response) {
          console.log("requestData", response.data.paymentReport);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const editLeadHistory = (lead_id) => {
    console.log("lead_id", lead_id);
    getLeadHistory({ project_id: project_id, lead_id: lead_id, page: "all" })(
      leadsDispatch
    );
    setOpenShow(true);
  };

  const handleClose11 = () => {
    setOpenShow(false);
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    // setLoader(true)
    let postData = [];
    selected.map((item) => {
      postData.push({
        user_id: userId,
        project_id: projectId,
        lead_id: item,
        call_status: statusChange,
      });
    });
    console.log("postData", postData);
    reassignLead(postData)(leadsDispatch);
    setSelected([]);
    setUserId("");
  };

  const defaultProps = {
    options: usersData,
    getOptionLabel: (option) => {
      setUserId(option.user_id);
      // setUserAmount(option.user_amount)
      return `${option.full_name}, ${option.mobile_number}`;
    },
  };
  return (
    <>
      <div
        style={{ backgroundColor: "white", borderRadius: "10px", padding: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid sm={12} justifyContent="center" alignItems="center">
                <div
                  style={{
                    color: "rgb(35, 106, 151)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MenuBookIcon
                    style={{ fontSize: 20 }}
                    className="icon-image"
                  />
                  <span
                    className={classes.papercount1}
                    style={{ color: "rgb(35, 106, 151)" }}
                  >
                    &nbsp; Total
                  </span>
                  &nbsp;
                  <div style={{ fontSize: 14 }}>

                    <span
                      style={{
                        padding: "0px 4px 0px 4px",
                        borderRadius: 3,
                        color: "rgb(35, 106, 151)",
                      }}
                    >

                      {totalLeads}
                    </span>
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      display: "flex",
                      color: "#EABE06",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ContactMailIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount4}
                      style={{ color: "#EABE06" }}
                    >
                      &nbsp; Fresh
                    </span>
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span
                        style={{
                          padding: "0px 4px 0px 4px",
                          borderRadius: 3,
                          color: "#EABE06",
                        }}
                      >
                        {totalFreshLead}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      color: "#D4333F",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ContactSupportIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#D4333F" }}
                    >

                      &nbsp; Followup
                    </span>
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span style={{ color: "#D4333F" }}>
                        {totalFollowupLead}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid sm={12} justifyContent="center" alignItems="center">
                <div
                  style={{
                    display: "flex",
                    color: "#0a0",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ContactPageIcon
                    style={{ fontSize: 20 }}
                    className="icon-image"
                  />
                  <span className={classes.papercount2}>
                    &nbsp; Accepted
                  </span>
                  &nbsp;
                  <div style={{ fontSize: 14 }}>

                    <span
                      style={{
                        padding: "0px 4px 0px 4px",
                        borderRadius: 3,
                        color: "#0a0",
                      }}
                    >
                      {totalAcceptLead}
                    </span>
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      color: "#D4333F",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#D4333F" }}
                    >

                      &nbsp; Rejected
                    </span>
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span style={{ color: "#D4333F" }}>
                        {totalRejectLead}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      color: "#125693",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CallMissedIcon style={{ fontSize: 20 }} className="icon-image" />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#125693" }}
                    >

                      &nbsp;Non Contacted
                    </span>
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span style={{ color: "#125693" }}>
                        {totalNonContactLeads}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper

              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
                padding: 10
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      color: "#125693",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CallIcon style={{ fontSize: 20 }} className="icon-image" />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#125693" }}
                    >

                      &nbsp;Durations
                    </span> 
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span style={{ color: "#125693" }}>
                        {totalCallDuration}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <br />
        <Grid
          container
          style={{ padding: "0px 20px 0px 20px", marginBottom: 5 }}
          spacing={3}
        >
          <Grid item sm={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateRangePicker
                startText="Start date"
                endText="End date"
                disableMaskedInput={true}
                //open={openPicker}
                // disableOpenPicker={true}
                maxDate={new Date()}
                showDaysOutsideCurrentMonth={true}
                value={selectedDate}
                onChange={handleDateChange}
                toolbarTitle="Select Date Range"
                format="DD/MM/YYYY"
                onClose={handleOnBlur}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField
                      {...endProps}
                      InputProps={{
                        endAdornment: (
                          <>
                            {selectedDate[0] != null &&
                              selectedDate[1] != null ? (
                              <>
                                <IconButton
                                  onClick={(e) => handleClr(e)}
                                  style={{ padding: 0 }}
                                >
                                  <ClearIcon style={{ fontSize: 13 }} />
                                </IconButton>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ),
                      }}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={3}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={attendanceStatus}
                onChange={handleStatusChange}
                label="Status"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="fresh">Fresh</MenuItem>
                <MenuItem value="followup">Follow Up</MenuItem>
                <MenuItem value="non_contacted">Non Contacted</MenuItem>
                <MenuItem value="accept">Accept</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sub Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={subStatusChange}
                onChange={handleSubStatusChange}
                label="Status"
              >

                {
                  subStatus.map(
                    item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                  )
                }


              </Select>
            </FormControl>

          </Grid>
          <Grid item sm={3}>
            <SearchBar
              style={{
                boxShadow: "none",
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                marginTop: -1,
                borderRadius: 0,
              }}
              value={searched}
              onKeyPress={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </Grid>
          <Grid item sm={4}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Tasker
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={taskerValue}
                onChange={handleTaskerChange}
                label="Select Tasker"
              >
                {usersData.map((item) => {
                  return (
                    <MenuItem value={item.user_id}>{item.full_name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>

          </Grid>
          <Grid item sm={2}></Grid>
          <Grid item sm={3}>
            <Button
              variant="outlined"
              color="outlined"
              onClick={excelDownloadApi}
            >

              {loader1 ? (
                <CircularProgress style={{ color: "#fff" }} size={28} />
              ) : (
                "Download lead Reports"
              )}
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button variant="outlined" color="outlined" onClick={excelApi}>

              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={28} />
              ) : (
                "Download Lead History"
              )}
            </Button>
          </Grid>
        </Grid>
        {leadsAssignTableBody.length > 0 ? (
          <Grid
            item
            xs={12}
            style={{ width: "100%", overflowX: "auto", display: "inline-grid" }}
          >
            {selected.length != 0 && (
              <Form onSubmit={handleSubmit3}>
                <EnhancedTableToolbar numSelected={selected.length} />
              </Form>
            )}
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rowsPerPage}
                />
                <TableBody>
                  {leadsAssignTableBody.map((row, index) => {
                    const isItemSelected = isSelected(row.lead_data.lead_id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick1(event, row.lead_data.lead_id)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.lead_data.lead_id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          style={{ minWidth: 120 }}
                          align="center"
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <TableCell
                            align="left"
                            style={{
                              width: "30%",
                              color: "#000000",
                              borderBottom: "none",
                              padding: "0px",
                            }}
                          >
                            <Avatar src={row.user_data.profile_image} />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              width: "70%",
                              color: "#000000",
                              borderBottom: "none",
                            }}
                          >
                            <label> {row.user_data.full_name}</label>
                          </TableCell>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 100 }}>
                          <label> {row.lead_data.lead_id}</label>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 100 }}>
                          <label> {row.lead_data.call_status}</label>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 200 }}>
                          <label>

                            {moment(row.lead_data.created_at).utc().format("DD-MM-YYYY")}{" "}
                            {moment(
                              row.lead_data.created_at,
                              "DD-MM-YYYY hh:mm A"
                            ).format("hh:mm A")}
                          </label>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 200 }}>
                          <label>

                            {moment(row.lead_data.updated_at).utc().format("DD-MM-YYYY")}{" "}
                            {moment(
                              row.lead_data.updated_at,
                              "DD-MM-YYYY hh:mm A"
                            ).format("hh:mm A")}
                          </label>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <label>

                            {row.lead_data.total_call_duration
                              ? row.lead_data.total_call_duration
                              : "00:00:00"}
                          </label>
                        </TableCell>
                        {row.lead_details.map((inside_row, inside_index) => {
                          return inside_row.key != "created_on" &&
                            inside_row.key != "updated_on" &&
                            inside_row.key != "rn" &&
                            inside_row.key != "durations" ? (
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ minWidth: 120 }}
                              align="left"
                            >
                              {inside_row.value}
                            </TableCell>
                          ) : (
                            ""
                          );
                        })}
                        <TableCell align="center">
                          {row.lead_data.call_status == "fresh" ? (
                            <>
                              <Tooltip title="Remove Lead">
                                <IconButton
                                  onClick={() =>
                                    handleDelete(row.lead_data.user_lead_id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : row.lead_data.call_record != "" ? (
                            <>
                              <Tooltip title="Record">
                                <IconButton
                                  onClick={() =>
                                    handleAudio(row.lead_data.call_record)
                                  }
                                >
                                  {isPlay ? (
                                    <PauseCircleIcon />
                                  ) : (
                                    <PlayArrowIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            "NA"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <IconButton
                            onClick={() =>
                              editLeadHistory(
                                row.lead_data.lead_id,
                                row.lead_data.project_id
                              )
                            }
                          >
                            <Tooltip title="View History">
                              <ImportContactsIcon />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handleChangePage}
                  color="primary"
                  variant="outlined"
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
            {leadsAssignTableBody.length == 0 && (
              <h2 style={{ textAlign: "center" }}>
                <br />
                No Leads Found
              </h2>
            )}
          </Grid>
        ) : <>{loaderData == true ?
          <LinearProgress />
          : (

            <h2 style={{ textAlign: "center" }}>
              <br />
              No Leads found
            </h2>
          )}</>}
      </div>
      <SuccessNotification
        open={openNotification}
        msg={successMessage}
        handleClose={handleDeleteClose}
      />
      <LeadHistory open={openShow} msg={""} handleClose={handleClose11} />
    </>
  );
});
export default withRouter(ProjectTable);
