import 'date-fns';
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { styles } from "./CreateLeadStyle";
import SuccessNotification from '../../popUpNotification/SuccessNotification'
import FailureNotification from '../../popUpNotification/FailureNotification';
import { useForm, Form } from '../../useForm';
import { GlobalContext } from "../../../context/GlobalState";
import { getLeadsFields } from "../../../context/actions/leadsAction/getLeadsFields";
import { createLead } from "../../../context/actions/leadsAction/createLead";
import { getLeads } from "../../../context/actions/leadsAction/getLeads";
toast.configure();
const useStyles = makeStyles(styles);



const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [leadsFields, setLeadsFields] = useState([]);
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  let projectId = props.location.pathname && props.location.pathname.split('/').pop()
  const [expanded, setExpanded] = React.useState(false);
  const initialFormData = Object.freeze({});
  const [formData, updateFormData] = React.useState(initialFormData);


  useEffect(() => {
    getLeadsFields(projectId)(leadsDispatch)
  }, [])

  useEffect(() => {
    getLeads(projectId)(leadsDispatch)
  }, [])





  const handleChangeLeadInput = (event) => {
    updateFormData({
      ...formData,

      "project_id": projectId,
      [event.target.name]: event.target.value,
    });
  }


  const handleSubmit1 = e => {
    e.preventDefault()
    setLoader(true)

    setTimeout(() => {
      createLead(formData)(leadsDispatch)
    }, 500)
  }






  useEffect(() => {
    setLoader(false)
    console.log('leadsState', leadsState)
    /******************************* GET LEADS FIELDS ******************************** */
    let Lead_field_status = leadsState.getLeadsFields && leadsState.getLeadsFields.status
    // SUCCESS RESPONSE
    if (Lead_field_status === true) {
      
      setLeadsFields(leadsState.getLeadsFields.data)

      setUploadLeadsTab(false)
      setAssignLeadsTab(false)
      setCreateTable(true)
    }
    // SERVER ERROR RESPONSE
    if (Lead_field_status === 500) {
      console.log("Server Error:", leadsState.getLeadsFields)
      setUploadLeadsTab(true)
      setAssignLeadsTab(true)
      setCreateTable(false)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (Lead_field_status === 401) {
      setMessage("Worng username or password")
      console.log("Validation Error:", leadsState.getLeadsFields)
    }

    /************************* GET LEADS FIELDS ************************************** */



    /************************** CREATE LEAD ************************************* */
    let create_status = leadsState.createLead && leadsState.createLead.status
    // SUCCESS RESPONSE
   
    if (create_status === true) {
      
      setSuccessMessage("Lead  Created successfully")
      handleNotificationClickOpen()
      setLoader(false)
      setUploadLeadsTab(false)
      setAssignLeadsTab(false)
      setCreateTable(true)
      
    }
    // SERVER ERROR RESPONSE
    if (create_status === 500) {
      console.log("Server Error:", leadsState.createLead)
      setFailureMessage('Lead Already Created')
      handleFailureNotificationClickOpen()
      setLoader(false)
      setUploadLeadsTab(true)
      setAssignLeadsTab(true)
      setCreateTable(false)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (create_status === 401) {
      setMessage("Worng username or password")
      console.log("Validation Error:", leadsState.createLead)
    }

    /*****************************  CREATE LEAD  ********************************** */
  }, [leadsState])


  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
    leadsState.createLead={
      loading: false,
      error: null,
      data: null,
    };
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
    leadsState.createLead={
      loading: false,
      error: null,
      data: null,
    };
  };






  return (
    <>
      <React.Fragment>
        <div>
                <Form onSubmit={handleSubmit1}>
                  <Grid className={classes.layoutMargin1}>
                    <Grid xs={12} >
                      <Card variant="outlined">
                        <CardContent className={classes.custInput}>
                          <Grid xs={12}
                            container
                            alignItems="center"
                            style={{ paddingBottom: '0px' }}
                          >
                            <Grid item container justify="flex-start" sm={6}>
                              <h2 ><b>Create Lead</b></h2>
                            </Grid>
                            <Grid item direction="row" container justify="flex-end" sm={6}>
                              <div>
                                <Button className={classes.saveBtn} type="submit" size="large" variant="contained" color="primary" style={{ backgroundColor: '#125693' }}>
                                  {
                                    loader ? <CircularProgress style={{ color: '#fff' }} size={28} /> : "Create"
                                  }
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} className={classes.paddings}  >
                            {leadsFields.table_fields && leadsFields.table_fields.map((items, i) => {
                              return items.COLUMN_NAME != "project_id" && items.COLUMN_NAME != "created_on" ?
                                <Grid item xs={6} spacing={2}>
                                  <TextField
                                    id="standard-fullname-input"
                                    name={items.COLUMN_NAME}
                                    label={items.COLUMN_NAME}
                                    fullWidth
                                    autoComplete="current-fullname"
                                    required
                                    onChange={event => handleChangeLeadInput(event)}
                                  />
                                </Grid>
                                : ''
                            }
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Form>
            
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
})
export default withRouter(BookingTable)
