import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CallMissedIcon from '@mui/icons-material/CallMissed';
import { withRouter, Link, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Pagination from "@material-ui/lab/Pagination";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AssignLeadTableStyle";
import PlusIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Avatar, CardContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import FilterDialog from "./FilterDialog";
import Toolbar from "@mui/material/Toolbar";
import { useForm, Form } from "../useForm";
import CallIcon from "@mui/icons-material/Call";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import IconButton from "@material-ui/core/IconButton";
import { TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import InfiniteScroll from "react-infinite-scroll-component";
import { GlobalContext } from "context/GlobalState";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import VerifiedIcon from "@mui/icons-material/Verified";
import XLSX from "xlsx";
import LinearProgress from '@mui/material/LinearProgress'
import DownloadIcon from '@mui/icons-material/Download';
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, DialogContent, DialogTitle } from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { getUserLeads } from "context/actions/leadsAction/getUserLeads";
import { getLeadsFields } from "context/actions/leadsAction/getLeadsFields";
import { getProjectUsers } from "context/actions/projectAction/getProjectUsers";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { deleteAssignLead } from "context/actions/leadsAction/removeAssignLead";
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { getUserLeadsDownload } from "context/actions/leadsAction/getUserLeadsDownload";
import { getLeadHistory } from "context/actions/leadsAction/getLeadHistory";
import LeadHistory from "./leadHistory";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getUserLeadsDownloadReport } from "context/actions/leadsAction/getUserLeadsReportDownload";
import { reassignLead } from "context/actions/leadsAction/reassignLead";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: "#B8B8B8",
  },
  selected: {
    color: "#602994",
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: 'white',
    },
  },
}))(TableRow);


const ProjectTable = React.memo((props) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [paginationCount, setPaginationCount] = React.useState(1);
  const [loadingLoop, setLoadingLoop] = useState(true);
  const descriptionElementRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loaderData, setLoaderData] = useState(false);

  const [scroll, setScroll] = React.useState("paper");
  const [openShow, setOpenShow] = React.useState(false);
  const [selectedDate, handleDateChanges] = useState([null, null]);
  const [page, setPage] = React.useState(1);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [filterDate, setFilterDate] = React.useState(
    moment(new Date()).utc().format("YYYY-MM-DD")
  );
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [statusChange, setStatusChange] = React.useState("");
  const [attendanceStatus, setAtteandanceStatus] = React.useState("all");
  const [taskerValue, setTaskerValue] = React.useState("");
  const [searched, setSearched] = useState("");
  const [subStatus, setSubStatus] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [popupValue, setPopupValue] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [selected, setSelected] = React.useState([]);
  let project_id =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [popupImage, setPopupImage] = useState("");
  const [leadsAssignTableHeader, setLeadsAssignTableHeader] = useState([]);
  const [leadsAssignTableHeader1, setLeadsAssignTableHeader1] = useState([]);
  const [leadsAssignTableBody, setLeadsAssignTableBody] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [totalAcceptLead, setTotalAcceptLead] = React.useState(0);
  const [totalFollowupLead, setTotalFollowupLead] = React.useState(0);
  const [nonContactedLead, setNonContactedLead] = React.useState(0);
  const [totalFreshLead, setTotalFreshLead] = React.useState(0);
  const [totalLeads, setTotalLeads] = React.useState(0);
  const [totalRejectLead, setTotalRejectLead] = React.useState(0);
  const [totalCallDuration, setTotalCallDuration] = React.useState(0);
  const [totalNonContactLeads, setTotalNonContactLeads] = React.useState(0);
  const [subStatusChange, setSubStatusChange] = useState('all');
  const [filterCount, setfilterCount] = useState(0);


  const acceptSubStatus = [
    { id: 'key_done_taskmo', title: 'Key Done Taskmo' },
    { id: 'key_done_customer_itself', title: 'Key Done Customer Itself' }]

  const followUpSubStatus = [
    { id: 'technical_issue', title: 'Technical Issue' },
    { id: 'call_back_request', title: 'Call Back Request' },
    { id: 'will_do_on_my_own', title: 'Will Do On My Own' },
    { id: 'language_issue', title: 'Language Issue' },
    { id: 'partially_onboard', title: 'Partially Onboard' },
    { id: 'others', title: 'Others' }
  ]

  const nonContactSubStatus = [
    { id: 'call_not_getting_connected', title: 'Call Not Getting Connected' },
    { id: 'rnr', title: 'RNR' },
    { id: 'switched_off', title: 'Switched Off' },
    { id: 'disconnecting_the_call', title: 'Disconnecting the Call' }]

  const rejectSubStatus = [
    { id: 'wrong_number', title: 'Wrong Number' },
    { id: 'dnd', title: 'DND' },
    { id: 'no_smart_phone', title: 'No Smartphone' },
    { id: 'number_does_not_exist', title: 'Number Does Not Exist' },
    { id: 'using_abusive_language', title: 'Using Abusive Language' },
    { id: 'not_in_lead_of_money', title: 'Not In Lead of Money' },
    { id: 'not_intimated_by_company/employer', title: 'Not Intimated by Company/Employer' },
    { id: 'fake_call', title: 'Fake Call' },
    { id: 'hr_inform_not_to_use_refyne', title: 'Hr Inform not to use Refyne' },
    { id: 'no_document', title: 'No Document' },
    { id: 'wrong_onboarding', title: 'Wrong Onboarding' },
  ]

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsAssignTableBody.map((n) => n.lead_data.lead_id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    console.log("projectState", projectState);
    let status = projectState.getProjectUsers.status;
    if (status === true) {
      const verifyFiltered = projectState.getProjectUsers.data.filter(
        (project) => project.onboard_status == "completed"
      );
      setUsersData(verifyFiltered);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProjectUsers);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProjectUsers);
    }
  }, [projectState]);

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        style={{ width: "100%" }}
        sx={{
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Grid container spacing={2}>
          <Grid sm={2} item style={{ display: "flex", alignItems: "center" }}>
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              ></Typography>
            )}
          </Grid>
          <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
            <FormControl variant="standard" style={{ width: "100%" }} required>
              <InputLabel id="demo-simple-select-standard-label">
                Tasker
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={userId}
                label="Select Tasker"
                onChange={handleChangeTasker}
              >
                {usersData.map((item) => {
                  return (
                    <MenuItem value={item.user_id}>
                      {item.full_name} ({item.mobile_number})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
            <FormControl variant="standard" style={{ width: "100%" }} required>
              <InputLabel id="demo-simple-select-standard-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={statusChange}
                label="Select Status"
                onChange={handleChangeAssignStatus}
              >
                <MenuItem value="fresh">Fresh</MenuItem>
                <MenuItem value="followup">Follow Up</MenuItem>
                <MenuItem value="accept">Accept</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sm={2} item>
            {numSelected > 0 ? (
              <Tooltip title="Re-Assign" style={{ float: "right" }}>
                <IconButton>
                  {/* <DeleteIcon /> */}
                  <Button
                    className={classes.saveBtn}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#125693" }}
                  >
                    {loader ? (
                      <CircularProgress style={{ color: "#fff" }} size={28} />
                    ) : (
                      "ReAssign"
                    )}
                  </Button>
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" align="center" style={{ background: "#F0F0F0" }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Lead ID
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Agent
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Status
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            ID
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Poi Id
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Type
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Subtype
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            District
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            State
          </TableCell>

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Name
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Source
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Language
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Pincode
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            User phone
          </TableCell>

          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Additional Details
          </TableCell> */}

          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Explain Features
          </TableCell> */}

          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Download Amazon Pay Business App
          </TableCell> */}

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Download App On Call
          </TableCell>
          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Merchant Download App
          </TableCell> */}
          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Step To Download App
          </TableCell> */}

          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Creation Date
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Update Date
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Total Call Duration
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Last Call Duration
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Hangup Cause
          </TableCell>

          {/* <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            Action
          </TableCell>
          <TableCell align="left" style={{ background: "#F0F0F0", fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis' }} key={1}>
            History
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    let count = newPage - 1;
    setPage(newPage);
    setLoaderData(true)
    setPaginationCount(newPage);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: newPage,
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: newPage,
          })(leadsDispatch);
        }
      }
    }
  };

  useEffect(() => {
    getProjectUsers({ project_id: project_id, page: "all" })(projectDispatch);
  }, []);

  function handleClr(e) {
    setLoaderData(true)
    e.stopPropagation();
    handleDateChanges([null, null]);
    setPage(1);
    setLoadingLoop(true);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    if (searched == "") {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: searched,
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  }

  const handleDateChange = (event) => {
    //console.log('event', event)
    handleDateChanges(event);
  };

  useEffect(() => {
    setLoaderData(true)
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log("leadsState", leadsState);

    /************************** ASSIGN LEAD ************************************* */
    let assign_lead_status =
      leadsState.reassignLead && leadsState.reassignLead.status;
    // SUCCESS RESPONSE

    if (assign_lead_status === true) {
      setSuccessMessage("Lead  Re-Assigned successfully");

      handleDeleteOpen();
      leadsState.reassignLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleDeleteClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (assign_lead_status === 500) {
      console.log("Server Error:", leadsState.reassignLead);
      // setFailureMessage('Lead Already Assigned')
      handleDeleteOpen();
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (assign_lead_status === 401) {
      // setFailureMessage("Worng username or password")
      console.log("Validation Error:", leadsState.reassignLead);
    }

    /*****************************  ASSIGN LEAD ********************************** */

    let status =
      // leadsState.getUserLeads &&
      // leadsState.getUserLeads.data &&
      leadsState.getUserLeads.status;
    if (status == true) {
      setLoaderData(false)
      // console.log("leadsState.getUserLeads.data.res[0].lead_details", leadsState.getUserLeads.data.res[0].lead_details);

      if (leadsState.getUserLeads.data.res.length != 0) {
        setLeadsAssignTableHeader(
          leadsState.getUserLeads.data.res[0].lead_details
        );
        setLeadsAssignTableBody(leadsState.getUserLeads.data.res);
        setTotalPage(leadsState.getUserLeads.data.total_page);
        setTotalAcceptLead(leadsState.getUserLeads.data.total_accept_lead);
        setTotalFollowupLead(leadsState.getUserLeads.data.total_followup_lead);
        setNonContactedLead(leadsState.getUserLeads.data.total_non_contacted_lead)
        setTotalFreshLead(leadsState.getUserLeads.data.total_fresh_lead);
        setTotalLeads(leadsState.getUserLeads.data.total_leads);
        setTotalRejectLead(leadsState.getUserLeads.data.total_reject_lead);
        setTotalCallDuration(leadsState.getUserLeads.data.total_call_duration);
        setTotalNonContactLeads(leadsState.getUserLeads.data.total_non_contacted_lead)
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
      setTotalLeads(leadsState.getUserLeads.data.total_leads);
      setTotalFreshLead(leadsState.getUserLeads.data.total_fresh_lead);
      setTotalAcceptLead(leadsState.getUserLeads.data.total_accept_lead);
      setTotalNonContactLeads(leadsState.getUserLeads.data.total_non_contacted_lead);
      setTotalRejectLead(leadsState.getUserLeads.data.total_reject_lead);
    }
  }, [leadsState]);


  useEffect(() => {

    let status =
      leadsState.getUserLeadsDownload &&
      leadsState.getUserLeadsDownload.data &&
      leadsState.getUserLeadsDownload.status;
    if (status == true) {
      setLoader(false);
      if (leadsState.getUserLeadsDownload.data.res.length != 0) {
        downloadExcel(leadsState.getUserLeadsDownload.data.res);
        leadsState.getUserLeadsDownload = {
          loading: false,
          error: null,
          data: null,
        };
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
  }, [leadsState]);



  useEffect(() => {
    // console.log('leadsState', leadsState)
    let status =
      leadsState.getUserLeadsDownloadReports &&
      leadsState.getUserLeadsDownloadReports.data &&
      leadsState.getUserLeadsDownloadReports.status;
    if (status == true) {
      setLoader1(false);
      if (leadsState.getUserLeadsDownloadReports.data.res.length != 0) {
        downloadExcelReport(leadsState.getUserLeadsDownloadReports.data.res);
        leadsState.getUserLeadsDownloadReports = {
          loading: false,
          error: null,
          data: null,
        };
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
  }, [leadsState]);

  useEffect(() => {
    console.log("getDownloadRecord", leadsState.getDownloadRecord);
    let status =
      leadsState.getDownloadRecord &&
      leadsState.getDownloadRecord.data &&
      leadsState.getDownloadRecord.status;
    if (status == true) {
      leadsState.getDownloadRecord = {
        loading: false,
        error: null,
        data: null,
      };
    }
  }, [leadsState]);

  const requestSearch = (event) => {
    setLoaderData(true)
    if (event.key === "Enter") {
      setPage(1);
      setLeadsAssignTableHeader([]);
      setLeadsAssignTableBody([]);
      setLoadingLoop(true);
      setSearched(event.target.value);

      if (selectedDate[0] == null && selectedDate[1] == null) {
        if (event.target.value == "") {
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: "all",
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: taskerValue,
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          }
        } else {
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: "all",
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate: "all",
              filterEndDate: "all",
              project_id: project_id,
              user_id: taskerValue,
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          }
        }
      } else {
        if (searched == "") {
          let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: "all",
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: taskerValue,
              searched: "__search__",
              page: "1",
            })(leadsDispatch);
          }
        } else {
          let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
          if (taskerValue == "") {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: "all",
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          } else {
            getUserLeads({
              type: attendanceStatus,
              search: subStatusChange,
              filterStartDate,
              filterEndDate,
              project_id: project_id,
              user_id: taskerValue,
              searched: event.target.value,
              page: "1",
            })(leadsDispatch);
          }
        }
      }
    }
  };

  const cancelSearch = () => {
    setLoaderData(true)
    setSearched("");
    requestSearch(searched);
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    } else {
      let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
      let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
      if (taskerValue == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: "all",
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: taskerValue,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      }
    }
  };

  const history = useHistory();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ITEM_HEIGHT = 48;

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const report_columns = [
    {
      label: "No",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      label: "Tasker",
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "City", label: "City", minWidth: 60 },
    { id: "Phone", label: "Phone", minWidth: 60 },
    { id: "Check In ", label: "Check In Selfie" },
    { id: "Check In ", label: "Check In Time" },
    {
      id: "Check Out Selfie",
      label: "Check Out Selfie",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Check Out Time",
      label: "Check Out Time",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Total Hours (hh:mm)",
      label: "Total Hours (h:m)",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Target",
      label: "Target",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Achieved Target",
      label: "Achieved Target",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Status",
      label: "Status",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  const downloadExcel = (downloadData) => {
    console.log('downloadData', downloadData);
    const headers = [
      "No",
      "Agent",
      "Agent phone number",
      "Date",
      "Main Status",
      "Lead Status",
      "App Download Status",
      "Registered Status",
      "Note",
      "Follow Up Date",
      "Call Duration",
      "Last call duration",
      "Lead ID",
      "Hangup Cause",
      "ID",
      "Poi ID",
      "Type of Shop",
      "Subtype",
      "District",
      "State",
      "Merchant Shop Name",
      "Pincode",
      "Contact number",
    ];

    const records = [];
    downloadData &&
      downloadData.map((item, index) => {
        const innerData = [];
        innerData.push(index + 1); // No
        innerData.push(item.user_data.full_name); // Agent
        innerData.push(item.user_data.mobile_number); // Agent phone number
        if (item.lead_data.created_at == "0000-00-00 00:00:00") {
          innerData.push("-")
        } else {
          innerData.push(moment(item.lead_data.created_at).utc().format("DD-MM-YYYY")); // Date
        }
        innerData.push(item.lead_data.call_status); // Main Status
        innerData.push(item.lead_data.call_sub_status); // Lead Status
        innerData.push(item.lead_data.download_app_on_call); // App Download Status
        innerData.push(item.lead_data.is_registered); // Registered Status
        innerData.push(item.lead_data.call_note); // Note
        if (item.lead_data.call_followup_date == "0000-00-00 00:00:00") {
          innerData.push("-")
        } else {
          innerData.push(moment(item.lead_data.call_followup_date).utc().format("DD-MM-YYYY")); // Follow Up Date
        }
        innerData.push(item.lead_data.call_duration); // Call Duration
        innerData.push(item.lead_data.last_call_duration); // Last call duration
        innerData.push(item.lead_data.lead_id); // Lead ID
        innerData.push(item.lead_data.hangup_cause) // Hangup Cause

        item.lead_details.map(items => {

          if (items.key == "id") {
            innerData.push(items.value); // ID
          }

          if (items.key == "poi_id") {
            innerData.push(items.value); // Poi ID
          }

          if (items.key == "type") {
            innerData.push(items.value); // Type of Shop
          }

          if (items.key == "sub_type") {
            innerData.push(items.value); // Subtype
          }

          if (items.key == "district") {
            innerData.push(items.value); // District
          }

          if (items.key == "state") {
            innerData.push(items.value); // State
          }

          if (items.key == "name") {
            innerData.push(items.value); // Merchant Name
          }

          if (items.key == "pincode") {
            innerData.push(items.value); // Pincode
          }

          if (items.key == "user_phone") {
            innerData.push(items.value); // Contact number
          }

        })

        records.push(innerData)
      })
    const data = [headers, ...records];
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Report");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "lead-history-report.xlsx");
  }

  const downloadExcelReport = (downloadData) => {
    console.log('downloadData', downloadData);
    const headers = [
      "No",
      "Agent",
      "Agent phone number",
      "Date",
      "Main Status",
      "Lead Status",
      "App Download Status",
      "Registered Status",
      "Note",
      "Follow Up Date",
      "Call Duration",
      "Last call duration",
      "Total count",
      "Lead ID",
      "Hangup Cause",
      "ID",
      "Poi ID",
      "Type of Shop",
      "Subtype",
      "District",
      "State",
      "Merchant Shop Name",
      "Pincode",
      "Contact number",
    ];

    const records = [];
    downloadData &&
      downloadData.map((item, index) => {
        const innerData = [];
        innerData.push(index + 1); // No
        innerData.push(item.user_data.full_name); // Agent
        innerData.push(item.user_data.mobile_number); // Agent phone number
        if (item.lead_data.updated_at == "0000-00-00 00:00:00") {
          innerData.push("-")
        } else {
          innerData.push(moment(item.lead_data.updated_at).utc().format("DD-MM-YYYY")); // Date
        }
        innerData.push(item.lead_data.call_status); // Main Status
        innerData.push(item.lead_data.call_sub_status); // Lead Status
        innerData.push(item.lead_data.download_app_on_call); // App Download Status
        innerData.push(item.lead_data.is_registered); // Registered Status
        innerData.push(item.lead_data.call_note); // Note
        if (item.lead_data.call_followup_date == "0000-00-00 00:00:00") {
          innerData.push("-")
        } else {
          innerData.push(moment(item.lead_data.call_followup_date).utc().format("DD-MM-YYYY")); // Follow Up Date
        }
        innerData.push(item.lead_data.call_duration); // Call Duration
        innerData.push(item.lead_data.last_call_duration); // Last call duration
        innerData.push(item.lead_data.total_count); // Total count
        innerData.push(item.lead_data.lead_id); // Lead ID
        innerData.push(item.lead_data.hangup_cause) // Hangup Cause

        item.lead_details.map(items => {

          if (items.key == "id") {
            innerData.push(items.value); // ID
          }

          if (items.key == "poi_id") {
            innerData.push(items.value); // Poi ID
          }

          if (items.key == "type") {
            innerData.push(items.value); // Type of Shop
          }

          if (items.key == "sub_type") {
            innerData.push(items.value); // Subtype
          }

          if (items.key == "district") {
            innerData.push(items.value); // District
          }

          if (items.key == "state") {
            innerData.push(items.value); // State
          }

          if (items.key == "name") {
            innerData.push(items.value); // Merchant Name
          }

          if (items.key == "pincode") {
            innerData.push(items.value); // Pincode
          }

          if (items.key == "user_phone") {
            innerData.push(items.value); // Contact number
          }

        })

        records.push(innerData)
      })
    const data = [headers, ...records];
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Report");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "lead-report.xlsx");
  }

  const editHandleAssignClickOpen = (item) => {
    setShow(true);
  };

  const handleAssignClose = () => {
    setShow(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
  };


  const handleEditAssignClose = () => {
    setOpenAttendance(false);
  };

  const handleEditAttendanceOpen = (item) => {
    setOpenAttendance(true);
    setUserDetail(item);
    setPage(1);
  };

  const handleDelete = (userLeadID) => {
    confirm().then(() => {
      let postData = {
        user_lead_id: userLeadID,
      };
      deleteAssignLead(postData)(leadsDispatch);
    }).catch = () => console.log("Deletion cancelled.");
  };

  // const handleDelete = (userLeadID) => {
  //   console.log("Delete Detail userLeadID", userLeadID)
  //   let postData = {
  //     user_lead_id: userLeadID
  //   }
  //   setTimeout(() => {
  //     console.log('Delete Detail', postData)
  //     deleteAssignLead(postData)(leadsDispatch)
  //   }, 500)
  // }

  useEffect(() => {
    let status =
      leadsState.deleteAssignLead &&
      leadsState.deleteAssignLead.data &&
      leadsState.deleteAssignLead.status;
    if (status == true) {
      setSuccessMessage("Lead Removed successfully");
      handleDeleteOpen();

      leadsState.deleteAssignLead = {
        loading: false,
        error: null,
        data: null,
      };
    }
  }, [leadsState]);

  const handleDeleteOpen = () => {
    setOpenNotification(true);
  };

  const handleDeleteClose = () => {
    setLoaderData(true)
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
    setOpenNotification(false);
  };

  const handleOnBlur = () => {
    setLoaderData(true)
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  };

  const handleChangeAssignStatus = (e) => {
    setStatusChange(e.target.value);
  };

  const handleChangeTasker = (newValue) => {
    console.log("newValue*******", newValue)
    if (newValue != null && newValue != '' && newValue != undefined) {
      setUserId(newValue.user_id)
    } else {
      setUserId(0)
    }
  };

  const handleSubStatusChange = (event) => {
    setLoaderData(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setSubStatusChange(event.target.value);
    setLoadingLoop(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "1",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "1",
          })(leadsDispatch);
        } else {
          getUserLeads({
            type: attendanceStatus,
            search: event.target.value,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  }

  const handleTaskerChange = (event) => {
    setLoaderData(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setTaskerValue(event.target.value);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: event.target.value,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate: "all",
          filterEndDate: "all",
          project_id: project_id,
          user_id: event.target.value,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: event.target.value,
          searched: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");

        getUserLeads({
          type: attendanceStatus,
          search: subStatusChange,
          filterStartDate,
          filterEndDate,
          project_id: project_id,
          user_id: event.target.value,
          searched: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  };

  const excelApi = () => {
    setLoader(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownload({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    }
  };

  const excelDownloadApi = () => {
    setLoader1(true);
    if (selectedDate[0] == null && selectedDate[1] == null) {
      if (searched == "") {
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate: "all",
            filterEndDate: "all",
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: "__search__",
            page: "all",
          })(leadsDispatch);
        }
      } else {
        let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
        if (taskerValue == "") {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: "all",
            searched: searched,
            page: "all",
          })(leadsDispatch);
        } else {
          getUserLeadsDownloadReport({
            type: attendanceStatus,
            search: subStatusChange,
            filterStartDate,
            filterEndDate,
            project_id: project_id,
            user_id: taskerValue,
            searched: searched,
            page: "all",
          })(leadsDispatch);
        }
      }
    }
  };

  const handleAudio = (soundFile) => {
    if (soundFile) {
      // getDownloadRecord({file:soundFile})(leadsDispatch)
      // const audio = new Audio("https://developers-sounds-v2.s3.ap-south-1.amazonaws.com/611b7547a60cc440/" + soundFile);
      // if (!isPlay) {
      //   setIsPlay(true);
      //   audio.play();
      // } else {
      //   audio.currentTime=0
      //   setIsPlay(false);
      //   audio.pause();
      // }

      var config = {
        method: "get",
        url: "https://developers.myoperator.co/recordings/link/?token=d005e15b381cd74af84329ebb473bcc0&file=c656206fec792be4ccd7d0223a121cf6ccf4e127d7ca7fe76a7c83b5631a2b0b9d5382498147f335-v2.mp3",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };

      axios(config)
        .then(function (response) {
          console.log("requestData", response.data.paymentReport);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const editLeadHistory = (lead_id) => {
    console.log("lead_id", lead_id);
    getLeadHistory({ project_id: project_id, lead_id: lead_id, page: "all" })(
      leadsDispatch
    );
    setOpenShow(true);
  };

  const handleClose11 = () => {
    setOpenShow(false);
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    // setLoader(true)
    let postData = [];
    selected.map((item) => {
      postData.push({
        user_id: userId,
        project_id: projectId,
        lead_id: item,
        call_status: statusChange,
      });
    });
    console.log("postData", postData);
    reassignLead(postData)(leadsDispatch);
    setSelected([]);
    setUserId("");
  };

  const defaultProps = {
    options: usersData,
    getOptionLabel: (option) => {
      setUserId(option.user_id);
      // setUserAmount(option.user_amount)
      return `${option.full_name}, ${option.mobile_number}`;
    },
  };
  return (
    <>
      <div
        style={{ backgroundColor: "white", marginTop: '-25px' }}
      >
        <br />
        <Grid container spacing={1} justifyContent="space-between" >
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid sm={12} justifyContent="center" alignItems="center">
                <div style={{ fontSize: 26, fontWeight: 600 }}>

                  <span
                    style={{
                      padding: "0px 4px 0px 4px",
                      borderRadius: 3,
                      color: "rgb(35, 106, 151)",
                    }}
                  >

                    {totalLeads}
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(35, 106, 151)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MenuBookIcon
                    style={{ fontSize: 20 }}
                    className="icon-image"
                  />
                  <span
                    className={classes.papercount1}
                    style={{ color: "rgb(35, 106, 151)" }}
                  >
                    &nbsp; Total
                  </span>
                  &nbsp;
                </div>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div style={{ fontSize: 26, fontWeight: 600 }}>

                    <span
                      style={{
                        padding: "0px 4px 0px 4px",
                        borderRadius: 3,
                        color: "#EABE06",
                      }}
                    >
                      {totalFreshLead}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#EABE06",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ContactMailIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount4}
                      style={{ color: "#EABE06" }}
                    >
                      &nbsp; Fresh
                    </span>
                    &nbsp;

                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div
                    style={{
                      color: "#D4333F",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ContactSupportIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#D4333F" }}
                    >

                      &nbsp; Followup
                    </span>
                    &nbsp;
                    <div style={{ fontSize: 14 }}>

                      <span style={{ color: "#D4333F" }}>
                        {totalFollowupLead}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid> */}

          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid sm={12} justifyContent="center" alignItems="center">
                <div style={{ fontSize: 26, fontWeight: 600 }}>

                  <span
                    style={{
                      padding: "0px 4px 0px 4px",
                      borderRadius: 3,
                      color: "#0a0",
                    }}
                  >
                    {totalAcceptLead}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "#0a0",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ContactPageIcon
                    style={{ fontSize: 20 }}
                    className="icon-image"
                  />
                  <span className={classes.papercount2}>
                    &nbsp; Contacted
                  </span>
                  &nbsp;

                </div>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div style={{ fontSize: 26, fontWeight: 600 }}>

                    <span style={{ color: "#D4333F" }}>
                      {totalRejectLead}
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#D4333F",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: 20 }}
                      className="icon-image"
                    />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#D4333F" }}
                    >

                      &nbsp; Rejected
                    </span>
                    &nbsp;

                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper
              className={classes.topCardPaper}
              style={{
                background: "#f0f0f0",
                border: "0.5px solid #ddd",
                borderRadius: 2,
              }}
              elevation={0}
            >
              <Grid
                container
                sm={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <div style={{ fontSize: 26, fontWeight: 600 }}>

                    <span style={{ color: "#125693" }}>
                      {totalNonContactLeads}
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#125693",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CallMissedIcon style={{ fontSize: 20 }} className="icon-image" />
                    <span
                      className={classes.papercount3}
                      style={{ color: "#125693" }}
                    >

                      &nbsp;Not Connected
                    </span>
                    &nbsp;

                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

        </Grid>

        <br />
        <Grid
          container
          spacing={2}
        >

          <Grid item sm={3}>
            <Grid item xs={12}>
              <Paper

                style={{
                  background: "#f0f0f0",
                  border: "0.5px solid #ddd",
                  borderRadius: 2,
                  padding: 10
                }}
                elevation={0}
              >
                <Grid
                  container
                  sm={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={12}>
                    <div
                      style={{
                        color: "#125693",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CallIcon style={{ fontSize: 20 }} className="icon-image" />
                      <span
                        className={classes.papercount3}
                        style={{ color: "#125693" }}
                      >

                        &nbsp;Durations
                      </span>
                      &nbsp;
                      <div style={{ fontSize: 14 }}>

                        <span style={{ color: "#125693" }}>
                          {totalCallDuration}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item sm={3} style={{ display: "flex" }}>
            <Button
              style={{ fontSize: 12 }}
              startIcon={<DownloadIcon />}
              fullWidth
              variant="outlined"
              color="outlined"
              onClick={excelDownloadApi}
            >

              {loader1 ? (
                <CircularProgress style={{ color: "#000" }} size={28} />
              ) : (
                "Download Reports"
              )}
            </Button>
          </Grid>
          <Grid item sm={3} style={{ display: "flex" }}>
            <Button startIcon={<DownloadIcon />} fullWidth variant="outlined" color="outlined" onClick={excelApi} style={{ fontSize: 12 }}>

              {loader ? (
                <CircularProgress style={{ color: "#000" }} size={28} />
              ) : (
                "Download Lead History"
              )}
            </Button>
          </Grid>
          <Grid item sm={3} style={{ display: "flex" }}>
            <Button style={{ fontSize: 12 }} fullWidth variant="outlined" color="outlined" startIcon={<FilterAltIcon />} onClick={() => {
              handleClickOpen();
            }}>
              <div>
                Filter
              </div>
              {filterCount > 0 &&
                <div style={{
                  marginLeft: "10px",
                  background: "#673695",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  color: "white",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }} >
                  {filterCount}
                </div>}
            </Button>
          </Grid>
        </Grid><br />
        {leadsAssignTableBody.length > 0 ? (
          <Grid
            item
            xs={12}
            style={{ width: "100%", overflowX: "auto", display: "inline-grid" }}
          >
            {selected.length > 0 ?
              <Form onSubmit={handleSubmit3}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <Grid container spacing={2}>
                  <Grid sm={2} item style={{ display: "flex", alignItems: "center" }}>
                    {selected.length > 0 ? (
                      <Typography
                        sx={{ flex: "1 1 100%" }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                      >
                        {selected.length} selected
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ flex: "1 1 100%" }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      ></Typography>
                    )}
                  </Grid>
                  <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      id="select-tasker"
                      // sx={{ width: 300 }}
                      fullWidth
                      options={usersData}
                      autoHighlight
                      getOptionLabel={(option) => option.full_name}
                      onChange={(e, newValue) => handleChangeTasker(newValue)}
                      renderOption={(option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {option.profile_image ? <Avatar src={option.profile_image} /> : <Avatar style={{ background: '#125693', fontWeight: 600 }}>{option.full_name && option.full_name.charAt(0)}</Avatar>}
                            <span style={{ marginTop: 10, marginLeft: 10 }}>{option.full_name}</span>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Tasker"
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid sm={4} item style={{ display: "flex", alignItems: "center" }}>
                    <FormControl variant="standard" style={{ width: "100%" }} required>
                      <InputLabel id="demo-simple-select-standard-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={statusChange}
                        label="Select Status"
                        onChange={handleChangeAssignStatus}
                      >
                        <MenuItem value="fresh">New Lead</MenuItem>
                        {/* <MenuItem value="followup">Follow Up</MenuItem> */}
                        {/* <MenuItem value="accept">Accept</MenuItem> */}
                        <MenuItem value="connected">Connected</MenuItem>
                        <MenuItem value="not_connected">Not Connected</MenuItem>
                        <MenuItem value="reject">Reject</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid sm={2} item>
                    {selected.length > 0 ? (
                      <Tooltip title="Re-Assign" style={{ float: "right" }}>
                        <IconButton>
                          {/* <DeleteIcon /> */}
                          <Button
                            className={classes.saveBtn}
                            type="submit"
                            size="large"
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "#673695" }}
                          >
                            {loader ? (
                              <CircularProgress style={{ color: "#fff" }} size={28} />
                            ) : (
                              "ReAssign"
                            )}
                          </Button>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Form>
              : ''}

            <TableContainer>
              <Table className={classes.table} size="small" aria-label="customized table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={leadsAssignTableBody.length}
                />
                <TableBody>
                  {leadsAssignTableBody.map((row, index) => {
                    const isItemSelected = isSelected(row.lead_data.lead_id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.lead_data.lead_id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(event) =>
                            handleClick1(event, row.lead_data.lead_id)
                          }
                          style={{ minWidth: 120 }}
                          align="center"
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <div
                            onClick={() =>
                              editLeadHistory(
                                row.lead_data.lead_id,
                                row.lead_data.project_id
                              )
                            }
                            style={{
                              cursor: "pointer",
                              minWidth: "70px",
                              maxWidth: "70px",
                              background: "#f2dbf5",
                              color: "#673695",
                              border: "1px solid #673695",
                              fontWeight: 600,
                              borderRadius: "2px",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "14px"
                            }}
                          >
                            {row.lead_data.lead_id}
                          </div>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <TableCell
                            align="left"
                            style={{
                              width: "30%",
                              color: "#000000",
                              borderBottom: "none",
                              padding: "0px",
                            }}
                          >
                            {row.user_data.profile_image ? <Avatar src={row.user_data.profile_image} /> : <Avatar style={{ background: '#673695', fontWeight: 600 }}>{row.user_data.full_name && row.user_data.full_name.charAt(0)}</Avatar>}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              width: "70%",
                              color: "#000000",
                              borderBottom: "none",
                              whiteSpace: 'nowrap',
                              overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                            }}
                          >
                            <label> {row.user_data.full_name}</label>
                          </TableCell>
                        </TableCell>


                        <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.call_status}</label>
                        </TableCell>

                        {row.lead_details.map(item => {
                          return item.key === "id" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis',
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "poi_id" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "type" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}


                        {row.lead_details.map(item => {
                          return item.key === "sub_type" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "district" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "state" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "name" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value != "" ? item.value : "-"}</label>
                            </TableCell>
                        })}


                        {row.lead_details.map(item => {
                          return item.key === "source" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "language" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "pincode" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value}</label>
                            </TableCell>
                        })}

                        {row.lead_details.map(item => {
                          return item.key === "user_phone" &&
                            <TableCell align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}>
                              <label> {item.value}</label>
                            </TableCell>
                        })}

                        {/* <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.cx_additional_details}</label>
                        </TableCell> */}

                        {/* <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.explain_features}</label>
                        </TableCell> */}

                        {/* <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.download_amazon_pay_business_app}</label>
                        </TableCell> */}

                        <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.download_app_on_call}</label>
                        </TableCell>

                        {/* <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.merchant_download_app}</label>
                        </TableCell> */}
                        {/* 
                        <TableCell align="left"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                          }}>
                          <label> {row.lead_data.step_to_download_app}</label>
                        </TableCell> */}


                        <TableCell align="left" style={{ minWidth: 200 }}>
                          <label>

                            {moment(row.lead_data.created_at).utc().format("DD-MM-YYYY")}{" "}
                            {moment(
                              row.lead_data.created_at,
                              "DD-MM-YYYY hh:mm A"
                            ).format("hh:mm A")}
                          </label>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 200 }}>
                          <label>

                            {moment(row.lead_data.updated_at).utc().format("DD-MM-YYYY")}{" "}
                            {moment(
                              row.lead_data.updated_at,
                              "DD-MM-YYYY hh:mm A"
                            ).format("hh:mm A")}
                          </label>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <label>

                            {row.lead_data.total_call_duration
                              ? row.lead_data.total_call_duration
                              : "00:00:00"}
                          </label>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <label>

                            {row.lead_data.last_call_duration
                              ? row.lead_data.last_call_duration
                              : "00:00:00"}
                          </label>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 150 }}>
                          <label>
                            {row.lead_data.hangup_cause.length > 0 ? row.lead_data.hangup_cause : "-"}
                          </label>
                        </TableCell>

                        {/* <TableCell align="center">
                          {row.lead_data.call_status == "fresh" ? (
                            <>
                              <Tooltip title="Remove Lead">
                                <IconButton
                                  onClick={() =>
                                    handleDelete(row.lead_data.user_lead_id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : row.lead_data.call_record != "" ? (
                            <>
                              <Tooltip title="Record">
                                <IconButton
                                  onClick={() =>
                                    handleAudio(row.lead_data.call_record)
                                  }
                                >
                                  {isPlay ? (
                                    <PauseCircleIcon />
                                  ) : (
                                    <PlayArrowIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            "NA"
                          )}
                        </TableCell> */}

                        {/* <TableCell align="left">
                          <IconButton
                            onClick={() =>
                              editLeadHistory(
                                row.lead_data.lead_id,
                                row.lead_data.project_id
                              )
                            }
                          >
                            <Tooltip title="View History">
                              <ImportContactsIcon />
                            </Tooltip>
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handleChangePage}
                  color="primary"
                  variant="outlined"
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
            {leadsAssignTableBody.length == 0 && (
              <h2 style={{ textAlign: "center" }}>
                <br />
                No Leads Found
              </h2>
            )}
          </Grid>
        ) : <>{loaderData == true ?
          <LinearProgress />
          : (

            <h2 style={{ textAlign: "center" }}>
              <br />
              No Leads found
            </h2>
          )}</>}
      </div>
      <SuccessNotification
        open={openNotification}
        msg={successMessage}
        handleClose={handleDeleteClose}
      />
      <LeadHistory open={openShow} msg={""} handleClose={handleClose11} />
      <FilterDialog
        open={open}
        handleClose={handleClose}
        setfilterCount={setfilterCount}
        attendanceStatus={attendanceStatus}
        setAtteandanceStatus={setAtteandanceStatus}
        subStatusChange={subStatusChange}
        setSubStatusChange={setSubStatusChange}
        taskerValue={taskerValue}
        setTaskerValue={setTaskerValue}
        searched={searched}
        setSearched={setSearched}
        selectedDate={selectedDate}
        handleDateChanges={handleDateChanges}
        subStatus={subStatus}
        setSubStatus={setSubStatus}
      />
    </>
  );
});
export default withRouter(ProjectTable);
