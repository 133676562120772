import "date-fns";
import React, { useEffect, useState, useContext } from "react";
import AssignLead from "components/leads/assignLead/AssignLead";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AssignProjectDetail from "../projectDetails/ProjectDetails";
import CreateLeadTable from "components/leads/createLeadTable/CreateLeadTable";
import UploadLead from "components/leads/uploadLead/UploadLead";
import AssignProject from "../assignProject/AssignProject";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignLeadTable from "components/leads/assignLeadTable/AssignLeadTable";
import { Button, Grid } from "@mui/material";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useParams } from "react-router-dom";
import { GlobalContext } from "context/GlobalState";
import { styles } from "./ProjectActionsStyle";
import { updateSpreadSheet } from "context/actions/projectAction/updateSpreadSheet";
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ProjectActions = (props) => {
  const classes = useStyles();
  const params = useParams();
  console.log("params", params);
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleSheet = () => {
    setLoader(true);
    updateSpreadSheet("")(projectDispatch);
  };

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let status =
      projectState.spreadsheetData &&
      projectState.spreadsheetData.data &&
      projectState.spreadsheetData.data.status;
    if (status == true) {
      setLoader(false);
      setSuccessMessage("spreadsheet updated successfully");
      handleNotificationClickOpen();
    }
  }, [projectState]);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            style={{ display: "flex", alignItems: "center" }}
            aria-label="basic tabs example"
          >
            <Tab icon={<AssignmentIcon />} label="Project Details" />
            {/* <Tab icon={<AssignmentIndIcon />} label="Assign Project" /> */}
            {/* <Tab icon={<BackupTableIcon />} label="Lead Table" /> */}
            <Tab icon={<DriveFolderUploadIcon />} label="Upload Leads" />
            <Tab icon={<PeopleAltIcon />} label="Assign Leads" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AssignProjectDetail />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <AssignProject />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateLeadTable />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <UploadLead />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Tabs
            value={value1}
            onChange={handleChange1}
            centered
            aria-label="basic tabs example"
          >
            <Tab label="New Leads" />
            <Tab label="Assigned Leads" />
          
          </Tabs>

          <TabPanel value={value1} index={0}>
          {params.project_id == 22 && (
            <>
                <Button
                  color="primary"
                  component="span"
                  size="small"
                  variant="contained"
                  onClick={handleSheet}
                  style={{
                    backgroundColor: "#125693",
                    height: 30,
                    float:'right',marginBottom:10
                  }}
                >
                  <GetAppIcon />
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={28} />
                  ) : (
                    " Update Spreadsheet"
                  )}
                </Button>
                <br/>
                </>
              )}
            <AssignLead />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <AssignLeadTable />
          </TabPanel>
        </TabPanel>
       
      </Box>
      <SuccessNotification
        open={open}
        msg={successMessage}
        handleClose={handleNotificationClose}
      />
    </React.Fragment>
  );
};

export default ProjectActions;
