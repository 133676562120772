/***********************  ADD PROJECT ****************************************/

import { CREATE_PROJECT,CREATE_PROJECT_ERR} from '../../actionTypes/projectType'
import { createProjectApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'


export const addProject = payload => async dispatch => {
    try {
        let results = await axios.post(`${createProjectApi}`, payload, await accessToken())
         console.log("createBookingApi", results)
        let data = results && results.data
        dispatch({
            type: CREATE_PROJECT,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: CREATE_PROJECT_ERR,
            payload: error.response
        });
    }
}

/***********************  ADD PROJECT ****************************************/