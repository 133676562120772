import { GET_LEADS, GET_LEADS_ERR } from '../../actionTypes/leadsType'
import { getLeadsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getLeads = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getLeadsApi}/${payload.page}`, await accessToken())
        let data = results && results.data
        console.log('GET Leads', data)
        dispatch({
            type: GET_LEADS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_LEADS_ERR,
            payload: error.response,
        });
    }
};


