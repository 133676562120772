import React, { useEffect, useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CircularProgress from '@material-ui/core/CircularProgress';
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import { GlobalContext } from "context/GlobalState";
import IconButton from "@material-ui/core/IconButton";
import { getUserLeads } from 'context/actions/leadsAction/getUserLeads';
import { getProjectUsers } from "context/actions/projectAction/getProjectUsers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Avatar } from "@material-ui/core";

const FilterDialog = (props) => {
    const [loader, setLoader] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [loaderData, setLoaderData] = useState(false);
    const { projectState, projectDispatch } = useContext(GlobalContext);
    const { leadsState, leadsDispatch } = useContext(GlobalContext);
    const [clearFilter, setClearFilter] = useState(false);


    const { attendanceStatus, setAtteandanceStatus, subStatusChange, setSubStatusChange, taskerValue, setTaskerValue, searched, setSearched, selectedDate, handleDateChanges, subStatus, setSubStatus } = props;

    let project_id = 0

    const [dateCount, setdateCount] = useState(0);
    const [statusCount, setStatusCount] = useState(0);
    const [subStatusCount, setSubStatusCount] = useState(0);
    const [taskerCount, setTaskerCount] = useState(0);
    const [searchedCount, setSearchedCount] = useState(0);
    const [submitted, setSubmitted] = useState(false);




    useEffect(() => {
        getProjectUsers({ project_id: project_id, page: "all" })(projectDispatch);
    }, []);

    useEffect(() => {
        console.log("projectState", projectState);
        let status = projectState.getProjectUsers.status;
        if (status === true) {
            const verifyFiltered = projectState.getProjectUsers.data.filter(
                (project) => project.onboard_status == "completed"
            );
            setUsersData(verifyFiltered);
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", projectState.getProjectUsers);
        }
        // SERVER VALIDATION ERROR RESPONSE
        if (status === 401) {
            console.log("Validation Error:", projectState.getProjectUsers);
        }
    }, [projectState]);

    useEffect(() => {
        props.setfilterCount(dateCount + searchedCount + statusCount + subStatusCount + taskerCount);
    }, [props, submitted]);


    const acceptSubStatus = [
        { id: 'interested', title: 'Interested' },
        { id: 'follow_up', title: 'Follow up' },
        { id: 'not_interested', title: 'Not interested' },
        { id: 'others', title: 'Others' }
    ]

    const followUpSubStatus = [
        { id: 'technical_issue', title: 'Technical Issue' },
        { id: 'call_back_request', title: 'Call Back Request' },
        { id: 'will_do_on_my_own', title: 'Will Do On My Own' },
        { id: 'language_issue', title: 'Language Issue' },
        { id: 'partially_onboard', title: 'Partially Onboard' },
        { id: 'others', title: 'Others' }
    ]

    const nonContactSubStatus = [
        { id: 'switch_off', title: 'Switch off' },
        { id: 'not_getting_connected', title: 'Not getting connected' },
        { id: 'rnr', title: 'RNR (Ringing Not Responding)' }
    ]

    const rejectSubStatus = [
        { id: 'disconnecting_the_call', title: 'Disconnecting the call' },
        { id: 'wrong_number', title: 'Wrong number' },
        { id: 'dnd', title: "DND" },
        { id: 'no_smart_phone', title: 'No smart phone' },
        { id: 'number_does_not_exist', title: 'Number does not exist' },
        { id: 'using_abusive_language', title: 'Using abusive language' }
    ]

    const initialDate = [null, null]

    const handleReset = () => {
        setTaskerValue("");
        setAtteandanceStatus("all");
        setSubStatusChange("all");
        handleDateChanges(initialDate);
        setSearched("");
        setSubStatus([]);
        setClearFilter(true);
        props.setfilterCount(0);
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        setLoaderData(false);
        setSubmitted(true);
        if (selectedDate[0] !== null) {
            setdateCount(1);
        } else {
            setdateCount(0);
        }

        if (attendanceStatus !== "all") {
            setStatusCount(1);
        } else {
            setStatusCount(0);
        }

        if (subStatus.length !== 0) {
            setSubStatusCount(1);
        } else {
            setSubStatusCount(0);
        }

        if (searched != "") {
            setSearchedCount(1)
        } else {
            setSearchedCount(0)
        }

        if (taskerValue !== "") {
            setTaskerCount(1);
        } else {
            setTaskerCount(0);
        }

        props.handleClose();
        if (selectedDate[0] == null && selectedDate[1] == null) {
            if (searched == "") {
                if (taskerValue == "") {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate: "all",
                        filterEndDate: "all",
                        project_id: project_id,
                        user_id: "all",
                        searched: "__search__",
                        page: "1",
                    })(leadsDispatch);
                } else {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate: "all",
                        filterEndDate: "all",
                        project_id: project_id,
                        user_id: taskerValue,
                        searched: "__search__",
                        page: "1",
                    })(leadsDispatch);
                }
            } else {
                if (taskerValue == "") {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate: "all",
                        filterEndDate: "all",
                        project_id: project_id,
                        user_id: "all",
                        searched: searched,
                        page: "1",
                    })(leadsDispatch);
                } else {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate: "all",
                        filterEndDate: "all",
                        project_id: project_id,
                        user_id: taskerValue,
                        searched: searched,
                        page: "1",
                    })(leadsDispatch);
                }
            }
        } else {
            if (searched == "") {
                let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
                let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
                if (taskerValue == "") {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate,
                        filterEndDate,
                        project_id: project_id,
                        user_id: "all",
                        searched: "__search__",
                        page: "1",
                    })(leadsDispatch);
                } else {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate,
                        filterEndDate,
                        project_id: project_id,
                        user_id: taskerValue,
                        searched: "__search__",
                        page: "1",
                    })(leadsDispatch);
                }
            } else {
                let filterStartDate = moment(selectedDate[0]).format("YYYY-MM-DD");
                let filterEndDate = moment(selectedDate[1]).format("YYYY-MM-DD");
                if (taskerValue == "") {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate,
                        filterEndDate,
                        project_id: project_id,
                        user_id: "all",
                        searched: searched,
                        page: "1",
                    })(leadsDispatch);
                } else {
                    getUserLeads({
                        type: attendanceStatus,
                        search: subStatusChange,
                        filterStartDate,
                        filterEndDate,
                        project_id: project_id,
                        user_id: taskerValue,
                        searched: searched,
                        page: "1",
                    })(leadsDispatch);
                }
            }
        }
    }

    const handleDateChange = (event) => {
        console.log('eventsss', event)
        handleDateChanges(event);
    };


    const handleStatusChange = (event) => {
        setAtteandanceStatus(event.target.value);
        if (event.target.value == "followup") {
            setSubStatus(followUpSubStatus)
        }
        else if (event.target.value == "connected") {
            setSubStatus(acceptSubStatus)
        }
        else if (event.target.value == "reject") {
            setSubStatus(rejectSubStatus)
        }
        else if (event.target.value == "not_connected") {
            setSubStatus(nonContactSubStatus)
        }
        else {
            setSubStatus([])
            setSubStatusChange('all')
        }
    };


    const handleSubStatusChange = (event) => {
        setSubStatusChange(event.target.value);

    }


    const requestSearch = (event) => {
        // if (event.key === "Enter") {
        //     setSearched(event.target.value);
        // }
        setSearched(event);
    };

    const handleTaskerChange = (newValue) => {
        if (newValue != null && newValue != '' && newValue != undefined) {
            setTaskerValue(newValue.user_id)
        } else {
            setTaskerValue(0)
        }
        setClearFilter(false)
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <div>
            <Dialog
                // disableBackdropClick
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <form onSubmit={HandleSubmit}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} >
                                <div style={{ fontSize: '24px' }}><b>Filter</b></div>
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDateRangePicker
                                        startText="Start date"
                                        endText="End date"
                                        disableMaskedInput={true}
                                        //open={openPicker}
                                        // disableOpenPicker={true}
                                        maxDate={new Date()}
                                        showDaysOutsideCurrentMonth={true}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        toolbarTitle="Select Date Range"
                                        format="DD/MM/YYYY"
                                        //  onClose={handleOnBlur}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps} fullWidth />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField
                                                    fullWidth
                                                    {...endProps}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                {selectedDate[0] != null &&
                                                                    selectedDate[1] != null ? (
                                                                    <>
                                                                        <IconButton
                                                                            //   onClick={(e) => handleClr(e)}
                                                                            style={{ padding: 0 }}
                                                                        >
                                                                            <ClearIcon style={{ fontSize: 13 }} />
                                                                        </IconButton>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item sm={(attendanceStatus == "all") || (attendanceStatus == "fresh") ? 12 : 6}>
                                <FormControl variant="standard" style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Status
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={attendanceStatus}
                                        onChange={handleStatusChange}
                                        label="Status"
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="fresh">Fresh</MenuItem>
                                        {/* <MenuItem value="followup">Follow Up</MenuItem> */}
                                        <MenuItem value="not_connected">Not Connected</MenuItem>
                                        <MenuItem value="connected">Connected</MenuItem>
                                        <MenuItem value="reject">Reject</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {(attendanceStatus != "all") && (attendanceStatus != "fresh") &&
                                <Grid item sm={6}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-standard-label">
                                            Sub Status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={subStatusChange}
                                            onChange={handleSubStatusChange}
                                            label="Status"
                                        >
                                            {
                                                subStatus.map(
                                                    item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>}
                            <Grid item sm={12}>
                                <SearchBar
                                    style={{
                                        boxShadow: "none",
                                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                                        marginTop: -1,
                                        borderRadius: 0,
                                    }}
                                    placeholder="Search by lead id seperated by comma"
                                    value={searched}
                                    onChange={(searchVal) => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                {/* <FormControl variant="standard" style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Select Agent
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={taskerValue}
                                        onChange={handleTaskerChange}
                                        label="Select Agent"
                                    >
                                        {usersData.map((item) => {
                                            return (
                                                <MenuItem value={item.user_id}>{item.full_name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl> */}
                                <Autocomplete
                                    id="select-tasker"
                                    fullWidth
                                    options={usersData}
                                    autoHighlight
                                    getOptionLabel={(option) => option.full_name}
                                    onChange={(e, newValue) => handleTaskerChange(newValue)}
                                    renderOption={(option) => (
                                        <div>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {option.profile_image ? <Avatar src={option.profile_image} /> : <Avatar style={{ background: '#125693', fontWeight: 600 }}>{option.full_name && option.full_name.charAt(0)}</Avatar>}
                                                    <span style={{ marginTop: 10, marginLeft: 10 }}>{option.full_name}</span>
                                                </div>
                                            </Box>
                                        </div>
                                    )}
                                    renderInput={(params) => {
                                        // console.log(params.inputProps.value, "checkparamsss")
                                        return (
                                            <TextField
                                                {...params}
                                                label="Select Tasker"
                                                variant="standard"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    value: clearFilter === true ? "" : params.inputProps.value,
                                                    autoComplete: 'off',
                                                }}
                                            />
                                        )
                                    }
                                    }
                                />

                            </Grid>

                            <Grid item justifyContent="space-between" alignItems="center" style={{ display: "flex" }} sm={12} >
                                <div>
                                    <h3
                                        onClick={handleReset}
                                        style={{ color: '#673695', cursor: "pointer" }}
                                    >
                                        Clear Filter
                                    </h3>
                                </div>
                                <div>
                                    <Button type="submit"
                                        style={{ textTransform: 'none', color: "white", padding: '8px 40px', background: '#673695' }}
                                        size="large"
                                    >
                                        {
                                            loader ? <CircularProgress style={{ color: '#fff', padding: '2px 10px' }} /> : "Apply"
                                        }
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default FilterDialog