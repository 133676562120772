import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../..//routes/localRoutes";
import loginBgImage from '../../assets/images/loginbanner.svg';
import { styles } from "./LoginStyle";
import { GlobalContext } from "../../context/GlobalState";
import { loginUser } from '../../context/actions/userAction/loginUser'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

const Login = React.memo((props) => {
  const { userState, userDispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loginStatus, setLoginStatus] = useState(false)
  const [loader, setLoader] = useState(false);

  const handleLogin = async (e) => {
    const postData = {
      username,
      password
    }
    setMessage("")
    setLoader(true)
    setTimeout(() => {
      loginUser(postData)(userDispatch)
    }, 1000)
  }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  useEffect(() => {
    setLoader(false)
    let status = userState.loginUser && userState.loginUser.data && userState.loginUser.data.status
    // SUCCESS RESPONSE
    if (status === true) {
      localStorage.setItem("token", userState.loginUser.data.data[0].accessToken);
      localStorage.setItem("role", userState.loginUser.data.data[1].role);
      localStorage.setItem("admin_id", userState.loginUser.data.data[1].admin_id);
      localStorage.setItem("full_name", userState.loginUser.data.data[1].full_name);
      localStorage.setItem("profile_image", userState.loginUser.data.data[1].profile_image);
      history.push(localRoute.newLeads);
      userState.loginUser = {}
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", userState.loginUser)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      setMessage("Worng username or password")
      console.log("Validation Error:", userState.loginUser)
    }
  }, [userState])



  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      history.push(localRoute.newLeads);
    }
  }, [token])

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={11} sm={11} md={7} lg={7} xl={7}
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.imgContainer}
        >
          <div className={classes.loginBg}>
            <img src={loginBgImage} alt="" style={{ width: "100%" }} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.loginInputs}
        >
          <form className={classes.loginForm} noValidate autoComplete="off">
            <Grid spacing={0}>
              <div className={classes.loginTitle}> Admin Login</div>
            </Grid>

            <Grid xs={6} spacing={0}>
              <TextField xs={6} id="filled-basic" type="text" value={username} onChange={e => setUsername(e.target.value)} label="Enter Username" InputProps={{ classes }} variant="filled" />
            </Grid>
            <Grid xs={6} spacing={0}>
              <TextField xs={6} id="filled-basic" type="password" value={password} onChange={e => setPassword(e.target.value)} label="Enter Password" InputProps={{ classes }} variant="filled" />
            </Grid>
            <Grid xs={6} spacing={0}>
              <Button variant="contained" onClick={(e) => handleLogin(e)}
                disabled={!validateForm()}
                size="large"
                className={classes.loginBtn}
              >
                {
                  loader ? <CircularProgress style={{ color: '#fff' }} size={28} /> : "Login"
                }

              </Button>
            </Grid>
            <div className={classes.errorMsg}>
              {message}
            </div>

          </form>
        </Grid>
      </Grid>
    </>
  );
});

export default withRouter(Login);
