import { alpha, makeStyles } from '@material-ui/core/styles';
export const styles = (theme) => ({
  pageTitle: {
    color: "black",
    fontWeight:"400",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    fontFamily: 'Poppins',

    '&& .MuiBox-root':{
      padding: '0px !important' ,
  },
 
  },
//   container: {
//     maxHeight: 440,
//   },

custRow:{
  border: "none",
  boxShadow: "none"
},

reportlabel:{
  color:'red'
},
paper: {
 
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize:'14px',
  width:'100%',
  color:'#125693b5',
  marginTop:-15,
},
papercount:{
  color:'#125693b5',
  fontSize:'35px',
  padding:0,
  marginTop:-2,
},
tables: {
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: '5px 1rem'
},

body:
{
  background:'red',
},

theader:
{
  border: 'none',
  color:'#888888',
  fontSize:'15px'
},
tbodytag:
{
  border: 'none',
 background:'white',
  fontSize:'15px',
  borderSpacing:'5px ',
  bordercollapse:'separate !important'
                
},
tbodyspace:
{
  borderSpacing: '0 15px !important',
  borderCollapse: 'seperate !important',
  background:'white',
},

stepperBg:{
  background:'none',
  padding:0,
  margin:0,
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':{
    marginTop: 0,
    fontSize: 9,
    color:'#777',
  },
  '& .MuiStepIcon-root.MuiStepIcon-completed':{
    color:'#4db193',
    fontSize:18,
  },
  '& .MuiStepIcon-root.MuiStepIcon-active':{
    color:'#125693',
    fontSize:18,
  },
  '& .MuiStepIcon-root':{
    fontSize:18,
  },
  '& .MuiStepConnector-alternativeLabel':
   {
    top: 8,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
    position: 'absolute',

},
'& .MuiStepConnector-lineHorizontal':{
  borderTopWidth:2,
}
},
stepperLabel:{
  fontSize:8,
},
stepperLabelColor:{
  color:'#4db193',
},
stepperTiming:{
  fontSize:8,
},

fonts:
{
  fontSize:'14px',
  marginBottom:'10px'
},
pointsTable:
{
  border:'0.5px solid #888888',
  padding:'10px',
  borderRadius:'10px'
},
aligning:
{
  padding:'10px',
  marginTop:'20px',
  marginBottom:'10px'
},
paddings:
{
  padding:'10px'
},
fonts14:
{
  fontSize:'14px',
  color:'#888888'
 
},
fonts14color:
{
  fontSize:'14px',
  color:'#186DA6'
 
},
training:
{
  backgroundColor:'#F1F8FF',
  borderRadius:'10px',
  padding:'20px'
}

});


