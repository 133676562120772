let Base_Url = ``;
if (process.env.REACT_APP_NODE_ENV === "production") {
  Base_Url = `https://api.tele.taskmo.co/api`;
} else if (process.env.REACT_APP_NODE_ENV === "development") {
  Base_Url = `https://api.tele.taskmo.co/api`;
} else {
  Base_Url = `http://localhost:8015/api`;
}

export const createBookingApi = `${Base_Url}/create-booking`;
export const assignBookingApi = `${Base_Url}/assign-booking`;
export const cancelAssignBookingApi = `${Base_Url}/cancel-assign-booking`;
export const getBookingsApi = `${Base_Url}/bookings`;
export const getBookingApi = `${Base_Url}/booking`;
export const createDxApi = `${Base_Url}/create-dx`;
export const loginUserApi = `${Base_Url}/login`;
export const getUsersApi = `${Base_Url}/users`;
export const getUserApi = `${Base_Url}/user`;
export const getProjectsApi = `${Base_Url}/projects`;
export const getProjectApi = `${Base_Url}/project`;
export const createProjectApi = `${Base_Url}/create-project`;
export const assignProjectApi = `${Base_Url}/assign-project`;
export const getAssignProjectsApi = `${Base_Url}/get-assigned-projects`;
export const updateProjectStatusApi = `${Base_Url}/update-project-status`;
export const createLeadsTableApi = `${Base_Url}/create-lead-table`;
export const getLeadsFieldsApi = `${Base_Url}/lead-fields`;
export const createLeadApi = `${Base_Url}/create-lead`;
export const getLeadsApi = `${Base_Url}/leads`;
export const downloadAllLeadsApi = `${Base_Url}/leads`;
export const getDemoLeadsApi = `${Base_Url}/demo-leads`;
export const getLeadHistoryApi = `${Base_Url}/lead-history`;
export const assignLeadApi = `${Base_Url}/assign-lead`;
export const uploadLeadApi = `${Base_Url}/upload-leads`;
export const uploadDemoLeadApi = `${Base_Url}/upload-demo-leads`;
export const reassignLeadApi = `${Base_Url}/re-assign-lead`;
export const newProjectUsersApi = `${Base_Url}/new-project-users`;
export const projectUsersApi = `${Base_Url}/project-users`;
export const userLeadsApi = `${Base_Url}/admin-user-leads`;
export const downloadLeadsApi = `${Base_Url}/get-lead-downlod`;
export const deleteAssignLeadApi = `${Base_Url}/delete-assign-lead`;
export const createImageUrl = `${Base_Url}/create-url`;
export const updateSpredsheet = `${Base_Url}/upload-spreadsheet/`;
export const getDownloadRecordApi = `${Base_Url}/call-recording`;
export const getMasterLeadsApi = `${Base_Url}/master-leads`;
export const uploadMasterLeadsApi = `${Base_Url}/upload-master-leads`;
export const updateOnboardingStatusApi = `${Base_Url}/update-onboarding-status`;
export const createUpiApi = `${Base_Url}/create-upi`;

export const uploadGeoPointsApi = `${Base_Url}/upload-geopoints`;
export const uploadGeoLeadsApi = `${Base_Url}/upload-geoleads`;
export const getGeoPointsApi = `${Base_Url}/geo-points`;