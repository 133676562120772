import React, { useEffect, useState, useContext, } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { styles } from "./LeadHistoryStyle";
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import axios from "axios";
import XLSX from 'xlsx'
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Avatar } from '@material-ui/core';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { GlobalContext } from "../../../context/GlobalState";
import Paper from '@mui/material/Paper';
import { useConfirm } from "material-ui-confirm";
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import { visuallyHidden } from '@mui/utils';
import moment from "moment";
import Toolbar from '@mui/material/Toolbar';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactAudioPlayer from 'react-audio-player';
import { getDownloadRecord } from "context/actions/leadsAction/getDownloadRecord";
const useStyles = makeStyles(styles);

let Base_Url = ``;
if (process.env.REACT_APP_NODE_ENV === "production") {
  Base_Url = `https://api.tele.taskmo.co//api`;

} else if (process.env.REACT_APP_NODE_ENV === "development") {
  Base_Url = `https://api.tele.task-mo.com/api`;
} else {
  Base_Url = `http://localhost:8002/api`;
}

const headCells = [
  {
    id: 'No',
    // numeric: false,
    // disablePadding: true,
    label: 'No',
  },
  {
    id: 'Location Rule Title',
    // numeric: true,
    // disablePadding: false,
    label: 'Location Rule Title',
  },
  {
    id: 'Sub Location Name',
    // numeric: true,
    // disablePadding: false,
    label: 'Sub Location Name',
  },
  {
    id: 'Date',
    // numeric: true,
    // disablePadding: false,
    label: 'Date',
  },
  {
    id: 'CheckIn - Out Time',
    // numeric: true,
    // disablePadding: false,
    label: 'CheckIn - Out Time',
  },
  {
    id: 'WeekOff Day',
    // numeric: true,
    // disablePadding: false,
    label: 'WeekOff Day',
  },
  {
    id: 'Holiday Day',
    // numeric: true,
    // disablePadding: false,
    label: 'Holiday Day',
  },
];


const ProjectTable = React.memo((props) => {
  //console.log('props',props)
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const [isPlay, setIsPlay] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const [totalPage, setTotalPage] = useState(0);
  const [audioValue, setAudioValue] = React.useState('');
  const [leadsAssignTableHeader, setLeadsAssignTableHeader] = useState([]);
  const [leadsAssignTableBody, setLeadsAssignTableBody] = useState([]);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  const [userData, setUserData] = React.useState("");
  let job_id = props.location.pathname && props.location.pathname.split('/').pop()


  useEffect(() => {
    // console.log('leadsState', leadsState)
    let status = leadsState.getLeadHistory && leadsState.getLeadHistory.data && leadsState.getLeadHistory.status
    if (status == true) {
      // console.log('leadsState.getUserLeads', leadsState.getUserLeads)
      if (leadsState.getLeadHistory.data.length != 0) {
        setLeadsAssignTableHeader(leadsState.getLeadHistory.data[0].lead_details)
        // setLeadsAssignTableBody(leadsState.getLeadHistory.data)
        const array = []
        leadsState.getLeadHistory.data.map((item) => {
          array.push({ ...item, playIcon: false })
        })
        setLeadsAssignTableBody(array)
        console.log('leadsAssignTableBody', array)
        setTotalPage(leadsState.getLeadHistory.data.total_page)
        setUserData(leadsState.getLeadHistory.data.userData)
        leadsState.getLeadHistory = {
          error: false,
          data: null,
          loading: false
        }
      }
      else {
        setLeadsAssignTableHeader([])
        setLeadsAssignTableBody([])
        leadsState.getLeadHistory = {
          error: false,
          data: null,
          loading: false
        }
      }
    }
  }, [leadsState])


  // useEffect(() => {
  //   // console.log('leadsState', leadsState)
  //   let status = leadsState.getDownloadRecord && leadsState.getDownloadRecord.data && leadsState.getDownloadRecord.status
  //   if (status == true) {
  //      console.log('leadsState.getDownloadRecord', leadsState.getDownloadRecord.data)
  //      const audio = new Audio(leadsState.getDownloadRecord.data);
  //         if (!isPlay) {
  //           setIsPlay(true);
  //           audio.play();
  //         } else {
  //           audio.pause();
  //           // audio.currentTime=0
  //           setIsPlay(false);

  //         }
  //     leadsState.getDownloadRecord={
  //       error:false,
  //       data:null,
  //       loading:false
  //     }
  //   }
  // }, [leadsState])


  const handleAudio = (soundFile, i) => {
    if (soundFile) {
      // getDownloadRecord(soundFile)(leadsDispatch)
      var config = {
        method: 'get',
        url: `${Base_Url}/call-recording/${soundFile}`,
        headers: {
          'x-auth-token': localStorage.getItem('token'),

        },
      };

      axios(config)
        .then(function (response) {
          console.log('requestData', response.data.data);
          setAudioValue(response.data.data)
          const audio = new Audio(response.data.data);
          setLeadsAssignTableBody(leadsAssignTableBody.map((x, key) =>
            key === i ?
              { ...x, playIcon: !x.playIcon }
              : x))
          // setLeadsAssignTableBody(prevState => ({
          //   leadsAssignTableBody: prevState.map((x, key) => (key === i ? {...x, playIcon: !x. playIcon} : x) )
          // }));
          // if (!isPlay) {
          //  
          //   audio.play();
          // } else {
          //   audio.pause();
          //   setIsPlay(false);

          // }


        })
        .catch(function (error) {
          console.log(error);
        });
    }


  };

  const downloadExcel = () => {
    const headers = ["No", "Tasker", "Lead ID", "Status", "Date", "Duration", "Note"]
    leadsAssignTableHeader && leadsAssignTableHeader.map(item => {
      if (item.key != 'created_on' && item.key != 'updated_on') {
        headers.push(item.key)
      }
    })
    let finalHeader = [...headers]
    const records = []
    leadsAssignTableBody && leadsAssignTableBody.map((item, index) => {
      const innerData = []

      innerData.push(index + 1, item.user_data.full_name, item.lead_data.lead_id, item.lead_data.call_status, moment(item.lead_data.created_at).utc().format('DD-MM-YYYY') + ' ' + moment(item.lead_data.created_at, 'DD-MM-YYYY hh:mm A').format('hh:mm A'), item.lead_data.call_duration, item.lead_data.call_note)
      const innerData1 = []
      {
        item.lead_details.map((inside_row, inside_index) => {
          if (inside_row.key != 'created_on' && inside_row.key != 'updated_on') {
            innerData1.push(inside_row.value)
          }
        })
      }
      records.push([...innerData, ...innerData1])
    })
    const data = [finalHeader, ...records]
    const newData = records;
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 })
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Details")
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    //Download

    XLSX.writeFile(workBook, "lead-report.xlsx")

  }
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <div container style={{ backgroundColor: 'white', borderRadius: '10px', }}>
            <div className={classes.root}>
              <Grid container style={{ paddingLeft: '10px' }} sm={12} spacing={2}>

                <Grid item container xs={8} >
                  <div style={{ color: '#000', fontSize: '24px' }}><b>Lead History </b></div>
                </Grid>
                <Grid item xs={4}  >
                  <Button variant="outlined" color="outlined" style={{ float: 'right' }} onClick={downloadExcel}>Download</Button>
                </Grid>
              </Grid><br />
              <Grid item xs={12} >
                <Card variant="outlined" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                  <CardContent className={classes.custInput}>

                    <Box sx={{ width: '100%' }}>


                      <TableContainer >

                        <Table className={classes.table} aria-label="customized table" >
                          <TableHead>
                            <TableRow className={classes.custRow} >
                              <TableCell align="left"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Tasker
                              </TableCell>
                              <TableCell align="left"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Lead ID
                              </TableCell>
                              <TableCell align="left"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Status
                              </TableCell>
                              <TableCell align="left"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Date
                              </TableCell>
                              <TableCell align="left"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Duration
                              </TableCell>
                              {leadsAssignTableHeader.map((headCell) => {
                                return headCell.key != 'created_on' && headCell.key != 'updated_on' && headCell.key != 'rn' ?
                                  <TableCell align="left"
                                  >
                                    {headCell.key}
                                  </TableCell>
                                  : ''
                              })}
                              <TableCell align="center"
                                style={{ minWidth: 100 }}
                                key={1}>
                                Action
                              </TableCell>

                            </TableRow>
                          </TableHead>

                          <TableBody>

                            {leadsAssignTableBody.map((row, index) => {


                              return (
                                <TableRow
                                  hover

                                >
                                  <TableCell align="left" style={{ minWidth: 150 }}>
                                    <TableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar src={row.user_data.profile_image} /></TableCell>
                                    <TableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.user_data.full_name}</label></TableCell>
                                  </TableCell>
                                  <TableCell align="left" style={{ minWidth: 100 }}><label> {row.lead_data.lead_id}</label></TableCell>

                                  <TableCell align="left" style={{ minWidth: 100 }} ><label> {row.lead_data.call_status}</label></TableCell>
                                  <TableCell align="left" style={{ minWidth: 200 }}><label>  {moment(row.lead_data.created_at).utc().format('DD-MM-YYYY')} {moment(row.lead_data.created_at, 'DD-MM-YYYY hh:mm A').format('hh:mm A')}</label></TableCell>
                                  <TableCell align="left" style={{ minWidth: 150 }} ><label> {row.lead_data.call_duration ? row.lead_data.call_duration : "00:00:00"}</label></TableCell>

                                  {row.lead_details.map((inside_row, inside_index) => {

                                    return inside_row.key != 'created_on' && inside_row.key != 'updated_on' ?
                                      <TableCell
                                        component="th"

                                        scope="row"

                                        style={{ minWidth: 120 }}
                                        align="left"
                                      >
                                        {inside_row.value}
                                      </TableCell>
                                      : ''
                                  })}

                                  <TableCell align="left" >
                                    {/* 
                                    {row.lead_data.call_record != "" ? <><Tooltip title="Record">
                                      <IconButton onClick={() => handleAudio(row.lead_data.user_lead_history_id,index)}>
                                        {row.playIcon ? <PauseCircleIcon /> : <PlayArrowIcon />}
                                      </IconButton>
                                    </Tooltip></> : "NA"} */}
                                    {row.lead_data.call_record != "" ?
                                      <ReactAudioPlayer
                                        src={row.lead_data.call_record_url}
                                        // autoPlay
                                        controls
                                      /> : "NA"}
                                  </TableCell>


                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {leadsAssignTableBody.length == 0 ?
                        <h2 style={{ textAlign: 'center', marginTop: '3%', marginBottom: '3%', color: '#1818184a' }}>No History Found</h2> : ''
                      }
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      <br />
      <br />
    </>
  );
})
export default withRouter(ProjectTable)
