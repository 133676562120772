import "date-fns";
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AddProjectStyle";
import SuccessNotification from "../../popUpNotification/SuccessNotification";
import Controls from "../../controls/Controls";
import { useForm, Form } from "../../useForm";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Avatar } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import { GlobalContext } from "../../../context/GlobalState";
import { addProject } from "../../../context/actions/projectAction/addProject";
import moment from "moment";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { createUrlForImage } from "context/actions/userAction/addImageToUser";
const useStyles = makeStyles(styles);
toast.configure();

console.log(
  moment("Mon 03-Jul-2017, 11:00 AM", "ddd DD-MMM-YYYY, hh:mm A").format(
    "hh:mm A"
  )
);

const initialFValues = {
  profile: "",
  brandname: "",
  start_date: new Date().toLocaleDateString("en-US"),
  start_time: new Date().toLocaleString("en-US"),
  jobCategory: "",
  projectObjective: "",
  location: "",
  end_date: new Date().toLocaleDateString("en-US"),
  end_time: new Date().toLocaleString("en-US"),
  language: "",
  PayoutCriteria: "",
  ProjectScript: "",
  ProjectDescription: "",
  upload_pdf: "",
};

export default function MaxWidthDialog(props) {
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const { userState, userDispatch } = useContext(GlobalContext);
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  let date = Moment().toDate();
  const [open, setOpen] = React.useState(false);

  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), heading: "", points: "0", description: "" },
  ]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), heading: "", points: "0", description: "" },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  useEffect(() => {
    setLoader(false);
    console.log("use effect", projectState);

    let status =
      projectState.addProject &&
      projectState.addProject.data &&
      projectState.addProject.data.status;
    // SUCCESS RESPONSE
    console.log("use effect ", status);
    if (status === true) {
      console.log("Project Created successfully", projectState.addProject);
      setSuccessMessage("Project created successfully");
      handleNotificationClickOpen();
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.addProject);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", projectState.addProject);
    }
  }, [projectState]);

  //console.log("projectState90 **", projectState, addProject)

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("brandname" in fieldValues)
      temp.brandname = fieldValues.brandname ? "" : "This field is required.";

    if ("jobCategory" in fieldValues)
      temp.jobCategory = fieldValues.jobCategory
        ? ""
        : "This field is required.";
    // if ("language" in fieldValues)
    //   islanguage = fieldValues.language ? "" : "This field is required.";
    if ("location" in fieldValues)
      temp.location = fieldValues.location ? "" : "This field is required.";
    if ("projectObjective" in fieldValues)
      temp.projectObjective = fieldValues.projectObjective
        ? ""
        : "This field is required.";
    if ("inchargeName" in fieldValues)
      temp.inchargeName = fieldValues.inchargeName
        ? ""
        : "This field is required.";
    if ("inchargeEmail" in fieldValues)
      temp.inchargeEmail =
        /$^|.+@.+..+/.test(fieldValues.inchargeEmail) &&
        fieldValues.inchargeEmail
          ? ""
          : "Email is not valid.";
    if ("inchargePhone" in fieldValues)
      temp.inchargePhone =
        fieldValues.inchargePhone?.length > 9 ? "" : "Min 10 numbers required.";
    if ("testName" in fieldValues)
      temp.testName = fieldValues.testName ? "" : "This field is required.";
    if ("booking_date" in fieldValues)
      temp.booking_date = fieldValues.booking_date
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handlenumberChange,
    handleaddressChange,
    // handleCapture,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleCapture = ({ target }) => {
    //console.log('upload Files')
    const { name, value } = target;
    if (value != "") {
      const fileReader = new FileReader();
      const name1 = target.accept.includes("image");
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setValues({
          ...values,
          [name]: e.target.result,
        });
      };
      let formData = new FormData();
      formData.append("file", target.files[0]);
      createUrlForImage(formData)(userDispatch);
    }
  };

  useEffect(() => {
    let status =
      userState.userImageUpload &&
      userState.userImageUpload.data &&
      userState.userImageUpload.status;
    if (status == true) {
      console.log("userState.userImageUpload", userState.userImageUpload);
      setImageUrl(userState.userImageUpload.data.url);
      userState.userImageUpload = {
        loading: false,
        error: null,
        data: [],
      };
    }
  }, [userState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);
      console.log(values.start_time);
      let project_start_time = moment(
        values.start_time,
        "MM/DD/YYYY, hh:mm A"
      ).format("hh:mm A");
      let project_end_time = moment(
        values.end_time,
        "MM/DD/YYYY, hh:mm A"
      ).format("hh:mm A");
      let postData = {
        project_name: values.brandname,
        brand_name: values.brandname,
        start_date: values.start_date,
        end_date: values.end_date,
        project_logo_image: imageUrl,
        project_logo_title: "",
        location: values.location,
        working_timings: project_start_time + " to " + project_end_time,
        project_title: values.brandname,
        project_description: values.ProjectDescription,
        project_process_points: inputFields,
        project_language: JSON.stringify(islanguage),
        project_job_category: values.jobCategory,
        project_objective: values.projectObjective,
        payout_criteria: values.PayoutCriteria,
        project_script: values.ProjectScript,
        acceptance_tasker_sign: "",
        sow: "",
        project_test_start: "",
        project_test_end: "",
        project_test_result: "",
        project_test_status: "",
        project_process_status: "",
        acceptance_status: "",
        training_status: "",
      };
      console.log("add project", postData);
      setTimeout(() => {
        addProject(postData)(projectDispatch);
        resetForm();
        setImageUrl("");
      }, 500);
    }
  };

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
    projectState.addProject = {
      loading: false,
      error: null,
      data: null,
    };
    history.push("/projects");
  };

  const jobCategoryCollection = () => [
    { id: "Development", title: "Development" },
    { id: "Marketing", title: "Marketing" },
    { id: "Accounting", title: "Accounting" },
    { id: "HR", title: "HR" },
  ];

  const languages = ["English", "Tamil", "Kannada", "Hindi", "Telugu"];
  const [islanguage, setIsLanguage] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsLanguage(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  return (
    <React.Fragment>
      <Grid container xs={12} style={{ margin: "10px" }}>
        <Grid xs={8}>
          <div style={{ color: "#888888" }}>Projects / Add New Project</div>
        </Grid>
        <Grid xs={4}>
          <Button
            style={{ color: "#186DA6", float: "right", textTransform: "none" }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <b>Back</b>
          </Button>
        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit}>
        <Grid className={classes.layoutMargin1}>
          <Grid xs={12} container>
            <Card variant="outlined" className={classes.card}>
              <CardContent className={classes.custInput}>
                <Grid
                  xs={12}
                  container
                  alignItems="center"
                  style={{ paddingBottom: "0px" }}
                >
                  <Grid item container justify="flex-start" sm={6}>
                    <h2>
                      <b>Add New Project</b>
                    </h2>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    container
                    justify="flex-end"
                    sm={6}
                  >
                    <div>
                      <Button
                        className={classes.saveBtn}
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#125693" }}
                      >
                        {loader ? (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={28}
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                      {/* <Controls.Button
text="Reset"
color="default"
onClick={resetForm} /> */}
                    </div>
                  </Grid>
                </Grid>
                <div
                  style={{
                    borderTop: "1px solid #B8B8B8 ",
                    marginBottom: "10px",
                    margin: "10px",
                  }}
                ></div>
                <Grid item container xs={12}>
                  <Grid
                    xs={1}
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <Avatar
                      style={{
                        width: "80px",
                        height: "80px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                      src={values.profile}
                    />
                  </Grid>
                  <Grid xs={10} style={{ marginTop: "15px" }}>
                    <input
                      accept="image/*"
                      type="file"
                      className={classes.input}
                      id="icon-button-photo"
                      onChange={handleCapture}
                      name="profile"
                      error={errors.profile}
                    />
                    <label htmlFor="icon-button-photo">
                      <Button
                        color="primary"
                        component="span"
                        size="large"
                        variant="contained"
                        style={{ backgroundColor: "#125693" }}
                      >
                        <GetAppIcon />
                        Upload Brand logo
                      </Button>
                    </label>
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  <Grid item xs={6} className={classes.paper}>
                    <Controls.Input
                      name="brandname"
                      label="Brand name"
                      value={values.brandname}
                      onChange={handleInputChange}
                      error={errors.brandname}
                    />
                    {/* <Controls.DatePicker
                      name="start_date"
                      label="Start Date"
                      value={values.start_date}
                      onChange={handleInputChange}
                    /> */}
                    <Controls.TimePicker
                      name="start_time"
                      label="Start Time"
                      value={values.start_time}
                      onChange={handleInputChange}
                    />
                    <Controls.Select
                      name="jobCategory"
                      label="Job Category"
                      fullWidth
                      value={values.jobCategory}
                      onChange={handleInputChange}
                      options={jobCategoryCollection()}
                      error={errors.jobCategory}
                    />
                    <Controls.TextArea
                      name="projectObjective"
                      label="Project Objective"
                      value={values.projectObjective}
                      onChange={handleInputChange}
                      error={errors.projectObjective}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.paper}>
                    <Controls.Input
                      label="Location"
                      name="location"
                      value={values.location}
                      onChange={handleInputChange}
                      error={errors.location}
                    />
                    {/* <Controls.DatePicker
                      name="end_date"
                      label="End Date"
                      value={values.end_date}
                      onChange={handleInputChange}
                    /> */}
                    <Controls.TimePicker
                      name="end_time"
                      label="End Time"
                      value={values.end_time}
                      onChange={handleInputChange}
                    />
                    {/* <Controls.Select
                      name="language"
                      label="Languages"
                      value={values.language}
                      onChange={handleInputChange}
                      options={languageCollection()}
                      error={errors.language}
                    /> */}

                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      style={{
                        textAlign: "left",
                        fontSize: "small",
                        marginTop: "-2px",
                      }}
                    >
                      Select Language
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={islanguage}
                      onChange={handleLanguageChange}
                      variant="standard"
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{
                        width: "100%",
                      }}
                    >
                      {languages.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={islanguage.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {/* <Grid xs={12} className={classes.paddings}>
                  <h4 style={{ color: '#186DA6' }}>Lead approval process & points</h4>
                </Grid> */}
                {/* <Grid container spacing={3} className={classes.paddings}  >
                  {inputFields.map((inputField, i) => (

                    <Grid item xs={6} key={inputField.id}>
                      <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
                        <Grid container>
                          <Grid item xs={6}>
                            <div className={classes.fonts14}>Step  {(i + 1)}</div><br />
                          </Grid>
                          <Grid item xs={6} > */}
                {/* {inputFields.length!=1 &&
                <IconButton style={{float:'right'}} disabled={inputFields.length === 1} onClick={() => handleRemoveFields(inputField.id)} >
                <div style={{fontSize:'12px',float:'right',color:'#186DA6'}}>Remove</div>
                </IconButton>
                } */}
                {/* </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={6}> */}
                {/* <Controls.Input
                              name="heading"
                              label="Heading"
                              value={inputField.heading}
                              onChange={event => handleChangeInput(inputField.id, event)}
                              error={errors.heading}
                            /> */}
                {/* <TextField
                             id="standard-fullname-input"
                             name="heading"
                             label="Heading"
                             fullWidth
                             autoComplete="current-fullname"
                             value={inputField.heading}
                             onChange={event => handleChangeInput(inputField.id, event)}
                             required
                           /> */}
                {/* </Grid>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <MonetizationOnIcon style={{ color: 'black' }} />
                          </Grid>
                          <Grid item xs={5}>
                            <Controls.Select
                              name="points"
                              style={{ borderBottom: 'none' }}
                              fullWidth

                              value={inputField.points}
                              onChange={event => handleChangeInput(inputField.id, event)}
                              options={pointsCollection()}
                              error={errors.points}
                            />
                          </Grid>
                          <Grid item xs={12}> */}
                {/* <Controls.TextArea
                              name="description"
                              label="Enter description"
                              value={inputField.description}
                              onChange={event => handleChangeInput(inputField.id, event)}
                              error={errors.description}
                            /> */}
                {/* <TextField
                             id="standard-fullname-input"
                             name="description"
                             label="Description"
                             fullWidth
                             autoComplete="current-fullname"
                             value={inputField.description}
                             onChange={event => handleChangeInput(inputField.id, event)}
                             required
                             multiline
                             rows={3}
                           /> */}
                {/* </Grid>
                          <Grid container>
                            <Grid item xs={10}>
                            </Grid>
                            <Grid item xs={2} >
                              {inputFields.length != 1 &&
                                <Button variant="contained" color="primary" size="small" style={{ float: 'right', color: 'white', backgroundColor: '#125693' }} disabled={inputFields.length === 1} onClick={() => handleRemoveFields(inputField.id)} >
                                  <DeleteIcon /><div style={{ fontSize: '12px', float: 'right', color: 'white' }}>Remove</div>
                                </Button>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                  <Grid item xs={6} onClick={handleAddFields}>
                    <Paper className={classes.paper} elevation={0} className={classes.pointsTable} style={{ backgroundColor: '#F2F2F2' }}>
                      <Grid container style={{ textAlign: 'center' }}>
                        <Grid item xs={12} >
                          <AddCircleIcon style={{ color: '#B8B8B8', width: '85px', height: '85px', justifyContent: "center", textAlign: 'center', marginTop: 25 }} />
                        </Grid>
                      </Grid>
                      <div className={classes.fonts} style={{ marginTop: '10px', textAlign: 'center', color: '#888888' }}> Add more lead approval steps </div>
                    </Paper>
                  </Grid>
                </Grid> */}
                {/* <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <h4 style={{ color: '#186DA6' }}>Payout Criteria</h4>
                    <Controls.TextArea
                      name="PayoutCriteria"
                      label="Enter Criteria"
                      value={values.PayoutCriteria}
                      onChange={handleInputChange}
                      error={errors.PayoutCriteria}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h4 style={{ color: '#186DA6' }}>Project Script</h4>
                    <Controls.TextArea
                      name="ProjectScript"
                      label="Project Script"
                      value={values.ProjectScript}
                      onChange={handleInputChange}
                      error={errors.ProjectScript}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h4 style={{ color: '#186DA6' }}>Upload video</h4>
                    <Paper className={classes.paper} elevation={0} className={classes.pointsTable} style={{ backgroundColor: '#F2F2F2' }}>
                      <Grid container style={{ textAlign: 'center' }}>
                        <Grid item xs={12} >
                          <GetAppIcon style={{ color: '#B8B8B8', width: '85px', height: '85px', justifyContent: "center", textAlign: 'center', marginTop: 25 }} />
                        </Grid>
                      </Grid>
                      <div className={classes.fonts} style={{ marginTop: '10px', textAlign: 'center', color: '#888888' }}> Upload project video </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <h4 style={{ color: '#186DA6' }}>Project Description</h4>
                    <Controls.TextArea
                      name="ProjectDescription"
                      label="Enter Project Description"
                      value={values.ProjectDescription}
                      onChange={handleInputChange}
                      error={errors.ProjectDescription}
                    />
                  </Grid>
                </Grid> */}
              </CardContent>
              {/* <Grid xs={12} className={classes.paddings}>
                <h4 style={{ color: '#186DA6' }}>Project test question</h4>
              </Grid> */}
              {/* <Grid container xs={12} >
                <Grid item xs={4} className={classes.paper}>
                  <Controls.Input
                    label="Upload test question and answer"
                    name="upload"
                    value={values.fileupload}
                    onChange={handleInputChange}
                    error={errors.fileupload}
                  />
                </Grid>
                <Grid item xs={2} className={classes.paper}>
                  <input
                    accept="files/*"
                    className={classes.input}
                    id="icon-button-photo"
                    onChange={handleCapture}
                    type="file"
                    name='upload_pdf'
                    error={errors.upload_pdf}
                  />
                  <label htmlFor="icon-button-photo">
                    <Button color="primary" component="span" size="large" variant="contained" color="primary" style={{ backgroundColor: '#125693' }}><GetAppIcon />Upload</Button>
                  </label>
                </Grid>
                <Grid item xs={6} className={classes.paper}>
                </Grid>
              </Grid> */}
            </Card>
          </Grid>
        </Grid>
      </Form>
      <SuccessNotification
        open={open}
        msg={successMessage}
        handleClose={handleNotificationClose}
      />
    </React.Fragment>
  );
}
