import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./LeadsDetailStyle";
import LeadsDetails from "../../../components/leads/leadsDetail"

const LeadsDetail = React.memo((props) => {
  return (
    <>
     <LeadsDetails />
    </>
  );
});

export default withRouter(LeadsDetail);
