export default {
  taskers: {
    loading: false,
    error: null,
    data: [],
    isSearchActive: false,
    foundprojects: [],
  },
  addTasker: {
    loading: false,
    error: null,
    data: null,
  },
  getTaskers: {
    loading: false,
    error: null,
    data: null,
  },
  getTasker: {
    loading: false,
    error: null,
    data: null,
  },
  removeTasker: {
    loading: false,
    error: null,
    data: null,
  },
  editTasker: {
    loading: false,
    error: null,
    data: null,
  },
  updateOnboarding: {
    loading: false,
    error: null,
    data: [],
  }
};
