export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_ERR = "CREATE_PROJECT_ERR";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_ERR = "GET_PROJECTS_ERR";
export const GET_ASSIGN_PROJECTS = "GET_ASSIGN_PROJECTS";
export const GET_ASSIGN_PROJECTS_ERR = "GET_ASSIGN_PROJECTS_ERR";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_ERR = "GET_PROJECT_ERR";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_ERR = "UPDATE_PROJECT_ERR";
export const ASSIGN_PROJECT = "ASSIGN_PROJECT";
export const ASSIGN_PROJECT_ERR = "ASSIGN_PROJECT_ERR";
export const UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS";
export const UPDATE_PROJECT_STATUS_ERR = "UPDATE_PROJECT_STATUS_ERR";
export const GET_NEW_PROJECT_USERS = "GET_NEW_PROJECT_USERS";
export const GET_NEW_PROJECT_USERS_ERR = "GET_NEW_PROJECT_USERS_ERR";
export const GET_PROJECT_USERS = "GET_PROJECT_USERS";
export const GET_PROJECT_USERS_ERR = "GET_PROJECT_USERS_ERR";
export const UPDATE_SPREADSHEET = "UPDATE_SPREADSHEET";
export const UPDATE_SPREADSHEET_ERR = "UPDATE_SPREADSHEET_ERR";
