
export const CREATE_LEADS_TABLE = 'CREATE_LEADS_TABLE';
export const CREATE_LEADS_TABLE_ERR = 'CREATE_LEADS_TABLE_ERR';
export const GET_LEADS_FIELDS = 'GET_LEADS_FIELDS';
export const GET_LEADS_FIELDS_ERR = 'GET_LEADS_FIELDS_ERR';
export const CREATE_LEAD = 'CREATE_LEAD';
export const CREATE_LEAD_ERR = 'CREATE_LEAD_ERR';
export const GET_LEADS = 'GET_LEADS';
export const GET_LEADS_ERR = 'GET_LEADS_ERR';
export const DOWNLOAD_ALL_LEADS = 'DOWNLOAD_ALL_LEADS';
export const DOWNLOAD_ALL_LEADS_ERR = 'DOWNLOAD_ALL_LEADS_ERR';
export const ASSIGN_LEAD = 'ASSIGN_LEAD';
export const ASSIGN_LEAD_ERR = 'ASSIGN_LEAD_ERR';
export const UPLOAD_LEAD = 'UPLOAD_LEAD';
export const UPLOAD_LEAD_ERR = 'UPLOAD_LEAD_ERR';
export const GET_USER_LEADS = 'GET_USER_LEADS';
export const GET_USER_LEADS_ERR = 'GET_USER_LEADS_ERR';

export const DELETE_ASSIGN_LEAD = 'DELETE_ASSIGN_LEAD';
export const DELETE_ASSIGN_LEAD_ERR = 'DELETE_ASSIGN_LEAD_ERR';

export const GET_USER_LEADS_DOWNLOAD = 'GET_USER_LEADS_DOWNLOAD';
export const GET_USER_LEADS_DOWNLOAD_ERR = 'GET_USER_LEADS_DOWNLOAD_ERR';

export const GET_USER_LEADS_DOWNLOAD_REPORTS = 'GET_USER_LEADS_DOWNLOAD_REPORTS';
export const GET_USER_LEADS_DOWNLOAD_REPORTS_ERR = 'GET_USER_LEADS_DOWNLOAD_REPORTS_ERR';

export const GET_DOWNLOAD_RECORD = 'GET_DOWNLOAD_RECORD';
export const GET_DOWNLOAD_RECORD_ERR = 'GET_DOWNLOAD_RECORD_ERR';

export const GET_LEAD_HISTORY = 'GET_LEAD_HISTORY';
export const GET_LEAD_HISTORY_ERR = 'GET_LEAD_HISTORY_ERR';

export const RE_ASSIGN_LEAD = 'RE_ASSIGN_LEAD';
export const RE_ASSIGN_LEAD_ERR = 'RE_ASSIGN_LEAD_ERR';

export const UPLOAD_DEMO_LEAD = 'UPLOAD_DEMO_LEAD';
export const UPLOAD_DEMO_LEAD_ERR = 'UPLOAD_DEMO_LEAD_ERR';

export const GET_DEMO_LEADS = 'GET_DEMO_LEADS';
export const GET_DEMO_LEADS_ERR = 'GET_DEMO_LEADS_ERR';