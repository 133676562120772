export default {
  leads: {
    loading: false,
    error: null,
    data: [],
    isSearchActive: false,
    foundprojects: [],
  },
  createLeadsTable: {
    loading: false,
    error: null,
    data: null,
  },
  createLead: {
    loading: false,
    error: null,
    data: null,
  },
  getLeadsFields: {
    loading: false,
    error: null,
    data: null,
  },
  getLeads: {
    loading: false,
    error: null,
    data: null,
  },
  downloadAllLeadsInitState: {
    loading: false,
    error: null,
    data: null,
  },
  getDemoLeads: {
    loading: false,
    error: null,
    data: null,
  },
  getUserLeads: {
    loading: false,
    error: null,
    data: null,
  },
  getUserLeadsDownload: {
    loading: false,
    error: null,
    data: null,
  },
  getUserLeadsDownloadReports: {
    loading: false,
    error: null,
    data: null,
  },
  assignLead: {
    loading: false,
    error: null,
    data: null,
  },
  reassignLead: {
    loading: false,
    error: null,
    data: null,
  },
  uploadLead: {
    loading: false,
    error: null,
    data: null,
  },
  deleteAssignLead: {
    loading: false,
    error: null,
    data: null,
  },
  getDownloadRecord: {
    loading: false,
    error: null,
    data: null,
  },
  getLeadHistory: {
    loading: false,
    error: null,
    data: null,
  },
  uploadDemoLead: {
    loading: false,
    error: null,
    data: null,
  },
};
