import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory,
} from "react-router-dom";
import Drawer from "../components/drawer";
import Navigation from "../components/navigation";
import Login from "../pages/login";
import Upi from "../pages/upi";
import Jobs from "../pages/project";
import ScrollToTop from "../components/ScrollToTop";
import { localRoute } from "../routes/localRoutes";
import { SignalCellularNullSharp } from "@material-ui/icons";
import { ConfirmProvider } from "material-ui-confirm";
import GeoPoint from "pages/geoPoint/GeoPoint";

function RouterComponent(props) {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <ConfirmProvider>

          <Switch>
            <Route exact path={localRoute.projects} component={Drawer} />
            <Route exact path={`${localRoute.addBooking}`} component={Drawer} />
            <Route exact path={`${localRoute.addDx}`} component={Drawer} />
            <Route exact path={`${localRoute.dxList}`} component={Drawer} />
            <Route exact path={`${localRoute.projectDetail}/:project_id`} component={Drawer} />
            <Route exact path={`${localRoute.taskerDetail}/:project_id`} component={Drawer} />
            <Route exact path={`${localRoute.leadsDetail}/:project_id`} component={Drawer} />
            <Route exact path={`${localRoute.demoLead}`} component={Drawer} />
            <Route exact path={`${localRoute.taskerList}`} component={Drawer} />
            <Route exact path={`${localRoute.leadsList}`} component={Drawer} />
            <Route exact path={`${localRoute.assignProject}/:project_id`} component={Drawer} />
            <Route exact path={`${localRoute.voiceTest}`} component={Drawer} />
            <Route exact path={`${localRoute.referCode}`} component={Drawer} />
            <Route exact path={`${localRoute.addProject}`} component={Drawer} />
            <Route exact path={`${localRoute.addVoiceTest}`} component={Drawer} />

            <Route exact path={`${localRoute.uploadLeads}`} component={Drawer} />
            <Route exact path={`${localRoute.newLeads}`} component={Drawer} />
            <Route exact path={`${localRoute.assignLeadTable}`} component={Drawer} />
            <Route exact path={`${localRoute.master}`} component={Drawer} />
            <Route exact path="/" component={Login} />
            <Route exact path={`${localRoute.upi}/:data_limit`} component={Upi} />
            <Route exact path={`${localRoute.geoPoint}`} component={GeoPoint} />
          </Switch>
        </ConfirmProvider>

      </Router>
    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
