import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AssignLeadsStyle";
import AssignLead from "../../components/assignLead";
const AssignLeadPage = React.memo((props) => {
  return (
    <>
     <AssignLead/>
    </>
  );
});

export default withRouter(AssignLeadPage);
