import { UPLOAD_LEAD, UPLOAD_LEAD_ERR } from '../../actionTypes/leadsType'
import { uploadLeadApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const uploadLead = (payload) => async(dispatch) => {
    try {
        let results = await axios.post(`${uploadLeadApi}`, payload, await accessToken())
        //  console.log("createBookingApi", payload)
        let data = results && results.data
        dispatch({
            type: UPLOAD_LEAD,
            payload: data,
          });
    } catch (error) {
        dispatch({
            type: UPLOAD_LEAD_ERR,
            payload: error.response,
        });
    }
};


