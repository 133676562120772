import { GET_MASTER_LEADS, GET_MASTER_LEADS_ERR, UPLOAD_MASTER_LEAD, UPLOAD_MASTER_LEAD_ERR } from "context/actionTypes/MasterType";


export default (state, action) => {
    switch (action.type) {

        case GET_MASTER_LEADS:
            return {
                ...state,
                leads: action.payload, ...state.data,
            }

        case GET_MASTER_LEADS_ERR:
            return {
                ...state,
                leads: action.payload, ...state.data,
            }

        case UPLOAD_MASTER_LEAD:
            return {
                ...state,
                uploadLead: action.payload, ...state.data,
            }

        case UPLOAD_MASTER_LEAD_ERR:
            return {
                ...state,
                uploadLead: action.payload, ...state.data,
            }

        default:
            return state
    }
}