import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory,
} from "react-router-dom";
import { localRoute } from "../../routes/localRoutes";
import Projects from "../../pages/project/projects";
import AddBooking from "../../pages/project/addProject";

import taskerList from "../../pages/tasker/taskerList";
import leadsList from "../../pages/leads/leadsList";
import voiceTest from "../../pages/voice/voiceTest";
import referCode from "../../pages/refer/referCode";
import assignProject from "../../pages/project/assignProject";
// import ExpressJob from "../../pages/expressJob";
// import AppJob from "../../pages/appJob";
// import AddInformation from "../../pages/addInformation";
// import AddDiscount from "../../pages/addDiscount";
// import AddCommission from "../../pages/addCommission";
// import PromoCode from "../../pages/promoCode";
// import IssueTracker from "../../pages/issueTracker";
// import DownloadTrack from "../../pages/downloadTrack";
import NotFound from "../../pages/notFound";
import NotFound500 from "../../pages/notFound500";
import projectDetail from "../../pages/project/projectDetail";
import taskerDetail from "../../pages/tasker/taskerDetail";
//import AddCommission from "../../pages/addCommission";
import addProject from "../../pages/project/addProject";
import leadsDetail from "../../pages/leads/leadsDetail";
import addVoiceTest from "../../pages/voice/addVoiceTest";
import uploadDemoLead from "../../components/leads/uploadDemoLead";
import uploadLeads from "../../pages/uploadLeads";
import assignLead from "../../pages/assignLead";
import assignLeadTable from "../../pages/assignLeadTable";
import MasterTablePage from "pages/masterTable/MasterTablePage";
function RouterComponent(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${localRoute.projects}`} component={Projects} />
        <Route exact path={`${localRoute.addBooking}`} component={AddBooking} />
        <Route exact path={`${localRoute.taskerList}`} component={taskerList} />
        <Route exact path={`${localRoute.leadsList}`} component={leadsList} />
        <Route exact path={`${localRoute.voiceTest}`} component={voiceTest} />
        <Route exact path={`${localRoute.referCode}`} component={referCode} />
        <Route exact path={`${localRoute.addProject}`} component={addProject} />
        <Route exact path={`${localRoute.addVoiceTest}`} component={addVoiceTest} />
        <Route exact path={`${localRoute.projectDetail}/:project_id`} component={projectDetail} />
        <Route exact path={`${localRoute.taskerDetail}/:project_id`} component={taskerDetail} />
        <Route exact path={`${localRoute.leadsDetail}/:project_id`} component={leadsDetail} />
        <Route exact path={`${localRoute.assignProject}/:project_id`} component={assignProject} />
        <Route exact path={`${localRoute.demoLead}`} component={uploadDemoLead} />
        <Route exact path={`${localRoute.uploadLeads}`} component={uploadLeads} />
        <Route exact path={`${localRoute.newLeads}`} component={assignLead} />
        <Route exact path={`${localRoute.assignLeadTable}`} component={assignLeadTable} />
        <Route exact path={`${localRoute.master}`} component={MasterTablePage} />

        {/* <Route exact path={`${localRoute.appJob}`} component={AppJob} />
        <Route exact path={`${localRoute.addInformation}`} component={AddInformation} />
        <Route exact path={`${localRoute.addDiscount}`} component={AddDiscount} />
        <Route exact path={`${localRoute.addCommission}`} component={AddCommission} />
        <Route exact path={`${localRoute.promoCode}`} component={PromoCode} />
        <Route exact path={`${localRoute.issueTracker}`} component={IssueTracker} />
        <Route exact path={`${localRoute.downloadTrack}`} component={DownloadTrack} /> */}
        <Route
          exact
          path={`${localRoute.notFound}`}
          component={NotFound}
        />
        <Route
          exact
          path={`${localRoute.notFound500}`}
          component={NotFound500}
        />
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
