import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { styles } from "./ProjectDetailStyle";
import PlusIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import "../../../App.css";
import { Avatar } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { CardMedia } from "@material-ui/core";
import { GlobalContext } from "../../../context/GlobalState";
import { getProject } from "../../../context/actions/projectAction/getProject";
import moment from "moment";
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const { projectState, projectDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [projectDetail, setProjectDetail] = React.useState("");
  const [processPoints, setProcessPoints] = React.useState([]);
  const [languages, setLanguages] = React.useState("");

  const classes = useStyles();
  //const dispatch = useDispatch()

  useEffect(() => {
    getProject(projectId)(projectDispatch);
  }, []);

  useEffect(() => {
    let status = projectState && projectState.getProject.status;
    // SUCCESS RESPONSE
    if (status === true) {
      console.log("projectState() ", projectState.getProject.data);
      setProjectDetail(projectState.getProject.data);
      setProcessPoints(projectState.getProject.data.project_process_points);

      if (
        projectState.getProject.data.project_language != "" &&
        projectState.getProject.data.project_language != null
      ) {
        setLanguages(JSON.parse(projectState.getProject.data.project_language));
      }
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProject);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProject);
    }
  }, [projectState, projectDetail]);

  console.log("languages", languages);

  return (
    <>
      <Grid container xs={12} style={{ margin: "10px" }}>
        <Grid xs={8}>
          <div style={{ color: "#888888" }}>
            Projects / {projectDetail.project_name}
          </div>
        </Grid>
        <Grid xs={4}>
          <Button
            style={{ color: "#186DA6", float: "right", textTransform: "none" }}
            onClick={(event) => (window.location.href = "/projects")}
          >
            <ArrowBackIcon />
            <b>Back</b>
          </Button>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Grid
          xs={12}
          container
          alignItems="center"
          style={{ paddingBottom: "0px" }}
        >
          <Grid item container justify="flex-start" sm={6}>
            <h2 className={classes.pageTitle}>
              <b>{projectDetail.project_name}</b>
            </h2>
          </Grid>
          <Grid item direction="row" container justify="flex-end" sm={6}></Grid>
        </Grid>
        <div
          style={{
            borderTop: "1px solid #B8B8B8 ",
            marginBottom: "10px",
            margin: "10px",
          }}
        ></div>
        <Grid item container xs={12}>
          <Grid xs={1} style={{ justifyContent: "center", display: "flex" }}>
            <Avatar
              style={{
                width: "80px",
                height: "80px",
                justifyContent: "center",
                display: "flex",
              }}
              src={projectDetail.project_logo_image}
            />
          </Grid>
          <Grid xs={10} style={{ marginTop: "15px" }}>
            <div style={{ fontSize: "16px" }}>
              <b>{projectDetail.project_name}</b>
            </div>
            <div className={classes.fonts}>{projectDetail.location}</div>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.aligning}>
          <Grid xs={3}>
            <label className={classes.fonts}>
              <b>Start date </b>
            </label>
            <br />
            <label className={classes.fonts}>
              <b>Timing </b>
            </label>
            <br />
            <label className={classes.fonts}>
              <b>Project Lead</b>
            </label>
            <br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>
              &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;
              <label>
                {moment(projectDetail.start_date).utc().format("DD-MM-YYYY")}
              </label>
            </label>
            <br />
            <label className={classes.fonts}>
              &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{projectDetail.working_timings}{" "}
            </label>
            <br />
            <label className={classes.fonts}>
              &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;Ravindra Kumar
            </label>
            <br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>
              <b>Job Category </b>
            </label>
            <br />
            <label className={classes.fonts}>
              <b>Project Objective </b>
            </label>
            <br />
            {projectDetail.project_language && (
              <label className={classes.fonts}>
                <b>Language</b>
              </label>
            )}
            <br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>
              &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;
              {projectDetail.project_job_category}
            </label>
            <br />
            <label className={classes.fonts}>
              &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;
              {projectDetail.project_objective}
            </label>
            <br />
            {projectDetail.project_language && (
              <label className={classes.fonts}>
                &nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;
                {languages && languages.join(", ")}
              </label>
            )}
            <br />
          </Grid>
        </Grid>

        {/* <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: "#186DA6" }}>Lead approval process & points</h4>
        </Grid> */}
        {/* <Grid container spacing={3} className={classes.paddings}>
          {processPoints.map((object, i) => {
            return (
              <Grid item xs={6}>
                <Paper
                  className={classes.paper}
                  elevation={0}
                  className={classes.pointsTable}
                >
                  <div className={classes.fonts14}>{object.heading}</div>
                  <br />
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.fonts14color}>
                        <b>Quality Check Pass</b>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <MonetizationOnIcon
                        style={{ float: "right", color: "#888888" }}
                      />
                      <label
                        style={{ float: "right" }}
                        className={classes.fonts14}
                      >
                        {object.points} <label>Points</label>
                      </label>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      borderTop: "1px solid #B8B8B8 ",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  ></div>
                  <div className={classes.fonts} style={{ marginTop: "10px" }}>
                    {object.description}
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid> */}
        {/* {projectDetail.payout_criteria && (
          <Grid xs={12} className={classes.paddings}>
            <h4 style={{ color: "#186DA6" }}>Payout Criteria</h4>
            <div className={classes.fonts}>{projectDetail.payout_criteria}</div>
          </Grid>
        )}
        {projectDetail.project_script && (
          <Grid xs={12} className={classes.paddings}>
            <h4 style={{ color: "#186DA6" }}>Project script</h4>
            <div className={classes.fonts}>{projectDetail.project_script}</div>
          </Grid>
        )}
        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: "#186DA6" }}>Training Video</h4>
          <Grid container className={classes.training}>
            <Grid item xs={7}>
              <CardMedia
                component="video"
                className={classes.media}
                image={"path/to/file/video.webm"}
                autoPlay
              />
            </Grid>
            <Grid item xs={5}>
              <div className={classes.fonts}>
                <b>Video description</b>
              </div>
              <div className={classes.fonts}>
                {projectDetail.project_description}
              </div>
              <Link className={classes.fonts} style={{ color: "#186DA6" }}>
                View Training question
              </Link>
            </Grid>
          </Grid>
        </Grid> */}
      </div>
    </>
  );
});
export default withRouter(BookingTable);
