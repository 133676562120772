import { GET_TASKERS, GET_TASKERS_ERR, GET_TASKER, GET_TASKER_ERR, UPDATE_ONBOARD_STATUS, UPDATE_ONBOARD_STATUS_ERR } from '../../actionTypes/taskerType'

export default (state, action) => {
  console.log(action)
  switch (action.type) {
    case GET_TASKERS:
      return {
        ...state,
        getTaskers: action.payload, ...state.data,
      }

    case GET_TASKERS_ERR:
      return {
        ...state,
        getTaskers: { getdata: action.payload }
      }
    case GET_TASKER:
      return {
        ...state,
        getTasker: action.payload, ...state.data,
      }

    case GET_TASKER_ERR:
      return {
        ...state,
        getTasker: { getdata: action.payload }
      }
    case UPDATE_ONBOARD_STATUS:
      return {
        ...state,
        updateOnboarding: action.payload, ...state.data,
      }
    case UPDATE_ONBOARD_STATUS_ERR:
      return {
        ...state,
        updateOnboarding: action.payload, ...state.data,
      }
    default:
      return state;
  }
}