import { GET_PROJECTS, GET_PROJECTS_ERR } from '../../actionTypes/projectType'
import { getProjectsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getProjects = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getProjectsApi}/all`, await accessToken())
        let data = results && results.data
        console.log('GET PROJECTS', data)
        dispatch({
            type: GET_PROJECTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_PROJECTS_ERR,
            payload: error.response,
        });
    }
};


