export default {
  projects: {
    loading: false,
    error: null,
    data: [],
    isSearchActive: false,
    foundprojects: [],
  },
  addProject: {
    loading: false,
    error: null,
    data: null,
  },
  getProjects: {
    loading: false,
    error: null,
    data: null,
  },
  getAssignProjects: {
    loading: false,
    error: null,
    data: null,
  },
  getProject: {
    loading: false,
    error: null,
    data: null,
  },
  removeProject: {
    loading: false,
    error: null,
    data: null,
  },
  editProject: {
    loading: false,
    error: null,
    data: null,
  },
  assignProject: {
    loading: false,
    error: null,
    data: null,
  },
  getNewProjectUsers: {
    loading: false,
    error: null,
    data: null,
  },
  getProjectUsers: {
    loading: false,
    error: null,
    data: null,
  },

  spreadsheetData: {
    loading: false,
    error: null,
    data: [],
  },
};
