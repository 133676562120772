import 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import { styles } from "./ProjectDetailsStyle";
import { GlobalContext } from "../../../context/GlobalState";
import SuccessNotification from '../../popUpNotification/SuccessNotification'
import FailureNotification from '../../popUpNotification/FailureNotification';
import AssignProject from '../assignProject'
import CreateLeadTable from '../../leads/createLeadTable'
import Createlead from '../../leads/createLead'
import UploadLead from '../../leads/uploadLead'
import AssignLead from '../../leads/assignLead'
import { getProject } from "../../../context/actions/projectAction/getProject";
import { Card } from '@mui/material';
toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = React.useState(false);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [projectDetail, setProjectDetail] = useState("");
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  let projectId = props.location.pathname && props.location.pathname.split('/').pop()
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    getProject(projectId)(projectDispatch)
  }, [])

  useEffect(() => {
    console.log(projectState)
    let status = projectState.getProject.status
    // SUCCESS RESPONSE
    if (status === true) {
      console.log("projectState() ", projectState.getProject.data)
    setProjectDetail(projectState.getProject.data)
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProject)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      console.log("Validation Error:", projectState.getProject)
    }
  }, [projectState])


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };

  useEffect(() => {
  
    console.log('leadsState', leadsState)
    /******************************* GET LEADS FIELDS ******************************** */
    let Lead_field_status = leadsState.getLeadsFields && leadsState.getLeadsFields.status
    // SUCCESS RESPONSE
    if (Lead_field_status === true) {
      setUploadLeadsTab(false)
      setAssignLeadsTab(false)
      setCreateTable(true)
    }
    // SERVER ERROR RESPONSE
    if (Lead_field_status === 500) {
      console.log("Server Error:", leadsState.getLeadsFields)
      setUploadLeadsTab(true)
      setAssignLeadsTab(true)
      setCreateTable(false)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (Lead_field_status === 401) {
      setMessage("Worng username or password")
      console.log("Validation Error:", leadsState.getLeadsFields)
    }

    /************************* GET LEADS FIELDS ************************************** */

    /************************** CREATE LEADS TABLE ************************************* */
    let status = leadsState.createLeadsTable && leadsState.createLeadsTable.status
    // SUCCESS RESPONSE
    if (status === true) {
      setUploadLeadsTab(false)
      setAssignLeadsTab(false)
      setCreateTable(true)
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      setUploadLeadsTab(true)
      setAssignLeadsTab(true)
      setCreateTable(false)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      setMessage("Worng username or password")
      console.log("Validation Error:", leadsState.createLeadsTable)
    }

    /*****************************  CREATE LEADS TABLE  ********************************** */


  }, [leadsState])


  return (
    <>
      <React.Fragment>
       <Card>
        <Grid container xs={12} style={{ margin: '10px',paddingTop:'10px' }}>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
              Project Number 
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp; {projectDetail.project_number}
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
              Project Name 
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp; {projectDetail.project_name}
            </div>
          </Grid>
          
        </Grid>
        <Grid container xs={12} style={{ margin: '10px',paddingTop:'10px' }}>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            Brand Name 
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp; {projectDetail.brand_name}
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
              Location 
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp;{projectDetail.location}
            </div>
          </Grid>
          
        </Grid>
        <Grid container xs={12} style={{ margin: '10px',paddingTop:'10px' }}>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            Working Time
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp; {projectDetail.working_timings}
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
              Project Status 
            </div>
          </Grid>
          <Grid xs={3}>
            <div style={{ color: '#888888' }}>
            : &nbsp;{projectDetail.project_status}
            </div>
          </Grid>
          
        </Grid><br />
       </Card>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
})
export default withRouter(BookingTable)
