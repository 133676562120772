import {
  UPDATE_SPREADSHEET,
  UPDATE_SPREADSHEET_ERR,
} from "../../actionTypes/projectType";
import { updateSpredsheet } from "api/Api";
import axios from "axios";
import { accessToken } from "api/Token";
export const updateSpreadSheet = () => async (dispatch) => {
  try {
    let results = await axios.get(
      `${updateSpredsheet}`,

      await accessToken()
    );
    let data = results && results.data;

    dispatch({
      type: UPDATE_SPREADSHEET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SPREADSHEET_ERR,
      payload: error.response,
    });
  }
};
