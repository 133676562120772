import "date-fns";

import { CSVLink, CSVDownload } from "react-csv";
import { DropzoneArea } from "material-ui-dropzone";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./UploadLeadStyle";
import SuccessNotification from "../../popUpNotification/SuccessNotification";
import FailureNotification from "../../popUpNotification/FailureNotification";
import { GlobalContext } from "../../../context/GlobalState";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import { uploadLead } from "../../../context/actions/leadsAction/uploadLead";
import { getLeadsFields } from "../../../context/actions/leadsAction/getLeadsFields";
toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [defaultCSV, setDefaultCSV] = useState([]);
  const [csvFile, setCSVFile] = useState([]);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [leadsFields, setLeadsFields] = useState([]);
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();

  const [uploadLeadsArr, setUploadLeadsArr] = useState([]);

  useEffect(() => {
    getLeadsFields(projectId)(leadsDispatch);
  }, []);

  useEffect(() => {
    setLoader(false);
    /******************************* GET LEADS FIELDS ******************************** */
    let Lead_field_status =
      leadsState.getLeadsFields && leadsState.getLeadsFields.status;
    // SUCCESS RESPONSE
    if (Lead_field_status === true) {
      console.log("leadsFields", leadsState.getLeadsFields.data.table_fields);
      setLeadsFields(leadsState.getLeadsFields.data.table_fields);
      let Array = [];
      leadsState.getLeadsFields.data.table_fields.map((item) => {
        if (
          item.COLUMN_NAME != "created_on" &&
          item.COLUMN_NAME != "updated_on" &&
          item.COLUMN_NAME != "project_id"
        ) {
          Array.push(item.COLUMN_NAME);
        }
      });

      let finalArr = [Array];
      setDefaultCSV(finalArr);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER ERROR RESPONSE
    if (Lead_field_status === 500) {
      console.log("Server Error:", leadsState.getLeadsFields);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (Lead_field_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.getLeadsFields);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }

    /************************* GET LEADS FIELDS ************************************** */

    /*****************************  UPLOAD LEADS ********************************** */
    let upload_lead_status =
      leadsState.uploadLead && leadsState.uploadLead.status;
    // SUCCESS RESPONSE
    if (upload_lead_status === true) {
      setSuccessMessage("Lead  Uploaded successfully");
      handleNotificationClickOpen();
      setLoader(false);
      setUploadLeadsTab(false);
      setAssignLeadsTab(false);
      setCreateTable(true);
      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (upload_lead_status === 500) {
      console.log("Server Error:", leadsState.uploadLead);
      setFailureMessage("Lead Already Uploaded");
      handleFailureNotificationClickOpen();
      setLoader(false);
      setUploadLeadsTab(true);
      setAssignLeadsTab(true);
      setCreateTable(false);
      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (upload_lead_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.uploadLead);
      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
    }

    /*****************************  UPLOAD LEADS ********************************** */
  }, [leadsState]);

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };

  const uploadHandle = (e) => {
    setCSVFile(e[0]);
    // if (files) {
    //   setMsg("")
    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     const csv = event.target.result;
    //     let head = csvToArray(csv);
    //     let data = csvToArray(csv);
    //     let headerCSV = head.shift()
    //      let defaultHeader = ["patient_name", "patient_phone_one", "patient_phone_two", "patient_email", "patient_dob", "patient_gender", "patient_address", "patient_city", "patient_pincode", "patient_state", "patient_test_name", "patient_test_description", "patient_description", "patient_incharge_name", "patient_incharge_phone", "patient_incharge_email", "patient_incharge_designation", "booking_date"]
    //     let headers = headerCSV.map((name) => name.toLowerCase().replace(/\s/g, "_"))
    //     console.log(defaultCSV)
    //     console.log('headers',headers)
    //     if (JSON.stringify(defaultCSV) === JSON.stringify(headers)) {
    //       let records = data.slice(1)
    //       let result = []
    //       let len = records.length
    //       for (let i = 0; i < len; i++) {
    //         const obj = {};
    //         const bits = records[i]
    //         headers.forEach((h, i) => (obj[h] = bits[i]));
    //         if (obj.patient_name !== "") {
    //           result.push(obj);
    //         }
    //       }
    //       setUploadLeadsArr(result)
    //     } else {
    //       setMsg("Invalid data format, please download the sample .csv file. Note: Do not change the headers name.")
    //     }
    //   };
    //   reader.readAsText(files);
    // }
  };

  function csvToArray(text) {
    let p = "",
      row = [""],
      ret = [row],
      i = 0,
      r = 0,
      s = !0,
      l;
    for (l of text) {
      if ('"' === l) {
        if (s && l === p) row[i] += l;
        s = !s;
      } else if ("," === l && s) l = row[++i] = "";
      else if ("\n" === l && s) {
        if ("\r" === p) row[i] = row[i].slice(0, -1);
        row = ret[++r] = [(l = "")];
        i = 0;
      } else row[i] += l;
      p = l;
    }
    return ret;
  }

  const handleSubmit2 = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("lead_data", csvFile);
    formData.append("project_id", projectId);
    // let postData={
    //   project_id:projectId,
    //   lead_data:csvFile
    // }

    console.log(formData);
    setTimeout(() => {
      uploadLead(formData)(leadsDispatch);
    }, 500);
  };

  const downloadExcel = () => {
    const data = defaultCSV;
    //console.log('newData', data)
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download

    XLSX.writeFile(workBook, "sample-lead-fields.csv");
  };

  return (
    <>
      <React.Fragment>
        <div>
          <Typography>
            <Card variant="outlined">
              <CardContent className={classes.custInput}>
                <Grid xs={12} container alignItems="center">
                  <Grid item container justify="flex-start" sm={6}>
                    <h2>
                      <b>Upload Lead</b>
                    </h2>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    container
                    justify="flex-end"
                    sm={6}
                  >
                    {/* <CSVLink
                          filename={"sample-booking-data.csv"}
                          data={defaultCSV && defaultCSV}
                          style={{ textDecoration: 'none' }}
                        > */}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={downloadExcel}
                      className={classes.button}
                      endIcon={<GetAppIcon />}
                      style={{ backgroundColor: "#000000" }}
                    >
                      Download sample .csv file
                    </Button>
                    {/* </CSVLink> */}
                  </Grid>
                </Grid>
                <Grid item container justify="flex-start" sm={12}>
                  <DropzoneArea
                    showFileNames
                    onChange={(e) => uploadHandle(e)}
                    filesLimit={1}
                    showAlerts={false}
                    // showPreviews={false}
                    acceptedFiles={[
                      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                    ]}
                  />
                </Grid>
                <Grid xs={12} container alignItems="center">
                  <Grid
                    item
                    direction="row"
                    container
                    justify="center"
                    style={{ marginTop: 10 }}
                    sm={12}
                  >
                    <Button
                      className={classes.saveBtn}
                      type="submit"
                      onClick={handleSubmit2}
                      size="large"
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: "#000000" }}
                    >
                      {loader ? (
                        <CircularProgress style={{ color: "#fff" }} size={28} />
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    container
                    justify="center"
                    className={classes.errorMsg}
                    sm={12}
                  >
                    {msg}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Typography>
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
});
export default withRouter(BookingTable);
