import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./VoiceTestStyle";
import VoiceTest from "../../../components/voice/voiceTest";
const VoicesTest = React.memo((props) => {
  return (
    <>
    
     <VoiceTest/>
    </>
  );
});

export default withRouter(VoicesTest);
