import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AddVoiceTestStyle";
import AddVoiceTest from "../../../components/voice/addVoiceTest";
const AddVoice = React.memo((props) => {
  return (
    <>
     <AddVoiceTest />
    </>
  );
});

export default withRouter(AddVoice);
