import { UPDATE_ONBOARD_STATUS, UPDATE_ONBOARD_STATUS_ERR } from 'context/actionTypes/taskerType';
import { updateOnboardingStatusApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'



export const updateOnboardingStatus = payload => async dispatch => {
    try {
        let results = await axios.patch(`${updateOnboardingStatusApi}`, payload, await accessToken())

        let data = results && results.data
        dispatch({
            type: UPDATE_ONBOARD_STATUS,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ONBOARD_STATUS_ERR,
            payload: error.response
        });
    }
}