import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
export default function Select(props) {
    const { name, label, value, error = null, onChange, options } = props;
    console.log('Options', options.user_id)
    //const default_val = options[0].user_id
    return (
        
        <FormControl style={{ minWidth: '100%' }}
            {...(error && { error: true })}>
            <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                onChange={onChange} 
                //defaultValue={default_val}
                >


                {
                    options.map(
                        item => (<MenuItem key={item.user_id} value={item.user_id}>{item.full_name}</MenuItem>)
                    )
                }
            </MuiSelect>
            {/* {error && <FormHelperText>{error}</FormHelperText>} */}
        </FormControl>

    )
}
