import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { styles } from "./VoiceTestStyle";
import PlusIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import '../../../App.css'
import { Avatar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from '@material-ui/core';
import { Input } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { localRoute } from 'routes/localRoutes';
const useStyles = makeStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: '#B8B8B8',
  },
  selected: {
    color: '#186DA6',
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    color: '#888888',
    background: '#F2F2F2'
  },
  body: {
    fontSize: 14,
    //border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: 'white',

    },
  },
}))(TableRow);



const columns = [
  { id: 'VoiceID', label: 'Voice ID', minWidth: 100 },
  {
    id: 'Tasker ',
    label: 'Tasker ',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'DateTime',
    label: 'Date & Time',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Project',
    label: 'Project',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Voicesample',
    label: 'Voice sample',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 40,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(UserID, Name, Mobile, Location, Email, status, action) {
  return { UserID, Name, Mobile, Location, Email, status, action };
}


const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [myBookings, setMyBookings] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showCancel, setShowCancel] = React.useState(false);
  const [bookingItem, setBookingItem] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAssignClickOpen = (item) => {
    setShow(true);
    setBookingItem(item)
  };
  const handleAssignClose = () => {
    setShow(false);
  };

  const handleCancelAssignClose = () => {
    setShowCancel(false);
  };

  const rows_active = [
    createData('100', '100 - Jacob', '6.7.2021 - 10:30am', 'Jio Mart - 100', 'Jacob@gmail.com', 'Completed', 'action'),
    createData('100', '100 - Jacob', '6.7.2021 - 10:30am', 'Jio Mart - 100', 'Jacob@gmail.com', 'Completed', 'action'),
  ];


  const ITEM_HEIGHT = 48;


  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
        <Grid xs={12}
          container
          alignItems="center"
          style={{ padding: '10px', paddingBottom: '0px' }}
        >
          <Grid item container justify="flex-start" sm={6}>
            <h2 className={classes.pageTitle} style={{color:'black'}}><b>Voice Test</b></h2>
          </Grid>
          <Grid item direction="row" container justify="flex-end" sm={6}>
            <Button
              variant="contained"
              style={{ textTransform: 'none' }}
              size="medium"
              className="button"
              endIcon={<PlusIcon />}
              onClick={event =>  window.location.href='/add-voice'}
            >
              Add voice test
            </Button>
          </Grid>
        </Grid>
        <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div>
        <div className={classes.root}>
        <Grid item container xs={12}>
            <Grid xs={7}>
              
            </Grid>
            <Grid xs={2}>

            </Grid>
            <Grid xs={3}>
              <TextField
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

          </Grid>
          
            <Grid item xs={12} >
              <TableContainer >
                <Table className={classes.table} aria-label="customized table" >
                  <TableHead>
                    <TableRow className={classes.custRow} >
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows_active.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          <StyledTableCell component="th" scope="row">
                           <Link style={{ textDecoration: "none", color: '#0047FF' }}> {/* to={`${localRoute.leadsDetail}/${row && row.UserID && row.UserID.UserID}`} style={{ textDecoration: "none", color: '#0047FF' }} */}
                              {row.UserID}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar /></StyledTableCell>
                            <StyledTableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.Name}</label></StyledTableCell>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Mobile}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Location}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.Email}</StyledTableCell>

                          <StyledTableCell align="center" style={{ width: '15%', color: '#000000', }}><label style={{ backgroundColor: '#00E0AA', padding: '5px', borderRadius: '10px', fontSize: '10px' }}>{row.status}</label></StyledTableCell>
                          <StyledTableCell align="right" style={{ width: '15%' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={menuopen}
                              onClose={handleClose1}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >

                              <MenuItem onClick={handleClose1} style={{ fontSize: '12px' }}>Verify tasker</MenuItem>

                            </Menu>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows_active.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
        </div>
      </div>
    </>
  );
})
export default withRouter(BookingTable)
