import React, { useState } from "react";
import QrScanner from 'qr-scanner';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CircularProgress from '@mui/material/CircularProgress';
import { CSVLink } from "react-csv";
import axios from "axios";
import XLSX from "xlsx";
import { padding } from "@mui/system";
import { accessToken } from "api/Token";
import { createUpiApi } from "api/Api";
import { useEffect } from "react";
import { setMinutes } from "date-fns";

const Upi = (props) => {
  const [urlData, setUrlData] = useState([]);
  const [upiData, setUpiData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState("")
  const [counter, setCounter] = useState(0);
  const [hide, setHide] = useState(false);
  const [status, setStatus] = useState(false)
  const [allResult, setAllResult] = useState([])
  const [array, setArray] = useState([]);
  const [mapOneData, setMapOneData] = useState([]);
  const [mapTwoData, setMapTwoData] = useState([]);
  let limit = props.location.pathname && props.location.pathname.split("/").pop();

  const callUpi = () => {

    if (urlData.length <= 100000) {
      setHide(true);
      setLoader(true)
      setTimeout(() => {
        getUpi();
      }, 2000)
    } else {
      alert("File rows shuold be less than 25000");
      setUrlData([])
    }

  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getUpi = async () => {
    let count = 1
    for (const item of urlData) {
      countRows(count++);
      await processUpi(item)
    }
    setStatus(true)
    setLoader(false)
  }

  const processUpi = async (item) => {
    let Promises = []

    // urlData && urlData.map((item, index) => {
    let key = item && item[0];
    let url = `https://qrscancodes.s3.ap-south-1.amazonaws.com/${item && item[0]}/QRcode.jpg`
    Promises.push(new Promise(async (resolve, reject) => {
      // setTimeout(async ()=>{ 
      try {

        if ((item && item[2] == '-' || item && item[2] == '') && item && item[2] != 'No QR code found') {
          const response = await fetch(url);
          const blob = await response.blob();
          let base64 = await blobToBase64(blob)
          let res = await QrScanner.scanImage(base64, { returnDetailedScanResult: true })
          if (res) {
            let result = res.data
            let dd = result && result.split('pa=') || []
            let cc = dd && dd[1].split('&')
            let ret = cc && cc[0] || '-'
            resolve({ lead_id: key, image_url: url, upi_id: ret })
          } else {
            resolve({ lead_id: key, image_url: url, upi_id: '-' })
          }
        } else {
          resolve({ lead_id: key, image_url: item && item[1], upi_id: item && item[2] })
        }


      } catch (error) {
        let errorRes
        if (error == 'Scanner error: No QR code found') {
          errorRes = 'No QR code found'
        } else {
          errorRes = '-'
        }
        resolve({ lead_id: key, image_url: url, upi_id: errorRes })
      }
      // },
      // 1000)
    }))


    // })
    const result = await Promise.all(Promises)



    if (result.length > 0) {

      // if (result[0].upi_id != 'No QR code found') {
      //   try {
      //     let postData = JSON.stringify({
      //       vpa: result[0].upi_id
      //     });
      //     var config = {
      //       method: 'post',
      //       url: 'https://api.attestr.com/api/v1/public/finanx/vpa',
      //       headers: {
      //         'Authorization': 'Basic T1gwcTNQUWpjaVRBYlluUnQyLmYxYjQyYjg3NzZlNmNiNjMzMTM1OWE5ZmJmMzM5NTRhOjI2NmY5ZTJlNGI4ZTBjOTkxYTZjOGY5Y2E5ZTQ5MzY4NGNlNmQ4N2IwYWU4Nzk0Nw==',
      //         'Content-Type': 'application/json',
      //       },
      //       data: postData
      //     };
      //     let res = await axios(config)
      //     let UPI = res.data

      //     if (UPI.valid == true) {
      //       result[0].merchant_name = UPI.name || '';
      //       result[0].upi_status = 'valid';
      //     } else {
      //       result[0].merchant_name = ''
      //       result[0].upi_status = 'invalid';
      //     }

      //     await axios.post(`${createUpiApi}`, result[0]);

      //   } catch (error) {
      //     console.log("result[0]*** Eorr", error)
      //   }

      // } else {
      //   console.log("result[0]*** UPI not found")
      //   await axios.post(`${createUpiApi}`, result[0]);
      // }

      await axios.post(`${createUpiApi}`, result[0]);


      // setArray(oldArray => [...oldArray, ...result]);

    }

  }

  // console.log("array", array)



  const Upload = () => {
    var fileUpload = document.getElementById("fileUpload");
    if (fileUpload.value.toLowerCase()) {
      if (typeof (FileReader) != "undefined") {
        var reader = new FileReader();
        reader.onload = function (e) {
          let data = csvToArray(e.target.result)
          setUrlData(data)
        }
        reader.readAsText(fileUpload.files[0]);
      }
    } else {
      alert("Please upload a valid CSV file.");
    }
  }
  function csvToArray(str, delimiter = ",") {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const row = str && str.slice(str.indexOf("\n") + 1).split("\n");
    let arrData = []
    row && row.map(item => {
      let rows = item && item.trim().split(',')
      arrData.push(rows)
    })
    return arrData;
  }
  const downloadFile = () => {
    setHide(false)
    const headers = [
      "Lead ID",
      "Image URL",
      "UPI ID"
    ]
    const records = [];
    array.map(item => {
      const innerData = [];
      innerData.push(item.lead_id);
      innerData.push(item.image_url);
      innerData.push(item.upi_id);
      records.push(innerData)
    });
    const data = [headers, ...records];
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "upi-data.xlsx");
  }

  const handleChange = (e) => {
    if (e.target.files.length > 1) {
      setSelectedFile(`${e.target.files.length} Files`)
    } else {
      setSelectedFile(e.target.files[0].name)
    }
  }

  let countRow = urlData.filter(item => item[2] == '-')

  const countRows = (index) => {
    setCounter(index)
  }

  let headers = [
    { label: "Lead ID", key: "lead_id" },
    { label: "Image Url", key: "image_url" },
    { label: "UPI ID", key: "upi_id" }
  ];


  useEffect(async () => {
    // let result = await axios.get(`https://api.amazon.tele.dev.task-mo.com/api/get-upi-stats`);
    let result = await axios.get(`https://api.amazon.tele.dev.task-mo.com/api/get-upi-stats/${limit}`);
    let output = result.data;
    if (output.status = true) {
      let arrData = output && output.data
      if (arrData.length > 0) {
        setMapOneData(arrData)
      }
    }
  }, [limit])



  const processUpiData = () => {
    if (mapOneData.length > 0) {
      console.log("Total Remaining", mapOneData.length)
      let count = 0
      setInterval(async () => {
        let result = await axios.get(`https://api.amazon.tele.dev.task-mo.com/api/get-upi/${mapOneData[count++]}`);
        let output = result.data
        let check = output && output.data && output.data.upi_data_id
        if (check != undefined) {
          try {
            let url = output.data.image_url
            const response = await fetch(url);
            const blob = await response.blob();
            let base64 = await blobToBase64(blob)
            let res = await QrScanner.scanImage(base64, { returnDetailedScanResult: true })
            if (res) {
              let result = res.data
              let dd = result && result.split('pa=') || []
              let cc = dd && dd[1].split('&')
              let ret = cc && cc[0] || '-'
              let patchData = {
                upi_id: ret,
                upi_data_id: output.data.upi_data_id
              }
              console.log("SUCCESS QR", patchData)
              await axios.patch(`https://api.amazon.tele.dev.task-mo.com/api/update-upi`, patchData)
            }

          } catch (error) {
            let errorRes
            if (error == 'Scanner error: No QR code found') {
              errorRes = 'No QR code found'
            } else {
              errorRes = '-'
            }
            let patchData = {
              upi_id: errorRes,
              upi_data_id: output.data.upi_data_id
            }
            console.log("No QR", patchData)
            await axios.patch(`https://api.amazon.tele.dev.task-mo.com/api/update-upi`, patchData)
          }
        }
      }, 100)
    }
  }
  processUpiData()

  const refresh = () => {
    setInterval(() => {
      window.location.reload();
    }, 60000)
  }
  refresh();



  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", minHeight: "100vh", background: "#F0F0F0" }} >
      <div>
        <Card style={{ minWidth: "500px", textAlign: "center", marginTop: "30px" }} >
          <CardContent style={{ padding: 0, paddingBottom: "16px" }} >
            <h1 style={{ background: "#673695", color: "#fff", marginTop: 0, paddingBottom: "15px", paddingTop: "15px" }} >
              Upload CSV
            </h1>
            <div style={{ width: "90%", margin: "auto" }} >
              <div>
                <input id="fileUpload" type="file" multiple hidden onChange={(e) => handleChange(e)}
                  accept={[
                    ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                  ]} />
                <label for="fileUpload" style={{ cursor: "pointer", display: "flex", justifyContent: "center", }} >
                  <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", border: "1.5px dashed #673695", padding: "5px 60px", borderRadius: "2px", color: "#673695", maxWidth: "max-content" }} >
                    <DriveFolderUploadIcon style={{ fontSize: "50px" }} />
                    <strong>Choose a file</strong>
                  </div>
                </label>
              </div>
              <br />
              {selectedFile}
              <div style={{ justifyContent: "center", marginTop: "20px" }} >
                {urlData.length == 0 && array.length == 0 ?
                  <Button id="upload" size="large" onClick={() => Upload()} variant="contained" style={{ textTransform: "none", background: "#673695", color: "#fff" }}  >Upload</Button> : ''
                }
                {!hide &&
                  (urlData.length > 0 && loader == false && upiData.length == 0 ?
                    <Button id="upload" size="large" onClick={() => callUpi()} variant="contained" style={{ textTransform: "none", background: "#673695", color: "#fff" }}>{`${Number(countRow.length)} Rows! Would like to proceed ?`}</Button> : '')
                }

                {loader && !status && (
                  <div style={{ width: "100%" }}>
                    <LinearProgress />
                    <div style={{ marginTop: "10px" }} >
                      {/* <h1 style={{ color: '#673695' }}>{counter}</h1> */}
                      <p> Please wait. It may take few minutes! </p>
                      <p style={{ fontWeight: 600, color: '#673695', fontSize: 18, letterSpacing: 0.5 }}>Do not refresh page OR go back </p>
                    </div>
                  </div>)}
                {
                  urlData.length == 0 ? '' : <h1 style={{ color: '#673695' }}>{counter}</h1>
                }

                {status ?
                  <>
                    {/* <p style={{ fontWeight: 600, color: '#00aa00', fontSize: 20, letterSpacing: 1 }}>File is ready!</p>
                    <Button id="upload" size="large" onClick={() => downloadFile()} variant="contained" style={{ textTransform: "none", background: "#00aa00", color: "#fff" }}>Download</Button> */}

                    {/* <CSVLink data={upiData} headers={headers} filename={"upi-data.csv"} style={{ textDecoration: 'none' }}>
                      <Button style={{ textTransform: "none", background: "#00aa00", color: "#fff", padding: '8px 40px', textDecoration: 'none' }}> Download </Button>
                    </CSVLink> */}


                    <Button id="upload" size="large" variant="contained" style={{ textTransform: "none", background: "#00aa00", color: "#fff", cursor: 'none' }}> UPI Data Created Successfully</Button>
                  </>
                  : ''

                }
              </div>




              {/* <div style={{ textAlign: "center" }} >
                {
                  upiData.map((item, i) => (
                    <>
                      <div key={i}>{item}</div>
                    </>
                  ))
                }
              </div> */}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Upi