import { GET_TASKER, GET_TASKER_ERR } from '../../actionTypes/taskerType'
import { getUserApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getTasker= (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getUserApi}/${payload}`, await accessToken())
        console.log(results)
        let data = results && results.data
        console.log('GET PROJECT', data)
        dispatch({
            type: GET_TASKER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TASKER_ERR,
            payload: error.response,
        });
    }
};


