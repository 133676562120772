import {
  LOGIN_USER,
  LOGIN_USER_ERR,
  USER_IMAGE_UPLOAD,
  USER_IMAGE_UPLOAD_ERR,
} from "../../actionTypes/userType";
export default (state, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loginUser: { data: action.payload },
      };

    case LOGIN_USER_ERR:
      return {
        ...state,
        loginUser: { data: action.payload },
      };

    case "REMOVE_USER":
      let removedUser = state.users.data.filter((user) => {
        return user.id !== action.payload;
      });
      return {
        ...state,
        users: { data: removedUser },
      };
    case "ADD_USER":
      let data = [action.payload, ...state.users.data];
      return {
        ...state,
        users: { data },
      };
    case "EDIT_USER":
      const updateUser = action.payload;

      const updateUsers = state.users.data.map((user) => {
        if (user.id === updateUser.id) {
          return updateUser;
        }
        return user;
      });
      return {
        ...state,
        users: { data: updateUsers },
      };

    case USER_IMAGE_UPLOAD:
      return {
        ...state,
        userImageUpload: action.payload,
        ...state.data,
      };

    case USER_IMAGE_UPLOAD_ERR:
      return {
        ...state,
        userImageUpload: action.payload,
      };

    default:
      return state;
  }
};
