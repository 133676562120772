import { GET_USER_LEADS, GET_USER_LEADS_ERR } from '../../actionTypes/leadsType'
import { userLeadsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getUserLeads = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${userLeadsApi}/${payload.type}/${payload.search}/${payload.filterStartDate}/${payload.filterEndDate}/${payload.user_id}/${payload.searched}/${payload.page}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_USER_LEADS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_LEADS_ERR,
            payload: error.response,
        });
    }
};


