import "date-fns";
import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./CreateLeadTableStyle";
import SuccessNotification from "../../popUpNotification/SuccessNotification";
import FailureNotification from "../../popUpNotification/FailureNotification";
import { useForm, Form } from "../../useForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import { GlobalContext } from "../../../context/GlobalState";
import { createLeadsTable } from "../../../context/actions/leadsAction/createLeadsTable";
import { getLeadsFields } from "../../../context/actions/leadsAction/getLeadsFields";
toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [leadsFields, setLeadsFields] = useState([]);
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();

  useEffect(() => {
    getLeadsFields(projectId)(leadsDispatch);
  }, []);

  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), key_name: "", type: "", length: "" },
  ]);

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.length) {
          const onlyNums = event.target.value.replace(/[^0-9]/g, "");
          i[event.target.name] = onlyNums;
        } else {
          i[event.target.name] = event.target.value;
        }
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), key_name: "", type: "", length: "" },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let postData = {
      project_id: projectId,
      lead_table: inputFields,
    };
    setTimeout(() => {
      createLeadsTable(postData)(leadsDispatch);
    }, 500);
  };

  useEffect(() => {
    setLoader(false);
    console.log("leadsState", leadsState);
    /******************************* GET LEADS FIELDS ******************************** */
    let Lead_field_status =
      leadsState.getLeadsFields && leadsState.getLeadsFields.status;
    // SUCCESS RESPONSE
    if (Lead_field_status === true) {
      setLeadsFields(leadsState.getLeadsFields.data);

      setUploadLeadsTab(false);
      setAssignLeadsTab(false);
      setCreateTable(true);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER ERROR RESPONSE
    if (Lead_field_status === 500) {
      console.log("Server Error:", leadsState.getLeadsFields);
      setUploadLeadsTab(true);
      setAssignLeadsTab(true);
      setCreateTable(false);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (Lead_field_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.getLeadsFields);
      leadsState.getLeadsFields = {
        loading: false,
        error: null,
        data: null,
      };
    }

    /************************* GET LEADS FIELDS ************************************** */

    /************************** CREATE LEADS TABLE ************************************* */
    let status =
      leadsState.createLeadsTable && leadsState.createLeadsTable.status;
    // SUCCESS RESPONSE
    if (status === true) {
      setSuccessMessage("Lead Table Created successfully");
      handleNotificationClickOpen();
      setLoader(false);
      setLeadsFields(leadsState.createLeadsTable.data);

      setUploadLeadsTab(false);
      setAssignLeadsTab(false);
      setCreateTable(true);
      leadsState.createLeadsTable = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", leadsState.createLeadsTable);
      setFailureMessage("Leads Table Already Created");
      handleFailureNotificationClickOpen();
      setLoader(false);
      setUploadLeadsTab(true);
      setAssignLeadsTab(true);
      setCreateTable(false);
      leadsState.createLeadsTable = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.createLeadsTable);
      leadsState.createLeadsTable = {
        loading: false,
        error: null,
        data: null,
      };
    }

    /*****************************  CREATE LEADS TABLE  ********************************** */
  }, [leadsState]);

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
    leadsState.createLeadsTable = {
      loading: false,
      error: null,
      data: null,
    };
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
    leadsState.createLeadsTable = {
      loading: false,
      error: null,
      data: null,
    };
  };

  return (
    <>
      <React.Fragment>
        <div style={{ textAlign: "center" }}>
          <Card>
            {createTable == false ? (
              <Form onSubmit={handleSubmit}>
                <Grid className={classes.layoutMargin1}>
                  <Grid xs={12}>
                    <Card variant="outlined">
                      <CardContent className={classes.custInput}>
                        <Grid
                          xs={12}
                          container
                          alignItems="center"
                          style={{ paddingBottom: "0px" }}
                        >
                          <Grid item container justify="flex-start" sm={6}>
                            <h2>
                              <b>Create Lead Table</b>
                            </h2>
                          </Grid>
                          <Grid
                            item
                            direction="row"
                            container
                            justify="flex-end"
                            sm={6}
                          >
                            <div>
                              <Button
                                className={classes.saveBtn}
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#125693" }}
                              >
                                {loader ? (
                                  <CircularProgress
                                    style={{ color: "#fff" }}
                                    size={28}
                                  />
                                ) : (
                                  "Create"
                                )}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          className={classes.paddings}
                        >
                          {inputFields.map((inputField, i) => (
                            <Grid item xs={12} key={inputField.id} spacing={2}>
                              <Paper
                                className={classes.paper}
                                elevation={0}
                                className={classes.pointsTable}
                              >
                                <Grid container>
                                  <Grid item xs={4}>
                                    <div className={classes.fonts14}>
                                      Column {i + 1}
                                    </div>
                                    <br />
                                  </Grid>
                                  <Grid item xs={4}></Grid>
                                  <Grid item xs={4}></Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={4}>
                                    <TextField
                                      id="standard-fullname-input"
                                      name="key_name"
                                      label="Key Name"
                                      fullWidth
                                      autoComplete="current-fullname"
                                      value={inputField.key_name}
                                      onChange={(event) =>
                                        handleChangeInput(inputField.id, event)
                                      }
                                      required
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormControl style={{ minWidth: "100%" }}>
                                      <InputLabel id="demo-simple-select-label">
                                        Type
                                      </InputLabel>
                                      <MuiSelect
                                        label="Type"
                                        name="type"
                                        value={inputField.type}
                                        onChange={(event) =>
                                          handleChangeInput(
                                            inputField.id,
                                            event
                                          )
                                        }
                                        required
                                      >
                                        <MenuItem key="varchar" value="varchar">
                                          varchar
                                        </MenuItem>
                                      </MuiSelect>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="standard-fullname-input"
                                      name="length"
                                      label="Length"
                                      fullWidth
                                      autoComplete="current-fullname"
                                      value={inputField.length}
                                      onChange={(event) =>
                                        handleChangeInput(inputField.id, event)
                                      }
                                      required
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    {inputFields.length != 1 && (
                                      <Button
                                        variant="outfilled"
                                        color="primary"
                                        size="small"
                                        style={{
                                          float: "right",
                                          color: "white",
                                          backgroundColor: "#125693",
                                        }}
                                        disabled={inputFields.length === 1}
                                        onClick={() =>
                                          handleRemoveFields(inputField.id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          ))}
                          <Grid item xs={12} onClick={handleAddFields}>
                            <Paper
                              className={classes.paper}
                              elevation={0}
                              className={classes.pointsTable}
                              style={{ backgroundColor: "#F2F2F2" }}
                            >
                              <Grid container style={{ textAlign: "center" }}>
                                <Grid item xs={12}>
                                  <AddCircleIcon
                                    style={{
                                      color: "#B8B8B8",
                                      width: "85px",
                                      height: "85px",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      marginTop: 25,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <div
                                className={classes.fonts}
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                  color: "#888888",
                                }}
                              >
                                {" "}
                                Add more Columns for the leads table{" "}
                              </div>
                            </Paper>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <>
                <div style={{ marginTop: 20 }}>
                  Table Name: {leadsFields.table_name}
                </div>
                <br />
                <Grid container>
                  <Grid item xs={4}>
                    <div className={classes.fonts14}>No</div>
                    <br />
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.fonts14}>Column Name</div>
                    <br />
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.fonts14}>Column Type</div>
                    <br />
                  </Grid>
                </Grid>
                {leadsFields.table_fields &&
                  leadsFields.table_fields.map((item, i) =>
                    item.COLUMN_NAME != "created_on" &&
                    item.COLUMN_NAME != "updated_on" &&
                    item.COLUMN_NAME != "project_id" ? (
                      <Grid container>
                        <Grid item xs={4}>
                          <div>{i + 1}</div>
                          <br />
                        </Grid>
                        <Grid item xs={4}>
                          <div>{item.COLUMN_NAME}</div>
                          <br />
                        </Grid>
                        <Grid item xs={4}>
                          <div>{item.COLUMN_TYPE}</div>
                          <br />
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
              </>
            )}
          </Card>
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
});
export default withRouter(BookingTable);
