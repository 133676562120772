import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./ProjectStyle";
import BookingTable from "../../../components/project/projectTable";
const Booking = React.memo((props) => {
  useEffect(() => {
  }, [])
  return (
    <>
     <BookingTable/>
    </>
  );
});

export default withRouter(Booking);
