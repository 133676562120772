import { GET_DEMO_LEADS, GET_DEMO_LEADS_ERR } from '../../actionTypes/leadsType'
import { getDemoLeadsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getDemoLeads = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getDemoLeadsApi}/${payload}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_DEMO_LEADS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DEMO_LEADS_ERR,
            payload: error.response,
        });
    }
};


