import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./UploadLeadsStyle";
import UploadLead from "../../components/uploadLead";
const Home = React.memo((props) => {
  return (
    <>
     <UploadLead/>
    </>
  );
});

export default withRouter(Home);
