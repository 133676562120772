import { UPLOAD_DEMO_LEAD, UPLOAD_DEMO_LEAD_ERR } from '../../actionTypes/leadsType'
import { uploadDemoLeadApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const uploadDemoLead = (payload) => async(dispatch) => {
    try {
        let results = await axios.post(`${uploadDemoLeadApi}`, payload, await accessToken())
        //  console.log("createBookingApi", payload)
        let data = results && results.data
        dispatch({
            type: UPLOAD_DEMO_LEAD,
            payload: data,
          });
    } catch (error) {
        dispatch({
            type: UPLOAD_DEMO_LEAD_ERR,
            payload: error.response,
        });
    }
};


