import { ASSIGN_PROJECT, ASSIGN_PROJECT_ERR } from '../../actionTypes/projectType'
import { assignProjectApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const assignProject = (payload) => async(dispatch) => {
    try {
        console.log('payload',payload)
        let results = await axios.post(`${assignProjectApi}`, payload, await accessToken())
          console.log("Assigned", results)
        let data = results && results.data
        dispatch({
            type: ASSIGN_PROJECT,
            payload: data,
          });
    } catch (error) {
        dispatch({
            type: ASSIGN_PROJECT_ERR,
            payload: error.response,
        });
    }
};


