import React, { createContext, useReducer } from 'react';
import UserReducer from './reducers/userReducer';
import UsersInitialState from "./intialstates/userState";
import ProjectReducer from './reducers/projectReducer';
import ProjectsInitialState from "./intialstates/projectState";
import TaskerReducer from './reducers/taskerReducer';
import TaskersInitialState from "./intialstates/taskerState";
import LeadsInitialState from "./intialstates/leadsState";
import LeadsReducer from './reducers/leadsReducer';
import MasterReducer from './reducers/masterReducer';
import MasterInitialState from './intialstates/masterInitialState';
// Initial State
const initialState = {
  users: [],
}

// Create Context
export const GlobalContext = createContext(initialState);
// Provider Component
export const GlobalProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(UserReducer, UsersInitialState);
  const [projectState, projectDispatch] = useReducer(ProjectReducer, ProjectsInitialState);
  const [taskerState, taskerDispatch] = useReducer(TaskerReducer, TaskersInitialState);
  const [leadsState, leadsDispatch] = useReducer(LeadsReducer, LeadsInitialState);
  const [masterState, masterDispatch] = useReducer(MasterReducer, MasterInitialState)
  let provides = {
    userState,
    userDispatch,
    projectState,
    projectDispatch,
    taskerState,
    taskerDispatch,
    leadsState,
    leadsDispatch,
    masterState,
    masterDispatch
  }

  return (
    <GlobalContext.Provider value={provides}>
      {children}
    </GlobalContext.Provider>
  )
}