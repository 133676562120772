import "date-fns";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import { CSVLink, CSVDownload } from "react-csv";
import { DropzoneArea } from "material-ui-dropzone";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@mui/material/Typography";
import Pagination from "@material-ui/lab/Pagination";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AssignLeadStyle";
import SearchBar from "material-ui-search-bar";
import SuccessNotification from "../../popUpNotification/SuccessNotification";
import FailureNotification from "../../popUpNotification/FailureNotification";
import { useForm, Form } from "../../useForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../../context/GlobalState";
import { createLeadsTable } from "../../../context/actions/leadsAction/createLeadsTable";
import { getLeadsFields } from "../../../context/actions/leadsAction/getLeadsFields";
import { createLead } from "../../../context/actions/leadsAction/createLead";
import { getLeads } from "../../../context/actions/leadsAction/getLeads";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getProjectUsers } from "../../../context/actions/projectAction/getProjectUsers";
import { assignLead } from "../../../context/actions/leadsAction/assignLead";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import moment from "moment";
toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [paginationCount, setPaginationCount] = React.useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [leadsFields, setLeadsFields] = useState([]);
  const [openFailure, setOpenFailure] = React.useState(false);
  const [searched, setSearched] = useState("");
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [expanded, setExpanded] = React.useState(false);
  const initialFormData = Object.freeze({});
  const [formData, updateFormData] = React.useState(initialFormData);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalNewLeads, setTotalNewLeads] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [leadsAssignTableHeader, setLeadsAssignTableHeader] = useState([]);
  const [leadsAssignTableBody, setLeadsAssignTableBody] = useState([]);
  const [sslectedDate, handleDateChanges] = useState([null, null]);

  useEffect(() => {
    setPage(1);
    if (sslectedDate[0] == null && sslectedDate[1] == null) {
      if (searched == "") {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  }, []);

  const handleSubmit3 = (e) => {
    e.preventDefault();
    setLoader(true);
    let postData = [];
    selected.map((item) => {
      postData.push({
        user_id: userId,
        project_id: projectId,
        lead_id: item,
      });
    });

    setTimeout(() => {
      assignLead(postData)(leadsDispatch);
      setSelected([]);
      setUserId("");
    }, 500);
  };

  const requestSearch = (event) => {
    if (event.key === "Enter") {
      setPage(1);
      setSearched(event.target.value);
      setLeadsAssignTableHeader([]);
      setLeadsAssignTableBody([]);
      if (sslectedDate[0] == null && sslectedDate[1] == null) {
        if (event.target.value == "") {
          getLeads({
            project_id: projectId,
            filterStartDate: "all",
            filterEndDate: "all",
            search: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          getLeads({
            project_id: projectId,
            filterStartDate: "all",
            filterEndDate: "all",
            search: event.target.value,
            page: "1",
          })(leadsDispatch);
        }
      } else {
        if (event.target.value == "") {
          let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

          getLeads({
            project_id: projectId,
            filterStartDate,
            filterEndDate,
            search: "__search__",
            page: "1",
          })(leadsDispatch);
        } else {
          let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
          let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

          getLeads({
            project_id: projectId,
            filterStartDate,
            filterEndDate,
            search: event.target.value,
            page: "1",
          })(leadsDispatch);
        }
      }
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
    let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");
    if (sslectedDate[0] == null && sslectedDate[1] == null) {
      getLeads({
        project_id: projectId,
        filterStartDate: "all",
        filterEndDate: "all",
        search: "__search__",
        page: "1",
      })(leadsDispatch);
    } else {
      getLeads({
        project_id: projectId,
        filterStartDate,
        filterEndDate,
        search: "__search__",
        page: "1",
      })(leadsDispatch);
    }
  };

  useEffect(() => {
    setLoader(false);
    console.log("leadsState", leadsState);

    /************************** ASSIGN LEAD ************************************* */
    let assign_lead_status =
      leadsState.assignLead && leadsState.assignLead.status;
    // SUCCESS RESPONSE

    if (assign_lead_status === true) {
      setSuccessMessage("Lead  Assigned successfully");
      handleNotificationClickOpen();
      setLoader(false);
      leadsState.assignLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (assign_lead_status === 500) {
      console.log("Server Error:", leadsState.assignLead);
      setFailureMessage("Lead Already Assigned");
      handleFailureNotificationClickOpen();
      setLoader(false);
      setUploadLeadsTab(true);
      setAssignLeadsTab(true);
      setCreateTable(false);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (assign_lead_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.assignLead);
    }

    /*****************************  ASSIGN LEAD ********************************** */

    /*****************************  GET LEADS ********************************** */
    let get_leads_status = leadsState.getLeads && leadsState.getLeads.status;
    if (get_leads_status === true) {
      console.log("leadsAssignTableBody", leadsState.getLeads.data.res);
      if (leadsState.getLeads.data.res.length != 0) {
        setLeadsAssignTableHeader(leadsState.getLeads.data.res[0]);
        setLeadsAssignTableBody(leadsState.getLeads.data.res);
        setTotalPage(leadsState.getLeads.data.total_page);
        setTotalLeads(leadsState.getLeads.data.total_leads);
        setTotalNewLeads(leadsState.getLeads.data.total_new_leads);
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
    // SERVER ERROR RESPONSE
    if (get_leads_status === 500) {
      console.log("Server Error:", leadsState.getLeads);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (get_leads_status === 401) {
      console.log("Validation Error:", leadsState.getLeads);
    }
    /*****************************  GET LEADS ********************************** */
  }, [leadsState]);

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    if (sslectedDate[0] == null && sslectedDate[1] == null) {
      if (searched == "") {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
    setOpen(false);
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };

  const handleOnBlur = () => {
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    if (sslectedDate[0] == null && sslectedDate[1] == null) {
      if (searched == "") {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: "__search__",
          page: "1",
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: searched,
          page: "1",
        })(leadsDispatch);
      }
    }
  };

  /*********************************GET TASKERS  ********************************/

  function handleClr(e) {
    e.stopPropagation();
    handleDateChanges([null, null]);
    setPage(1);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
    let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");
    if (searched == "") {
      getLeads({
        project_id: projectId,
        filterStartDate: "all",
        filterEndDate: "all",
        search: "__search__",
        page: "1",
      })(leadsDispatch);
    } else {
      getLeads({
        project_id: projectId,
        filterStartDate: "all",
        filterEndDate: "all",
        search: searched,
        page: "1",
      })(leadsDispatch);
    }
  }

  useEffect(() => {
    getProjectUsers({ project_id: projectId, page: "all" })(projectDispatch);
  }, []);

  useEffect(() => {
    console.log("projectState", projectState);
    let status = projectState.getProjectUsers.status;
    if (status === true) {
      const verifyFiltered = projectState.getProjectUsers.data.filter(
        (project) => project.onboard_status == "completed"
      );
      setUsersData(verifyFiltered);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProjectUsers);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProjectUsers);
    }
  }, [projectState]);

  const defaultProps = {
    options: usersData,
    getOptionLabel: (option) => {
      setUserId(option.user_id);
      // setUserAmount(option.user_amount)
      return `${option.full_name}, ${option.mobile_number}`;
    },
  };

  /*********************************GET TASKERS  ********************************/

  /**********************************ASSIGN LEADS  ****************************/

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" align="center">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {leadsAssignTableHeader.map((headCell) => {
            return headCell.key != "created_on" &&
              headCell.key != "updated_on" ? (
              <TableCell
                className={classes.custRow}
                align="center"
                key={headCell.key}
              >
                {headCell.key}
              </TableCell>
            ) : (
              ""
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          ></Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              {/* <DeleteIcon /> */}
              <Button
                className={classes.saveBtn}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#125693" }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={28} />
                ) : (
                  "Assign"
                )}
              </Button>
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = leadsAssignTableBody.map((n) => n[0].value);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsAssignTableBody.map((n) => n[0].value);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPaginationCount(newPage);
    if (sslectedDate[0] == null && sslectedDate[1] == null) {
      if (searched == "") {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: "__search__",
          page: newPage,
        })(leadsDispatch);
      } else {
        getLeads({
          project_id: projectId,
          filterStartDate: "all",
          filterEndDate: "all",
          search: searched,
          page: newPage,
        })(leadsDispatch);
      }
    } else {
      if (searched == "") {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: "__search__",
          page: newPage,
        })(leadsDispatch);
      } else {
        let filterStartDate = moment(sslectedDate[0]).format("YYYY-MM-DD");
        let filterEndDate = moment(sslectedDate[1]).format("YYYY-MM-DD");

        getLeads({
          project_id: projectId,
          filterStartDate,
          filterEndDate,
          search: searched,
          page: newPage,
        })(leadsDispatch);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event", event);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - leadsAssignTableBody.length)
      : 0;

  const handleDateChange = (event) => {
    //console.log('event', event)
    handleDateChanges(event);
  };

  /**********************************ASSIGN LEADS  ****************************/
  return (
    <>
      <React.Fragment>
        <div className={classes.root}>
          <Typography>
            <Grid container>
              <Grid item xs={6}>
                <Paper
                  className={classes.topCardPaper}
                  style={{
                    background: "#f0f0f0",
                    border: "0.5px solid #ddd",
                    borderRadius: 2,
                  }}
                  elevation={0}
                >
                  <Grid sm={12} justifyContent="center" alignItems="center">
                    <div
                      style={{
                        color: "rgb(35, 106, 151)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LeaderboardIcon
                        style={{ fontSize: 20 }}
                        className="icon-image"
                      />{" "}
                      <span
                        className={classes.papercount1}
                        style={{ color: "rgb(35, 106, 151)" }}
                      >
                        &nbsp; Total Leads{" "}
                      </span>{" "}
                      &nbsp;{" "}
                      <div style={{ fontSize: 14 }}>
                        {" "}
                        <span
                          style={{
                            padding: "0px 4px 0px 4px",
                            borderRadius: 3,
                            color: "rgb(35, 106, 151)",
                          }}
                        >
                          {" "}
                          {totalLeads}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  className={classes.topCardPaper}
                  style={{
                    background: "#f0f0f0",
                    border: "0.5px solid #ddd",
                    borderRadius: 2,
                  }}
                  elevation={0}
                >
                  <Grid
                    container
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item sm={12}>
                      <div
                        style={{
                          display: "flex",
                          color: "rgb(0, 170, 0)",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LeaderboardIcon
                          style={{ fontSize: 20 }}
                          className="icon-image"
                        />{" "}
                        <span
                          className={classes.papercount4}
                          style={{ color: "rgb(0, 170, 0)" }}
                        >
                          &nbsp; New Leads{" "}
                        </span>{" "}
                        &nbsp;{" "}
                        <div style={{ fontSize: 14 }}>
                          {" "}
                          <span
                            style={{
                              padding: "0px 4px 0px 4px",
                              borderRadius: 3,
                              color: "rgb(0, 170, 0)",
                            }}
                          >
                            {totalNewLeads}{" "}
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Form onSubmit={handleSubmit3}>
              <Grid className={classes.layoutMargin1}>
                <Grid xs={12}>
                  <Card variant="outlined">
                    <CardContent className={classes.custInput}>
                      <Grid
                        md={12}
                        container
                        alignItems="center"
                        style={{ paddingBottom: "0px" }}
                        spacing={2}
                      >
                        <Grid item sm={4}>
                          <SearchBar
                            style={{
                              boxShadow: "none",
                              borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                              marginTop: -1,
                              borderRadius: 0,
                            }}
                            value={searched}
                            onKeyPress={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDateRangePicker
                              startText="Start date"
                              endText="End date"
                              disableMaskedInput={true}
                              //open={openPicker}
                              // disableOpenPicker={true}
                              maxDate={new Date()}
                              showDaysOutsideCurrentMonth={true}
                              value={sslectedDate}
                              onChange={handleDateChange}
                              toolbarTitle="Select Date Range"
                              format="DD/MM/YYYY"
                              onClose={handleOnBlur}
                              renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                  <TextField {...startProps} />
                                  <Box sx={{ mx: 2 }}> to </Box>
                                  <TextField
                                    {...endProps}
                                    InputProps={{
                                      endAdornment: (
                                        <>
                                          {sslectedDate[0] != null &&
                                            sslectedDate[1] != null ? (
                                            <>
                                              <IconButton
                                                onClick={(e) => handleClr(e)}
                                                style={{ padding: 0 }}
                                              >
                                                <ClearIcon
                                                  style={{ fontSize: 12 }}
                                                />
                                              </IconButton>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ),
                                    }}
                                  />
                                </React.Fragment>
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            style={{ width: "100%" }}
                            {...defaultProps}
                            id="clear-on-escape"
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Tasker"
                                margin="normal"
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          padding: "0px 20px 0px 20px",
                          marginBottom: 5,
                        }}
                        spacing={3}
                      ></Grid>
                      {/* <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div> */}
                      {leadsAssignTableBody.length > 0 ? (
                        <Grid
                          item
                          xs={12}
                          style={{
                            width: "100%",
                            overflowX: "auto",
                            display: "inline-grid",
                          }}
                        >
                          {selected.length != 0 && (
                            <EnhancedTableToolbar
                              numSelected={selected.length}
                            />
                          )}
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rowsPerPage}
                              />
                              <TableBody>
                                {leadsAssignTableBody.map((row, index) => {
                                  const isItemSelected = isSelected(
                                    row[0].value
                                  );
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      hover
                                      onClick={(event) =>
                                        handleClick(event, row[0].value)
                                      }
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row[0].value}
                                      selected={isItemSelected}
                                    >
                                      <TableCell
                                        padding="checkbox"
                                        style={{ minWidth: 120 }}
                                        align="center"
                                      >
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </TableCell>
                                      {row.map((inside_row, inside_index) => {
                                        return inside_row.key != "created_on" &&
                                          inside_row.key != "updated_on" ? (
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            style={{ minWidth: 120 }}
                                            align="center"
                                          >
                                            {inside_row.value}
                                          </TableCell>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Grid
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: 10 }}
                          >
                            <Box justifyContent="flex-end" display="flex">
                              <Pagination
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                variant="outlined"
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </Grid>
                          {leadsAssignTableBody.length == 0 && (
                            <h2 style={{ textAlign: "center" }}>
                              <br />
                              No Leads Found
                            </h2>
                          )}
                        </Grid>
                      ) : (
                        <h2 style={{ textAlign: "center" }}>
                          <br />
                          No Leads Found
                        </h2>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </Typography>
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
});

export default withRouter(BookingTable);
