import "date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { styles } from "./UploadLeadStyle";
import SuccessNotification from "../popUpNotification/SuccessNotification";
import FailureNotification from "../popUpNotification/FailureNotification";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import { useDebounce } from 'use-debounce';
import { GlobalContext } from "context/GlobalState";
import LinearProgress from '@mui/material/LinearProgress';
import { uploadLead } from "context/actions/leadsAction/uploadLead";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { getLeads } from "context/actions/leadsAction/getLeads";

toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [defaultCSV, setDefaultCSV] = useState([["id", "poi_id", "type", "sub_type", "district", "state", "name", "user_phone", "source", "language", "pincode"]]);
  const [csvFile, setCSVFile] = useState([]);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [openFailure, setOpenFailure] = React.useState(false);
  const [emptyCsvFile, setEmptyCsvFile] = useState(false);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);

  useEffect(() => {
    setLoader(false);
    /*****************************  UPLOAD LEADS ********************************** */
    let upload_lead_status =
      leadsState.uploadLead && leadsState.uploadLead.status;
    // SUCCESS RESPONSE
    if (upload_lead_status === true) {
      setKey(0)
      setSuccessMessage("Lead  Uploaded successfully");
      handleNotificationClickOpen();
      setLoader(false);

      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (upload_lead_status === 500) {
      setKey(0)
      console.log("Server Error:", leadsState.uploadLead);
      setFailureMessage("Lead Already Uploaded");
      handleFailureNotificationClickOpen();
      setLoader(false);
      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (upload_lead_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.uploadLead);
      leadsState.uploadLead = {
        loading: false,
        error: null,
        data: null,
      };
    }

    /*****************************  UPLOAD LEADS ********************************** */
  }, [leadsState]);

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setOpen(false);
    props.handleCloseUploadLead()
    props.setLeadsLoader(true);
    getLeads({
      page: "1",
    })(leadsDispatch);

  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };

  const uploadHandle = (e) => {
    setCSVFile(e[0]);

  };


  const handleSubmit2 = (e) => {
    e.preventDefault();

    console.log('csvFile', csvFile.length)
    if (csvFile === undefined || csvFile.length === 0) {
      setEmptyCsvFile(true);
    } else {
      setEmptyCsvFile(false);
      setLoader(true);
      let formData = new FormData();
      formData.append("lead_data", csvFile);
      console.log("checkkkk", formData);
      setTimeout(() => {
        uploadLead(formData)(leadsDispatch);
      }, 500);
    }

  };

  const downloadExcel = () => {
    const data = defaultCSV;
    //console.log('newData', data)
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download

    XLSX.writeFile(workBook, "sample-lead-fields.csv");
  };



  return (
    <>
      <Dialog
        open={props.openUploadLead}
        onClose={props.handleCloseUploadLead}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent>
          <React.Fragment>
            <div>
              <Typography>
                <Card variant="outlined">
                  <CardContent className={classes.custInput}>
                    <Grid xs={12} container alignItems="center">
                      <Grid item container justify="flex-start" sm={6}>
                        <h2>
                          <b>Upload Lead</b>
                        </h2>
                      </Grid>
                      <Grid
                        item
                        direction="row"
                        container
                        justify="flex-end"
                        sm={6}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={downloadExcel}
                          className={classes.button}
                          endIcon={<GetAppIcon />}
                          style={{ backgroundColor: "#000000" }}
                        >
                          Download sample .csv file
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item container justify="flex-start" sm={12}>
                      <DropzoneArea
                        key={debounceKey}
                        initialFiles={[csvFile]}
                        showFileNames
                        onChange={(e) => uploadHandle(e)}
                        filesLimit={1}
                        showAlerts={true}
                        minSize={0}
                        maxFileSize={524288000000000000000000}
                        onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                        acceptedFiles={[
                          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                        ]}


                      />
                    </Grid>
                    {emptyCsvFile &&
                      <div style={{ textAlign: "center", color: "red", marginTop: 5, fontSize: "12px" }} >
                        Please upload csv file
                      </div>
                    }
                    {loader ? (
                      <LinearProgress style={{ color: "#fff" }} size={28} />)
                      : (
                        <Grid xs={12} container alignItems="center">
                          <Grid
                            item
                            direction="row"
                            container
                            justify="center"
                            style={{ marginTop: 5 }}
                            sm={12}
                          >
                            <Button
                              className={classes.saveBtn}
                              type="submit"
                              onClick={handleSubmit2}
                              size="large"
                              variant="contained"
                              color="primary"
                              style={{ backgroundColor: "#000000" }}
                            >
                              Upload
                            </Button>
                          </Grid>

                          <Grid
                            item
                            direction="row"
                            container
                            justify="center"
                            className={classes.errorMsg}
                            sm={12}
                          >
                            {msg}
                          </Grid>
                        </Grid>
                      )}
                  </CardContent>
                </Card>
              </Typography>
            </div>
            <SuccessNotification
              open={open}
              msg={successMessage}
              handleClose={handleNotificationClose}
            />
            <FailureNotification
              open={openFailure}
              msg={failureMessage}
              handleClose={handleFailureNotificationClose}
            />
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </>
  );
});
export default withRouter(BookingTable);
