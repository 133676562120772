import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import UploadIcon from '@mui/icons-material/Upload';
import { Checkbox } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import Autocomplete, {
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from "@material-ui/core/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import { useDebounce } from 'use-debounce';
import { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import UploadGeoPointDialog from './UploadGeoPointDialog';
import { useEffect } from 'react';
import axios from 'axios';
import { getGeoPointsApi, uploadGeoLeadsApi } from "api/Api";
import { accessToken } from "api/Token";
import CircularProgress from '@material-ui/core/CircularProgress';
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import FailureNotification from "components/popUpNotification/FailureNotification";
import moment from "moment";
import XLSX from "xlsx";

const GeoPoint = () => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [openUploadLead, setOpenOpenUploadLead] = React.useState(false);
    const [key, setKey] = useState(0);
    const [debounceKey] = useDebounce(key, 1000);
    const [csvFile, setCSVFile] = useState([]);
    const [emptyCsvFile, setEmptyCsvFile] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dataLoader, setDataLoader] = useState(false);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);

    const [successMessage, setSuccessMessage] = useState("");
    const [failureMessage, setFailureMessage] = useState("");

    const [open, setOpen] = React.useState(false);
    const [openFailure, setOpenFailure] = React.useState(false);

    const [downloadData, setDownloadData] = useState([]);
    const [emptyDownloadData, setEmptyDownloadData] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const [defaultCSV, setDefaultCSV] = useState([["latitude", "longitude"]]);


    const uploadHandle = (e) => {
        setCSVFile(e[0]);
    };

    const handleNotificationClickOpen = () => {
        setOpen(true);
    };

    const handleNotificationClose = () => {
        setOpen(false);
    };

    const handleFailureNotificationClickOpen = () => {
        setOpenFailure(true);
    };

    const handleFailureNotificationClose = () => {
        setOpenFailure(false);
    };


    async function fetchData() {
        setDataLoader(true);
        const res = await axios.get(`${getGeoPointsApi}`, await accessToken());
        if (res.data.status === true) {
            setDataLoader(false);
            setData(res.data.data);
            var output = [];
            res.data.data.map(item => (
                output.push({ label: `${item.location}, ${item.city}, ${item.pincode}, (radius:${item.radius})`, value: item.geo_points_id })
            ))
            setItems(output);
        }
    }

    useEffect(() => {
        fetchData();
    }, [openUploadLead])


    const handleClickOpenUploadLead = () => {
        setOpenOpenUploadLead(true);
    };

    const handleCloseUploadLead = () => {
        setOpenOpenUploadLead(false);
    };

    const [selectedOptions, setSelectedOptions] = useState([]);
    const allSelected = items.length === selectedOptions.length;
    const handleToggleOption = selectedOptions =>
        setSelectedOptions(selectedOptions);
    const handleClearOptions = () => setSelectedOptions([]);
    const getOptionLabel = option => `${option.label}`;

    const handleSelectAll = isSelected => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };


    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find(option => option.value === "select-all")) {
                handleToggleSelectAll();
                let result = [];
                result = items.filter(el => el.value !== "select-all");
                return result
            } else {
                handleToggleOption && handleToggleOption(selectedOptions);
                return selectedOptions;
            }
        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
        }
    };




    const handleSubmit2 = (e) => {
        e.preventDefault();

        if (Object.keys(csvFile).length > 0) {
            setEmptyCsvFile(false);
            setLoader(true);
            let selectedIDs = selectedOptions.map((item) => item.value)
            let formData = new FormData();
            formData.append("file", csvFile);
            formData.append("geo_points_id", selectedIDs);

            async function uploadGeoLeads() {
                const res = await axios.post(`${uploadGeoLeadsApi}`, formData, await accessToken());
                setSuccessMessage(res?.data?.message)
                if (res?.data?.status == true) {
                    setLoader(false);
                    handleNotificationClickOpen()
                    handleClearOptions()
                    fetchData();
                    setDownloadData(res?.data?.data)
                    if (res?.data?.data.length == 0) {
                        setEmptyDownloadData(true)
                    } else {
                        setEmptyDownloadData(false);
                    }
                    setShowDownload(true);
                } else if (res?.data?.status == 401 || res?.data?.status == 500) {
                    setLoader(false);
                    setFailureMessage("Something Went Wrong");
                    handleFailureNotificationClickOpen()
                }
            }
            uploadGeoLeads();

        } else {
            setEmptyCsvFile(true);
        }

    };


    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value;
    const filter = createFilterOptions();



    const downloadExcel = () => {

        // console.log(downloadData, "checkDownloadData")

        const headers = [];

        downloadData.map(item => headers.push(Object.keys(item)));


        const records = [];

        downloadData.map((item, index) => {
            const innerData = [];
            for (var key in item) {
                innerData.push(item[key]);
            }
            records.push(innerData)
        })

        const data = [headers[0], ...records];

        const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet);
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workBook, "geo-leads-report.xlsx");
    }

    const downloadSample = () => {
        const data = defaultCSV;
        //console.log('newData', data)
        const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Details");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download

        XLSX.writeFile(workBook, "sample-lead-fields.csv");
    }

    return (
        <div>
            <div style={{ width: "50%", margin: "auto" }} >
                <Card style={{ marginTop: "20px", border: "0.5px solid #673695" }} >
                    <CardContent>
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div style={{ textAlign: "center" }} >
                                <h1 style={{ color: "#673695", marginTop: "0px" }} >GEO TOOL</h1>
                            </div>
                            <div>
                                <Button variant="outlined"
                                    size='large'
                                    style={{ color: "#673695", fontWeight: "600" }}
                                    onClick={handleClickOpenUploadLead}
                                >
                                    Manage Geo Points
                                </Button>
                            </div>
                        </div>
                        {!showDownload && (<div>
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }} >
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    size="small"
                                    options={items}
                                    value={selectedOptions}
                                    disableCloseOnSelect
                                    getOptionLabel={getOptionLabel}
                                    getOptionSelected={getOptionSelected}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        return [{ label: "Select all", value: "select-all" }, ...filtered];
                                    }}
                                    onChange={handleChange}
                                    renderOption={optionRenderer}
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Select Geo Point" InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {dataLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }} />
                                    )}
                                />
                            </div>


                            <Grid xs={12} container alignItems="center">
                                <Grid item container justify="flex-start" sm={6}>
                                    <h2>
                                        <b>Upload Lead</b>
                                    </h2>
                                </Grid>
                                <Grid
                                    item
                                    direction="row"
                                    container
                                    justify="flex-end"
                                    sm={6}
                                >
                                    <a href="javascript:void(0)" onClick={downloadSample} style={{ textDecoration: "none" }} > Download sample .csv format</a>
                                </Grid>
                            </Grid>

                            <Grid item container justify="flex-start" sm={12}>
                                <DropzoneArea
                                    key={debounceKey}
                                    initialFiles={[csvFile]}
                                    showFileNames
                                    onChange={(e) => uploadHandle(e)}
                                    filesLimit={1}
                                    showAlerts={true}
                                    minSize={0}
                                    maxFileSize={524288000000000000000000}
                                    onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                                    acceptedFiles={[
                                        ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                                    ]}
                                />
                            </Grid>
                            {emptyCsvFile &&
                                <div style={{ textAlign: "center", color: "red", marginTop: 5, fontSize: "12px" }} >
                                    Please upload csv file
                                </div>
                            }
                            {loader ? (
                                <LinearProgress style={{ color: "#fff" }} size={28} />)
                                : (
                                    <Grid xs={12} container alignItems="center">
                                        <Grid
                                            item
                                            direction="row"
                                            container
                                            justify="center"
                                            style={{ marginTop: 5 }}
                                            sm={12}
                                        >
                                            {(selectedOptions.length != 0 && csvFile.length != 0) &&
                                                <Button
                                                    // className={classes.saveBtn}
                                                    type="submit"
                                                    onClick={handleSubmit2}
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ background: "#673695", color: "#fff" }}
                                                >
                                                    Process
                                                </Button>}
                                        </Grid>
                                    </Grid>
                                )}
                        </div>)}

                        {showDownload &&
                            <div>
                                {emptyDownloadData == false ?
                                    <div style={{ textAlign: "center", color: "#00AA00" }} >
                                        <h2>File is ready</h2>
                                        <h4 style={{ color: "#777" }} >Total processed data : {downloadData.length}</h4>
                                    </div> : <div style={{ textAlign: "center", color: "#ccc" }} >
                                        <h3>No Data Found</h3>
                                    </div>}
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", gap: "20px" }} >

                                    <Button variant="outlined"
                                        size='large'
                                        style={{ color: "#673695", fontWeight: 600 }}
                                        onClick={() => setShowDownload(false)}
                                    >
                                        Reset
                                    </Button>
                                    {emptyDownloadData == false &&
                                        <Button startIcon={<GetAppIcon />} variant="contained"
                                            size='large'
                                            style={{ background: "#673695", color: "white" }}
                                            onClick={downloadExcel}
                                        >
                                            Download
                                        </Button>}
                                </div>
                            </div>}
                    </CardContent>
                </Card>
            </div>
            {openUploadLead &&
                <UploadGeoPointDialog openUploadLead={openUploadLead} handleCloseUploadLead={handleCloseUploadLead} setDataLoader={setDataLoader} setData={setData} setSelectedOptions={setSelectedOptions} />}
            <SuccessNotification
                open={open}
                msg={successMessage}
                handleClose={handleNotificationClose}
            />
            <FailureNotification
                open={openFailure}
                msg={failureMessage}
                handleClose={handleFailureNotificationClose}
            />
        </div >
    )
}

export default GeoPoint