import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./TaskerDetailStyle";
import TaskerDetails from "../../../components/tasker/taskerDetail"
import { useDispatch, useSelector } from "react-redux";

const TaskerDetail = React.memo((props) => {

  useEffect(() => {
  }, [])
  return (
    <>
     <TaskerDetails />
    </>
  );
});

export default withRouter(TaskerDetail);
