import { alpha, makeStyles } from '@material-ui/core/styles';
export const styles = (theme) => ({
  pageTitle: {
    color: "#602994",
    fontWeight:"400",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
   
 
  },
//   container: {
//     maxHeight: 440,
//   },

custRow:{
  border: "none",
  boxShadow: "none"
},

reportlabel:{
  color:'red'
},
paper: {
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#7946A9',
  backgroundColor:'#F1F1F1',
},
papercount:{
  color:'#125693b5',
  fontSize:'35px',
  padding:0,
  marginTop:-2,
},
tables: {
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: '5px 1rem'
},

body:
{
  background:'red',
},

theader:
{
  border: 'none',
  color:'#888888',
  fontSize:'15px'
},
tbodytag:
{
  border: 'none',
 background:'white',
  fontSize:'15px',
  borderSpacing:'5px ',
  bordercollapse:'separate !important'
                
},
tbodyspace:
{
  borderSpacing: '0 15px !important',
  borderCollapse: 'seperate !important',
  background:'white',
},


stepperLabel:{
  fontSize:8,
},
stepperLabelColor:{
  color:'#4db193',
},
stepperTiming:{
  fontSize:8,
}
,
paper: {
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#7946A9',
  backgroundColor:'#F1F1F1'
},
papercount:
{
  fontSize:'24px',
  color:'black'
},
fontbox:
{
  fontSize:'12px',
  color:'black'
},
borders:
{
  border:'1px solid #E5E5E5',
  padding:'10px'
},

online: {
  background: 'green',
  width: '15px !important',
  height: '15px  !important',
  borderRadius: '50%',
  position: "absolute",
  zIndex: '1',
  display: 'block',
   marginLeft: "25px",
  color: 'white',
   padding: '3px', 
  
},
offline: {
  background: 'red',
  width: '15px  !important',
  height: '15px  !important',
  borderRadius: '50%',
  position: "absolute",
  zIndex: '1',
  display: 'block',
  fontSize:'10px',
  color:'white',
  marginLeft: "25px",
  padding: '3px', 
},
imageSize:
{
  '@media (min-width: 1024px) and  (max-width: 1100px)': {
    fontSize:30,
    marginTop:10
},
'@media (min-width: 1101px) and  (max-width: 1200px)': {
  fontSize:35,
  marginTop:10
},
'@media (min-width: 1201px) and  (max-width: 1300px)': {
  fontSize:40,
  marginTop:5
},
'@media (min-width: 1301px)': {
  fontSize:50,

}},
imageSize1:
{
  '@media (min-width: 1024px) and  (max-width: 1300px)': {
    fontSize:'25px !important',
    marginTop:10
},
'@media (min-width: 1301px)': {
  fontSize:'20px !important',

}
 
},
imageSize2:
{
  '@media (min-width: 1024px) and  (max-width: 1300px)': {
    fontSize:'25px !important',
    marginTop:10,
    color:'rgb(3, 102, 3)',
},
'@media (min-width: 1301px)': {
  fontSize:'20px !important',
  color:'rgb(3, 102, 3)',

}
 
},
imageSize3:
{
  '@media (min-width: 1024px) and  (max-width: 1300px)': {
    fontSize:'25px !important',
    marginTop:10,
    color:'rgba(255, 13, 13, 0.6)',
},
'@media (min-width: 1301px)': {
  fontSize:'20px !important',
  color:'rgba(255, 13, 13, 0.6)',

}
 
},
imageSize4:
{
  '@media (min-width: 1024px) and  (max-width: 1300px)': {
    fontSize:'25px !important',
    marginTop:10,
    color:'rgba(39, 13, 255, 0.7)'
},
'@media (min-width: 1301px)': {
  fontSize:'20px !important',
  color:'rgba(39, 13, 255, 0.7)'

}
 
},
imageSize5:
{
  '@media (min-width: 1024px) and  (max-width: 1300px)': {
    fontSize:'25px !important',
    marginTop:10,
    color:'#b10000'
},
'@media (min-width: 1301px)': {
  fontSize:'20px !important',
  color:'#b10000'

},
paper: {
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#7946A9',
  backgroundColor:'#F1F1F1'
},
},
papercount1:
{
  fontSize:'13px',
  color:'#693695',
  // marginTop:-10,
  fontWeight:500
},
papercount2:
{
  fontSize:'13px',
  color:'rgb(3, 102, 3)',
  fontWeight:500
},
papercount3:
{
  fontSize:'13px',
  color:'rgba(255, 13, 13, 0.6)',
  // marginTop:10,
  fontWeight:500
},
papercount4:
{
  fontSize:'13px',
  color:'rgba(39, 13, 255, 0.7)',
  // marginTop:10,
  fontWeight:500
},
papercount5:
{
  fontSize:'13px',
  color:'#b10000',
  // marginTop:10,
  fontWeight:500
},
fontbox:
{
  fontSize:'12px',
  color:'black'
},
borders:
{
  border:'1px solid #E5E5E5',
  padding:'10px'
},
topCardPaper: {
  textAlign: "center",
  color: theme.palette.text.secondary,
  fontSize: "16px",
  width: "97%",
  color: "#333",
  background: "#000",
  padding: '10px 3px',
  fontWeight: 500
  // marginTop: -15,
},

});




