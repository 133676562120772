import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export default function TimePicker(props) {
    const { name, label, value, onChange } = props
    const convertToDefEventPara = (name, value) => ({
    
        target: {
            name, value
        }
        
    })

    return (
        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                      
                            <KeyboardTimePicker
                            style={{marginTop:0}}
                              margin="normal"
                              id="time-picker"
                              label={label}
                              fullWidth
                              
                            name={name}
                            value={value}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              autoComplete="current-fullname"
                              onChange={time =>onChange(convertToDefEventPara(name,time))}
                              keyboardIcon={<ArrowDropDownIcon />}
                            />

                        
                      </MuiPickersUtilsProvider>
    )
}
