import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./ProjectDetailStyle";
import ProjectDetails from "../../../components/project/projectDetail"



const ProjectDetail = React.memo((props) => {
  return (
    <>
     <ProjectDetails />
    </>
  );
});

export default withRouter(ProjectDetail);
