import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./LeadsListStyle";
import LeadsTable from "../../../components/leads/leadsTable";
const LeadTable = React.memo((props) => {
  return (
    <>
    
     <LeadsTable/>
    </>
  );
});

export default withRouter(LeadTable);
