import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./TaskerListStyle";
import TaskerTable from "../../../components/tasker/taskerTable";
const Booking = React.memo((props) => {
  return (
    <>
    
     <TaskerTable/>
    </>
  );
});

export default withRouter(Booking);
