import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import MuiListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import moneyPerson from "assets/images/moneyPerson.svg"
import Logo from 'assets/images/taskmo_logo.svg'
import Navigation from "../../components/navigation";
import { localRoute } from "../../routes/localRoutes";
import { withRouter, useHistory, Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useConfirm } from "material-ui-confirm";
// import { logOut } from "../../context/actions/userAction/logOut";
import { GlobalContext } from "../../context/GlobalState";
import { styles } from "./DrawerStyle";
import CssBaseline from '@material-ui/core/CssBaseline';
// import "./Font.css";
// import Notifications from "../Notifications/Notifications";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
const useStyles = makeStyles(styles);

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#fff",
      margin: '0px 5px 0px 5px',
      borderRadius: '2px',
      border: '0.3px solid #ddd',
      color: "#121E2E !important",
      "& .MuiListItemIcon-root": {
        color: "#121E2E",
        // paddingBottom: 2,
      },
      "&& .makeStyles-drawerMenuIten-19 span":
      {
        fontWeight: 700,
        color: "#121E2E !important",
      }
    },
    "&$selected:hover": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root": {
        color: "#121E2E",
      }
    },
  },
  selected: {}
})(MuiListItem);



const DrawerUI = (props) => {
  // const { history } = props;


  const { userState, userDispatch } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const confirm = useConfirm();

  const [pageTitle, setPageTitle] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  // const { portalUserState, portalUserDispatch } = useContext(GlobalContext);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [loginName, setLoginName] = React.useState('');
  const [badgeCount, setBadgeCount] = useState('0');
  const [open, setOpen] = React.useState(true);
  const [isShown, setIsShown] = React.useState(false);


  const handleListItemClick = (event, index) => {

    setSelectedIndex(index);
    // if (index == 0) {
    //   history.push(localRoute.master)
    // }

    if (index == 0) {
      history.push(localRoute.newLeads)
    }

    if (index == 1) {
      history.push(localRoute.assignLeadTable)
    }

    if (index == 2) {
      history.push(localRoute.taskerList)
    }

    if (index == 3) {
      logout()
    }
    handleResize();
  };


  useEffect(() => {
    let total_url = window.location.pathname.split('/')
    let url = total_url[1];

    // if (url == "master") {
    //   setSelectedIndex(0)
    // }

    if (url == "lead") {
      setSelectedIndex(0)
    }

    if (url == "report") {
      setSelectedIndex(1)
    }

    if (url == "agent") {
      setSelectedIndex(2)
    }

    setLoginName(localStorage.getItem('full_name'))
  }, []);


  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuProfileOpen = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    const admin_id = localStorage.getItem('login_id')
    //console.log('get manage user', admin_id)
    // getPortalUser(admin_id)(portalUserDispatch)
    history.push(`${localRoute.editProfile}`)
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = () => {
    confirm({ description: `Are You sure you want to logout?` })
      .then(() => {
        localStorage.clear();
        // logOut({ loginUser: [] })(userDispatch)
        history.push("/");
      })
      .catch = (() => console.log("Deletion cancelled."));

  };

  let localToken = localStorage.getItem("token");
  useEffect(() => {
    if (!localToken) {
      history.push("/");
      // history.go(0)
    }



  }, [localToken]);


  let pageTitlePath = history.location && history.location.pathname.split('/')
  useEffect(() => {
    let str = pageTitlePath && pageTitlePath[1].replace("-", " ")
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    setPageTitle(splitStr.join(' '))
  }, [pageTitlePath])


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleResize() {

    if (window.innerWidth <= '780') {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  React.useEffect(() => {
    // window.addEventListener('resize', handleResize)
    handleResize();
  }, [window.innerWidth])





  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={badgeCount} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );



  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ border: 'none' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <span style={{ fontSize: 16, fontWeight: 600, letterSpacing: '0.3px' }}>{pageTitle.toLocaleUpperCase()}</span>
          </div>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
            </div>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Typography variant="h7" style={{ marginRight: 8, display: "flex", justifyItems: "center", alignItems: "center" }} noWrap>
              {loginName ? loginName.toLocaleUpperCase() : ''}
            </Typography>
            <Avatar alt="AB UTNAL" onClick={handleProfileMenuOpen} style={{ margin: 0, padding: 0, background: "#673695" }} src="" className={classes.large}>
              {loginName[0]} </Avatar>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        // sytle={{ background: '#000' }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" className={classes.title}  >
            <img src={Logo} className={classes.logoImage} alt="/" />
            <div style={{ color: "#fff", fontSize: "10px", fontWeight: 600, marginTop: "-15px", marginLeft: "55px" }} >Telecalling</div>
          </Typography>

          {isShown &&
            <IconButton onClick={handleDrawerClose} >
              {theme.direction === 'ltr' ? <ChevronLeftIcon
                style={{
                  color: "#fff2bf",
                  border: "2px solid #fff",
                  borderRadius: "50%",
                  float: "right",
                  fontSize: "26px"
                }} /> : <ChevronRightIcon />}
            </IconButton>
          }
        </div>

        <List className={classes.drawerList} style={{ margin: '20px 5px 0px 5px' }}>

          {/* <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button onClick={(event) => handleListItemClick(event, 0)} selected={selectedIndex === 0}>
            <Link style={{ textDecoration: 'none' }}>
              <ListItemIcon className={classes.drawerIcon}>
                <ContactPhoneOutlinedIcon />
                <ListItemText className={classes.drawerMenuIten}>Master</ListItemText>
              </ListItemIcon>
            </Link>
          </ListItem> */}


          <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button onClick={(event) => handleListItemClick(event, 0)} selected={selectedIndex === 0}>
            <Link style={{ textDecoration: 'none' }}>
              <ListItemIcon className={classes.drawerIcon}>
                <InsertChartOutlinedIcon />
                <ListItemText className={classes.drawerMenuIten}>Lead</ListItemText>
              </ListItemIcon>
            </Link>
          </ListItem>

          <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button onClick={(event) => handleListItemClick(event, 1)} selected={selectedIndex === 1}>
            <Link style={{ textDecoration: 'none' }}>
              <ListItemIcon className={classes.drawerIcon}>
                <AssignmentIndOutlinedIcon />
                <ListItemText className={classes.drawerMenuIten}> Report</ListItemText>
              </ListItemIcon>
            </Link>
          </ListItem>

          <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button onClick={(event) => handleListItemClick(event, 2)} selected={selectedIndex === 2}>
            <Link style={{ textDecoration: 'none' }}>
              <ListItemIcon className={classes.drawerIcon}>
                <PersonOutlineOutlinedIcon />
                <ListItemText className={classes.drawerMenuIten}>Agent</ListItemText>
              </ListItemIcon>
            </Link>
          </ListItem>

          <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button onClick={(event) => handleListItemClick(event, 3)} selected={selectedIndex === 3}>
            <Link style={{ textDecoration: 'none' }}>
              <ListItemIcon className={classes.drawerIcon}>
                <ExitToAppOutlinedIcon />
                <ListItemText className={classes.drawerMenuIten}>Logout</ListItemText>
              </ListItemIcon>
            </Link>
          </ListItem>
        </List>

        <br />


        <div style={{
          width: "100%",
          position: 'absolute',
          bottom: 0,
          textAlign: "center",
          fontSize: "12px",
          fontWeight: 600
        }}>
          Powered by TASKMO
        </div>

      </Drawer>
      {renderMobileMenu}
      <div className={classes.container}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Typography paragraph>
            <Navigation />
          </Typography>
        </main>
      </div>
    </div>
  );
};
export default withRouter(DrawerUI);