import "date-fns";
import React, { useEffect, useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@mui/material/Typography";
import Pagination from "@material-ui/lab/Pagination";
import UploadIcon from '@mui/icons-material/Upload';
import { withRouter, useHistory, Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AssignLeadStyle";
import SuccessNotification from "../popUpNotification/SuccessNotification";
import FailureNotification from "../popUpNotification/FailureNotification";
import { useForm, Form } from "../useForm";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "context/GlobalState";
import { getLeads } from "context/actions/leadsAction/getLeads";
import { getProjectUsers } from "context/actions/projectAction/getProjectUsers";
import { assignLead } from "context/actions/leadsAction/assignLead";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import moment from "moment";
import UploadLead from "components/uploadLead";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadAllLeads } from "context/actions/leadsAction/downloadAllLeads";
import XLSX from "xlsx";
toast.configure();
const useStyles = makeStyles(styles);


const columns = [
  {
    id: "leadId",
    label: "Lead Id",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 160,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "language",
    label: "Language",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "id",
    label: "ID",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "poiId",
    label: "Poi Id",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "type",
    label: "Type",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "subtype",
    label: "Subtype",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "district",
    label: "District",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state",
    label: "State",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "shopName",
    label: "Name",
    minWidth: 130,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "source",
    label: "Source",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "pincode",
    label: "Pincode",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Date",
    label: "Date",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];


const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownLoadLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState(0);
  const [error, setError] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [paginationCount, setPaginationCount] = React.useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const { leadsState, leadsDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalNewLeads, setTotalNewLeads] = useState(0);
  const [totalAssignedLeads, setTotalAssignedLeads] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [leadsAssignTableHeader, setLeadsAssignTableHeader] = useState([]);
  const [leadsAssignTableBody, setLeadsAssignTableBody] = useState([]);

  const [openUploadLead, setOpenOpenUploadLead] = React.useState(false);

  const handleClickOpenUploadLead = () => {
    setOpenOpenUploadLead(true);
  };

  const handleCloseUploadLead = () => {
    setOpenOpenUploadLead(false);
    setPage(1);
  };

  useEffect(() => {
    setPage(1);
    setLoader(true);
    getLeads({
      page: "1",
    })(leadsDispatch);
  }, []);

  const excelDownloadAllLeads = () => {
    setDownLoadLoader(true);
    downloadAllLeads({
      page: "1",
    })(leadsDispatch);
  }

  const downloadExcel = (downloadData) => {
    const headers = [];
    for (var key in leadsAssignTableHeader) {
      if (key !== "mid") {
        if (key != "lead_type") {
          headers.push(key);
        }
      }
    }

    const records = [];
    downloadData &&
      downloadData.map((item, index) => {
        const innerData = [];
        for (var key in item) {
          if (key !== "mid") {
            if (key != "lead_type") {
              if (key == "updated_at") {
                innerData.push(moment(item[key]).utc().format("DD-MM-YYYY"));
              } else if (key == "created_at") {
                innerData.push(moment(item[key]).utc().format("DD-MM-YYYY"));
              } else {
                innerData.push(item[key]);
              }
            }
          }
        }

        records.push(innerData)
      })

    const data = [headers, ...records];
    const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "all-leads-report.xlsx");
  }

  useEffect(() => {
    let status = leadsState?.downloadAllLeadsInitState?.status
    if (status === true) {
      setDownLoadLoader(false)
      console.log(leadsState?.downloadAllLeadsInitState?.data?.res[0], "leadsState?.downloadAllLeadsInitState")

      // if (leadsState?.downloadAllLeadsInitState?.data?.res.length != 0) {
      //   setLeadsAssignTableHeader(leadsState?.downloadAllLeadsInitState?.data?.res[0])
      // }

      downloadExcel(leadsState?.downloadAllLeadsInitState?.data?.res);
      leadsState.downloadAllLeadsInitState = {
        loading: false,
        error: null,
        data: null,
      }
    }
  }, [leadsState])

  const handleSubmit3 = (e) => {
    e.preventDefault();
    let postData = [];
    if (userId) {
      setError(false)
      selected.map((item) => {
        postData.push({
          user_id: userId,
          lead_id: item,
        });
      });
      setTimeout(() => {
        assignLead(postData)(leadsDispatch);
        setSelected([]);
        setUserId("");
      }, 500);
    }
    else {
      setError(true)
    }


  };


  useEffect(() => {

    /************************** ASSIGN LEAD ************************************* */
    let assign_lead_status =
      leadsState.assignLead && leadsState.assignLead.status;
    // SUCCESS RESPONSE

    if (assign_lead_status === true) {
      setSuccessMessage("Lead  Assigned successfully");
      handleNotificationClickOpen();
      leadsState.assignLead = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (assign_lead_status === 500) {
      console.log("Server Error:", leadsState.assignLead);
      setFailureMessage("Lead Already Assigned");
      handleFailureNotificationClickOpen();

      setUploadLeadsTab(true);
      setAssignLeadsTab(true);
      setCreateTable(false);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (assign_lead_status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", leadsState.assignLead);
    }

    /*****************************  ASSIGN LEAD ********************************** */

    /*****************************  GET LEADS ********************************** */
    let get_leads_status = leadsState.getLeads && leadsState.getLeads.status;
    if (get_leads_status === true) {
      setLoader(false);
      if (leadsState.getLeads.data.res.length != 0) {
        setLeadsAssignTableHeader(leadsState.getLeads.data.res[0]);
        setLeadsAssignTableBody(leadsState.getLeads.data.res);
        setTotalPage(leadsState.getLeads.data.total_page);
        setTotalLeads(leadsState.getLeads.data.total_leads);
        setTotalNewLeads(leadsState.getLeads.data.total_new_leads);
        setTotalAssignedLeads(leadsState.getLeads.data.total_assigned_leads);
      } else {
        setLeadsAssignTableHeader([]);
        setLeadsAssignTableBody([]);
      }
    }
    // SERVER ERROR RESPONSE
    if (get_leads_status === 500) {
      console.log("Server Error:", leadsState.getLeads);
      setLoader(false);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (get_leads_status === 401) {
      console.log("Validation Error:", leadsState.getLeads);
      setLoader(false);
    }
    /*****************************  GET LEADS ********************************** */
  }, [leadsState]);

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPage(1);
    setLoader(true)
    getLeads({
      page: "1",
    })(leadsDispatch);

    setOpen(false);
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };


  /*********************************GET TASKERS  ********************************/


  useEffect(() => {
    getProjectUsers({ page: "all" })(projectDispatch);
  }, []);

  useEffect(() => {
    console.log("projectState", projectState);
    let status = projectState.getProjectUsers.status;
    if (status === true) {
      // const verifyFiltered = projectState.getProjectUsers.data.filter(
      //   (project) => project.onboard_status == "completed"
      // );
      setUsersData(projectState.getProjectUsers.data);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProjectUsers);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProjectUsers);
    }
  }, [projectState]);

  console.log('usersData', usersData)

  const defaultProps = {
    options: usersData,
    getOptionLabel: (option) => {
      setUserId(option.user_id);
      // setUserAmount(option.user_amount)
      return `${option.full_name}, ${option.mobile_number}`;
    },
  };
  /*********************************GET TASKERS  ********************************/

  /**********************************ASSIGN LEADS  ****************************/

  // const handleInputChange = (event) => {

  //   setUserId(event.target.value)

  // }

  const handleInputChange = (newValue) => {
    console.log("newValue*******", newValue)
    if (newValue != null && newValue != '' && newValue != undefined) {
      setUserId(newValue.user_id)
    } else {
      setUserId(0)
    }
  };

  console.log('userId', userId)
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" align="center" style={{ background: "#F0F0F0" }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              style={{
                minWidth: headCell.minWidth,
                color: "#000",
                background: "#F0F0F0",
                fontWeight: "700",
              }}
            >
              {headCell.label}
            </TableCell>
          ))}

        </TableRow>
      </TableHead >
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar

        sx={{
          pl: { sm: 3 },
          pr: { xs: 2, sm: 1 },
          // ...(numSelected > 0 && {
          //   bgcolor: (theme) =>
          //     alpha(
          //       theme.palette.primary.main,
          //       theme.palette.action.activatedOpacity
          //     ),
          // }),
        }}
        style={{ marginBottom: "10px", marginTop: 10 }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          ></Typography>
        )}

        {numSelected > 0 ? (
          <Grid item xs={12}>

            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Select Agent</InputLabel>
              <Select
                style={{ padding: "5px !important" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userId}
                label="Select Agent"
                onChange={handleInputChange}
                error={error}
              >
                {usersData.map((item) => {
                  return (
                    <MenuItem style={{ padding: "5px !important" }} value={item.user_id}><span style={{ display: 'flex' }}> <Avatar src={item.profile_image} style={{ width: 30, height: 30 }} /> &nbsp;&nbsp;&nbsp;<span style={{ display: "flex", alignItems: "center" }}>{item.full_name}</span></span></MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>) : (
          ""
        )}

        {numSelected > 0 ? (
          <Button
            className={classes.saveBtn}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#125693", marginLeft: "5px", marginTop: "0px", height: "55px" }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={28} />
            ) : (
              "Assign"
            )}
          </Button>
        ) : (
          ""
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsAssignTableBody.map((n) => n.lead_id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
    setLoader(true)
    setLeadsAssignTableHeader([]);
    setLeadsAssignTableBody([]);
    setPaginationCount(newPage);
    getLeads({
      page: newPage,
    })(leadsDispatch);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event", event);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  /**********************************ASSIGN LEADS  ****************************/
  return (
    <>
      <React.Fragment>
        <div className={classes.root} style={{ marginTop: '-15px' }}>
          <Typography>
            <Grid container style={{ marginBottom: 10 }} spacing={2} justifyContent="space-between" >
              <Grid item xs={2}>
                <Paper
                  className={classes.topCardPaper}
                  style={{
                    background: "#f0f0f0",
                    border: "0.5px solid #ddd",
                    borderRadius: 2,
                  }}
                  elevation={0}
                >
                  <Grid sm={12} justifyContent="center" alignItems="center">
                    <div
                      style={{
                        color: "#673695",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LeaderboardIcon
                        style={{ fontSize: 20 }}
                        className="icon-image"
                      />{" "}
                      <span
                        className={classes.papercount1}
                        style={{ color: "#673695" }}
                      >
                        &nbsp; Total{" "}
                      </span>{" "}
                      &nbsp;{" "}
                      <div style={{ fontSize: 14 }}>
                        {" "}
                        <span
                          style={{
                            padding: "0px 4px 0px 4px",
                            borderRadius: 3,
                            color: "#673695",
                          }}
                        >
                          {totalLeads}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={2}>
                <Paper
                  className={classes.topCardPaper}
                  style={{
                    background: "#f0f0f0",
                    border: "0.5px solid #ddd",
                    borderRadius: 2,
                  }}
                  elevation={0}
                >
                  <Grid sm={12} justifyContent="center" alignItems="center">
                    <div
                      style={{
                        color: "#673695",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LeaderboardIcon
                        style={{ fontSize: 20 }}
                        className="icon-image"
                      />{" "}
                      <span
                        className={classes.papercount1}
                        style={{ color: "#673695" }}
                      >
                        &nbsp; New{" "}
                      </span>{" "}
                      &nbsp;{" "}
                      <div style={{ fontSize: 14 }}>
                        {" "}
                        <span
                          style={{
                            padding: "0px 4px 0px 4px",
                            borderRadius: 3,
                            color: "#673695",
                          }}
                        >
                          {totalNewLeads}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={2}>
                <Paper
                  className={classes.topCardPaper}
                  style={{
                    background: "#f0f0f0",
                    border: "0.5px solid #ddd",
                    borderRadius: 2,
                  }}
                  elevation={0}
                >
                  <Grid sm={12} justifyContent="center" alignItems="center">
                    <div
                      style={{
                        color: "#673695",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LeaderboardIcon
                        style={{ fontSize: 20 }}
                        className="icon-image"
                      />{" "}
                      <span
                        className={classes.papercount1}
                        style={{ color: "#673695" }}
                      >
                        &nbsp; Assigned{" "}
                      </span>{" "}
                      &nbsp;{" "}
                      <div style={{ fontSize: 14 }}>
                        {" "}
                        <span
                          style={{
                            padding: "0px 4px 0px 4px",
                            borderRadius: 3,
                            color: "#673695",
                          }}
                        >
                          {" "}
                          {totalAssignedLeads}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={2.5} style={{ display: 'flex' }}>


                <Button startIcon={<GetAppIcon />} variant="contained"
                  fullWidth
                  size='large'
                  style={{ background: "#673695", color: "white" }}
                  onClick={excelDownloadAllLeads}
                >
                  {downloadLoader ? (
                    <CircularProgress style={{ color: "#fff" }} size={28} />
                  ) : (
                    "Download"
                  )}
                </Button>


              </Grid>

              <Grid item xs={2.5} style={{ display: 'flex' }}>


                <Button startIcon={<UploadIcon />} variant="contained"
                  fullWidth
                  size='large'
                  style={{ background: "#673695", color: "white" }}
                  onClick={handleClickOpenUploadLead} >
                  Upload Leads
                </Button>


              </Grid>
            </Grid>


            <Grid className={classes.layoutMargin1}>
              <Grid xs={12}>
                <Card variant="outlined">
                  <CardContent className={classes.custInput}>
                    <Grid
                      container
                      style={{
                        padding: "0px 20px 0px 20px",
                        marginBottom: 5,
                      }}
                      spacing={3}
                    ></Grid>
                    {/* <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div> */}
                    {loader === true && (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    )}
                    {(leadsAssignTableBody.length > 0 && loader === false) && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: "100%",
                          overflowX: "auto",
                          display: "inline-grid",
                        }}
                      >
                        {/* {selected.length != 0 && (
                            <EnhancedTableToolbar
                              numSelected={selected.length}
                            />
                          )} */}

                        {selected.length > 0 ?
                          <Form onSubmit={handleSubmit3}>
                            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                              <div item style={{ display: "flex", alignItems: "center", marginLeft: '10px' }}>
                                {selected.length > 0 ? (
                                  <Typography
                                    sx={{ flex: "1 1 100%" }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                  >
                                    {selected.length} selected
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ flex: "1 1 100%" }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                  ></Typography>
                                )}
                              </div>

                              <div style={{ display: "flex", gap: "10px", }} >
                                <div style={{ minWidth: "300px", marginTop: 10 }} >
                                  <Autocomplete
                                    id="select-tasker"
                                    // sx={{ width: 300 }}
                                    fullWidth
                                    options={usersData}
                                    autoHighlight
                                    getOptionLabel={(option) => option.full_name}
                                    onChange={(e, newValue) => handleInputChange(newValue)}
                                    renderOption={(option) => (
                                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          {option.profile_image ? <Avatar src={option.profile_image} /> : <Avatar style={{ background: '#125693', fontWeight: 600 }}>{option.full_name && option.full_name.charAt(0)}</Avatar>}
                                          <span style={{ marginTop: 10, marginLeft: 10 }}>{option.full_name}</span>
                                        </div>
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Tasker"
                                        variant="standard"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: 'off',
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div>
                                  {selected.length > 0 ? (
                                    <Tooltip title="Re-Assign" style={{ float: "right" }}>
                                      <IconButton>
                                        {/* <DeleteIcon /> */}
                                        <Button
                                          className={classes.saveBtn}
                                          type="submit"
                                          size="large"
                                          variant="contained"
                                          color="primary"
                                          style={{ backgroundColor: "#673695" }}
                                        >
                                          {loader ? (
                                            <CircularProgress style={{ color: "#fff" }} size={28} />
                                          ) : (
                                            "Assign"
                                          )}
                                        </Button>
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                          : ''}


                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <EnhancedTableHead
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={leadsAssignTableBody.length}
                            />
                            <TableBody>
                              {leadsAssignTableBody.map((row, index) => {
                                const isItemSelected = isSelected(
                                  row.lead_id
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                  <TableRow
                                    hover

                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.lead_id}
                                    selected={isItemSelected}
                                  >
                                    <TableCell
                                      padding="checkbox"
                                      style={{ minWidth: 120 }}
                                      align="center"
                                      onClick={(event) =>
                                        handleClick(event, row.lead_id)
                                      }
                                    >
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          minWidth: "80px",
                                          maxWidth: "80px",
                                          background: "#673695",
                                          color: "#fff",
                                          fontWeight: 600,
                                          borderRadius: "2px",
                                          padding: "2px 10px",
                                          display: "flex",
                                          justifyContent: "center",
                                          margin: "auto"
                                        }}
                                      >
                                        {row.lead_id}
                                      </div>

                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.user_phone}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.language}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.id}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.poi_id}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 150 }} align="center">
                                      <div style={{ maxWidth: 120, wordBreak: "break-all", marginLeft: "15px" }} >
                                        {row.type}
                                      </div>
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.sub_type}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.district}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.state}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.name}
                                    </TableCell>


                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.source}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {row.pincode}
                                    </TableCell>
                                    <TableCell component="th" scope="row" padding="none" style={{ minWidth: 120 }} align="center">
                                      {moment(row.created_at).utc().format('DD-MM-YYYY')}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{ marginTop: 10 }}
                        >
                          <Box justifyContent="flex-end" display="flex">
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChangePage}
                              color="primary"
                              variant="outlined"
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </Box>
                        </Grid>
                        {leadsAssignTableBody.length == 0 && (
                          <h2 style={{ textAlign: "center" }}>
                            <br />
                            No Leads Found
                          </h2>
                        )}
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

          </Typography>
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
        <UploadLead
          handleCloseUploadLead={handleCloseUploadLead}
          openUploadLead={openUploadLead}
          setLeadsLoader={setLoader}
        />
      </React.Fragment>
    </>
  );
});

export default withRouter(BookingTable);
