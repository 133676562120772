export const localRoute = {
  projects: '/projects',
  leadsList: '/leads',
  assignProject: '/assign-project',
  voiceTest: '/voice-test',
  referCode: '/refer-code',
  training: '/training',
  addProject: '/add-project',
  addVoiceTest: '/add-voice',
  projectDetail: '/project-detail',
  taskerDetail: '/tasker-detail',
  leadsDetail: '/leads-detail',
  taskerList: '/agent',
  uploadLeads: '/upload-leads',
  newLeads: '/lead',
  assignLeadTable: '/report',
  settings: '/settings',
  uploadBooking: '/upload-booking',
  admin: '/admin',
  notFound: '*',
  notFound500: '*',
  demoLead: '/demo-lead',
  master: '/master',
  upi: '/npm-run-dev',
  geoPoint: '/geo-point'
}
