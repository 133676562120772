import { CREATE_LEADS_TABLE, CREATE_LEADS_TABLE_ERR, GET_LEADS_FIELDS, GET_LEADS_FIELDS_ERR, CREATE_LEAD, CREATE_LEAD_ERR, GET_LEADS, GET_LEADS_ERR, ASSIGN_LEAD, ASSIGN_LEAD_ERR, UPLOAD_LEAD, UPLOAD_LEAD_ERR, GET_USER_LEADS, GET_USER_LEADS_ERR, DELETE_ASSIGN_LEAD, DELETE_ASSIGN_LEAD_ERR, GET_USER_LEADS_DOWNLOAD, GET_USER_LEADS_DOWNLOAD_ERR, GET_DOWNLOAD_RECORD, GET_DOWNLOAD_RECORD_ERR, GET_LEAD_HISTORY, GET_LEAD_HISTORY_ERR, GET_USER_LEADS_DOWNLOAD_REPORTS, GET_USER_LEADS_DOWNLOAD_REPORTS_ERR, RE_ASSIGN_LEAD, RE_ASSIGN_LEAD_ERR, UPLOAD_DEMO_LEAD, UPLOAD_DEMO_LEAD_ERR, GET_DEMO_LEADS, GET_DEMO_LEADS_ERR, DOWNLOAD_ALL_LEADS, DOWNLOAD_ALL_LEADS_ERR } from '../../actionTypes/leadsType'

export default (state, action) => {
  console.log(action)
  switch (action.type) {
    case CREATE_LEADS_TABLE:
      return {
        ...state,
        createLeadsTable: action.payload, ...state.data,
        getLeadsFields: action.payload, ...state.data,
      }

    case CREATE_LEADS_TABLE_ERR:
      return {
        ...state,
        createLeadsTable: action.payload
      }

    case CREATE_LEAD:
      return {
        ...state,
        createLead: action.payload, ...state.data,
        getLeads: action.payload, ...state.data,
      }

    case CREATE_LEAD_ERR:
      return {
        ...state,
        createLead: action.payload
      }

    case ASSIGN_LEAD:
      return {
        ...state,
        assignLead: action.payload, ...state.data,
      }

    case ASSIGN_LEAD_ERR:
      return {
        ...state,
        assignLead: action.payload
      }

    case RE_ASSIGN_LEAD:
      return {
        ...state,
        reassignLead: action.payload, ...state.data,
      }

    case RE_ASSIGN_LEAD_ERR:
      return {
        ...state,
        reassignLead: action.payload
      }

    case GET_LEADS_FIELDS:
      return {
        ...state,
        getLeadsFields: action.payload, ...state.data,
      }

    case GET_LEADS_FIELDS_ERR:
      return {
        ...state,
        getLeadsFields: action.payload
      }

    case GET_LEADS:
      return {
        ...state,
        getLeads: action.payload, ...state.data,
      }

    case GET_LEADS_ERR:
      return {
        ...state,
        getLeads: action.payload
      }

    case DOWNLOAD_ALL_LEADS:
      return {
        ...state,
        downloadAllLeadsInitState: action.payload, ...state.data,
      }

    case DOWNLOAD_ALL_LEADS_ERR:
      return {
        ...state,
        downloadAllLeadsInitState: action.payload, ...state.data,
      }

    case GET_DEMO_LEADS:
      return {
        ...state,
        getDemoLeads: action.payload, ...state.data,
      }

    case GET_DEMO_LEADS_ERR:
      return {
        ...state,
        getDemoLeads: action.payload
      }

    case GET_LEAD_HISTORY:
      return {
        ...state,
        getLeadHistory: action.payload, ...state.data,
      }

    case GET_LEAD_HISTORY_ERR:
      return {
        ...state,
        getLeadHistory: action.payload
      }

    case GET_USER_LEADS:
      return {
        ...state,
        getUserLeads: action.payload, ...state.data,
      }

    case GET_USER_LEADS_ERR:
      return {
        ...state,
        getUserLeads: action.payload
      }

    case GET_USER_LEADS_DOWNLOAD:
      return {
        ...state,
        getUserLeadsDownload: action.payload, ...state.data,
      }

    case GET_USER_LEADS_DOWNLOAD_ERR:
      return {
        ...state,
        getUserLeadsDownload: action.payload
      }

    case GET_USER_LEADS_DOWNLOAD_REPORTS:
      return {
        ...state,
        getUserLeadsDownloadReports: action.payload, ...state.data,
      }

    case GET_USER_LEADS_DOWNLOAD_REPORTS_ERR:
      return {
        ...state,
        getUserLeadsDownloadReports: action.payload
      }


    case UPLOAD_LEAD:
      return {
        ...state,
        uploadLead: action.payload, ...state.data,
      }

    case UPLOAD_LEAD_ERR:
      return {
        ...state,
        uploadLead: action.payload
      }


    case UPLOAD_DEMO_LEAD:
      return {
        ...state,
        uploadDemoLead: action.payload, ...state.data,
      }

    case UPLOAD_DEMO_LEAD_ERR:
      return {
        ...state,
        uploadDemoLead: action.payload
      }
    case GET_DOWNLOAD_RECORD:
      return {
        ...state,
        getDownloadRecord: action.payload, ...state.data,
      }

    case GET_DOWNLOAD_RECORD_ERR:
      return {
        ...state,
        getDownloadRecord: action.payload
      }

    case DELETE_ASSIGN_LEAD:
      return {
        ...state,
        deleteAssignLead: action.payload, ...state.data,
      }

    case DELETE_ASSIGN_LEAD_ERR:
      return {
        ...state,
        deleteAssignLead: action.payload
      }


    default:
      return state;
  }
}