import {
  CREATE_PROJECT,
  CREATE_PROJECT_ERR,
  GET_NEW_PROJECT_USERS,
  GET_NEW_PROJECT_USERS_ERR,
  GET_PROJECT_USERS,
  GET_PROJECT_USERS_ERR,
  UPDATE_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS_ERR,
  UPDATE_SPREADSHEET,
  UPDATE_SPREADSHEET_ERR,
} from "../../actionTypes/projectType";
import {
  GET_PROJECTS,
  GET_PROJECTS_ERR,
  GET_PROJECT,
  GET_PROJECT_ERR,
  ASSIGN_PROJECT,
  ASSIGN_PROJECT_ERR,
  GET_ASSIGN_PROJECTS,
  GET_ASSIGN_PROJECTS_ERR,
} from "../../actionTypes/projectType";

export default (state, action) => {
  console.log(action);
  let oldData = state && state.getProjects && state.getProjects.data;
  console.log(oldData);
  switch (action.type) {
    case CREATE_PROJECT:
      let data = [action.payload, ...state.projects.data];
      return {
        ...state,
        addProject: { data: action.payload },
        projects: { data },
      };

    case CREATE_PROJECT_ERR:
      return {
        ...state,
        addProject: { data: action.payload },
      };

    case GET_PROJECTS:
      return {
        ...state,
        getProjects: action.payload,
        ...state.projects.data,
      };

    case GET_PROJECTS_ERR:
      return {
        ...state,
        getProjects: { getdata: action.payload },
      };
    case GET_ASSIGN_PROJECTS:
      return {
        ...state,
        getAssignProjects: action.payload,
        ...state.projects.data,
      };

    case GET_ASSIGN_PROJECTS_ERR:
      return {
        ...state,
        getAssignProjects: { getdata: action.payload },
      };

    case GET_PROJECT:
      return {
        ...state,
        getProject: action.payload,
        ...state.projects.data,
      };

    case GET_PROJECT_ERR:
      return {
        ...state,
        getProject: { getdata: action.payload },
      };

    case UPDATE_PROJECT_STATUS:
      return {
        ...state,
        getProjects: action.payload,
        ...state.projects.data,
      };

    case UPDATE_PROJECT_STATUS_ERR:
      return {
        ...state,
        getProjects: { data: action.payload },
      };

    case UPDATE_SPREADSHEET:
      return {
        ...state,
        spreadsheetData: { data: action.payload },
      };

    case UPDATE_SPREADSHEET_ERR:
      return {
        ...state,
        spreadsheetData: { data: action.payload },
      };

    case ASSIGN_PROJECT:
      let updatedData =
        oldData &&
        oldData.map((item) => {
          if (item.project_id === action.payload.data.project_id) {
            item.project_assigned_status = "assigned";
          }
          return item;
        });

      return {
        ...state,
        assignProject: { data: action.payload },
        getProjects: { data: updatedData },
      };
    case ASSIGN_PROJECT_ERR:
      return {
        ...state,
        assignProject: { data: action.payload },
      };
    case GET_PROJECT_USERS:
      return {
        ...state,
        getProjectUsers: action.payload,
        ...state.projects.data,
      };

    case GET_PROJECT_USERS_ERR:
      return {
        ...state,
        getProjectUsers: { getdata: action.payload },
      };
    case GET_NEW_PROJECT_USERS:
      return {
        ...state,
        getNewProjectUsers: action.payload,
        ...state.projects.data,
      };

    case GET_NEW_PROJECT_USERS_ERR:
      return {
        ...state,
        getNewProjectUsers: { getdata: action.payload },
      };
    default:
      return state;
  }
};
