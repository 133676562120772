import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Icon } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export default function DatePicker(props) {

    const { name, label, value, onChange } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                        
                          <KeyboardDatePicker
                          disableToolbar
                          style={{marginTop:0}}
                            margin="normal"
                            id="date-picker-dialog" 
                            fullWidth
                            setSelectedDate
                            minDate = {new Date()} 
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            label={label}
                            format="dd/MM/yyyy"
                            name={name}
                            value={value}
                            onChange={date =>onChange(convertToDefEventPara(name,date))}
                            keyboardIcon={<ArrowDropDownIcon />}
                          />

                        
                      </MuiPickersUtilsProvider>
    )
}
