import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./ReferCodeStyle";
import ReferCode from "../../../components/refer/referCode";
const RefersCode = React.memo((props) => {
  return (
    <>
    
     <ReferCode/>
    </>
  );
});

export default withRouter(RefersCode);
