import React, { useEffect, useState, useContext, Fragment } from "react";
import { styles } from "./MasterTableStyle";
import "date-fns";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from "@mui/material/Typography";
import Pagination from "@material-ui/lab/Pagination";
import UploadIcon from '@mui/icons-material/Upload';
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import SuccessNotification from "../popUpNotification/SuccessNotification";
import FailureNotification from "../popUpNotification/FailureNotification";
import { useForm, Form } from "../useForm";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "context/GlobalState";
import { getLeads } from "context/actions/leadsAction/getLeads";
import { getProjectUsers } from "context/actions/projectAction/getProjectUsers";
import { assignLead } from "context/actions/leadsAction/assignLead";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import moment from "moment";
import UploadLead from "components/uploadLead";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import MasteruploadLead from "components/masteruploadLead";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getMasterLeads } from "context/actions/masterAction/getMasterLeads";
import SearchBar from "material-ui-search-bar";
toast.configure();

const useStyles = makeStyles(styles);

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
    root: {
        color: '#B8B8B8',
    },
    selected: {
        color: '#186DA6',
    },
})(Tab);


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: '#888888',
        background: '#F2F2F2'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const columns = [
    {
        id: "MID",
        label: "MID",
        minWidth: 80,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "leadId",
        label: "Lead ID",
        minWidth: 80,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "userPhone",
        label: "User Phone",
        minWidth: 130,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "Source",
        label: "Source",
        minWidth: 80,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "Language",
        label: "Language",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },

    {
        id: "Pincode",
        label: "Pincode",
        minWidth: 130,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
];

const MasterTable = React.memo((props) => {
    const classes = useStyles();
    const { masterState, masterDispatch } = useContext(GlobalContext);
    const [page, setPage] = React.useState(1);
    const [openUploadLead, setOpenOpenUploadLead] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [masterDataLoader, setMasterDataLoader] = useState(false);
    const [masterTableData, setMasterTableData] = useState([]);
    const [noMasterTableData, setNoMasterTableData] = useState(false);
    const [masterDataError, setMasterDataError] = useState("");
    const [searched, setSearched] = useState("");
    const [filteredMasterTableData, setFilterdMasterTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [paginationCount, setPaginationCount] = React.useState(1);

    const requestSearch = (searchedVal) => {
        const filteredRows = masterTableData.filter((row) => {
            return (row.mid && row.mid.toLowerCase().includes(searchedVal.toLowerCase()) || row.user_phone && row.user_phone.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.source && row.source.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.language && row.language.toLowerCase().includes(searchedVal.toLowerCase()) || row.pincode && row.pincode.toString().toLowerCase().includes(searchedVal.toLowerCase()));
        });
        setFilterdMasterTableData(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }


    const handleCloseUploadLead = () => {
        setOpenOpenUploadLead(false);
        setPage(1);
    };

    const handleClickOpenUploadLead = () => {
        setOpenOpenUploadLead(true);
    };

    const handleChangePage = (event, newPage) => {
        console.log("newPage", newPage);
        setPage(newPage);
        setMasterDataLoader(true)
        setPaginationCount(newPage);
        getMasterLeads({ status: "all", page: newPage })(masterDispatch)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleTabCall = (status) => {
    //     getMasterLeads({ status: status, page: page })(masterDispatch)
    // }

    useEffect(() => {
        setMasterDataLoader(true);
        getMasterLeads({ status: "all", page: page })(masterDispatch)
    }, []);

    console.log("check master leads", masterState.leads)

    useEffect(() => {

        let status = masterState?.leads && masterState?.leads?.status;

        if (status === true) {
            setMasterDataLoader(false);
            setMasterTableData(masterState?.leads?.data?.res);
            setFilterdMasterTableData(masterState?.leads?.data?.res);
            setTotalPage(masterState?.leads?.data?.total_page);
            if (masterState?.leads?.data?.res.length === 0) {
                setNoMasterTableData(true);
            } else {
                setNoMasterTableData(false);
            }
        }

        if (status === 500) {
            setMasterDataLoader(false);
            setMasterDataError("Internal Server Error")
        }

        if (status === 401) {
            setMasterDataLoader(false);
        }

    }, [masterState])

    return (
        <>
            <div className={classes.root} style={{ marginTop: '-15px' }}>
                <Grid container style={{ marginBottom: 10 }} justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                        <Paper
                            className={classes.topCardPaper}
                            style={{
                                background: "#f0f0f0",
                                border: "0.5px solid #ddd",
                                borderRadius: 2,
                            }}
                            elevation={0}
                        >
                            <Grid sm={12} justifyContent="center" alignItems="center">
                                <div
                                    style={{
                                        color: "#673695",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <LeaderboardIcon
                                        style={{ fontSize: 20 }}
                                        className="icon-image"
                                    />{" "}
                                    <span
                                        className={classes.papercount1}
                                        style={{ color: "#673695" }}
                                    >
                                        &nbsp; Total{" "}
                                    </span>{" "}
                                    &nbsp;{" "}
                                    <div style={{ fontSize: 14 }}>
                                        {" "}
                                        <span
                                            style={{
                                                padding: "0px 4px 0px 4px",
                                                borderRadius: 3,
                                                color: "#673695",
                                            }}
                                        >
                                            {masterState?.leads?.data?.total_leads}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* <Grid item xs={3}>
                        <Paper
                            className={classes.topCardPaper}
                            style={{
                                background: "#f0f0f0",
                                border: "0.5px solid #ddd",
                                borderRadius: 2,
                            }}
                            elevation={0}
                        >
                            <Grid sm={12} justifyContent="center" alignItems="center">
                                <div
                                    style={{
                                        color: "rgb(35, 106, 151)",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <LeaderboardIcon
                                        style={{ fontSize: 20 }}
                                        className="icon-image"
                                    />{" "}
                                    <span
                                        className={classes.papercount1}
                                        style={{ color: "rgb(35, 106, 151)" }}
                                    >
                                        &nbsp; Active
                                    </span>{" "}
                                    &nbsp;{" "}
                                    <div style={{ fontSize: 14 }}>
                                        {" "}
                                        <span
                                            style={{
                                                padding: "0px 4px 0px 4px",
                                                borderRadius: 3,
                                                color: "rgb(35, 106, 151)",
                                            }}
                                        >
                                            {masterState?.leads?.data?.total_active_leads}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid> */}

                    {/* <Grid item xs={3}>
                        <Paper
                            className={classes.topCardPaper}
                            style={{
                                background: "#f0f0f0",
                                border: "0.5px solid #ddd",
                                borderRadius: 2,
                            }}
                            elevation={0}
                        >
                            <Grid sm={12} justifyContent="center" alignItems="center">
                                <div
                                    style={{
                                        color: "rgb(35, 106, 151)",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <LeaderboardIcon
                                        style={{ fontSize: 20 }}
                                        className="icon-image"
                                    />{" "}
                                    <span
                                        className={classes.papercount1}
                                        style={{ color: "rgb(35, 106, 151)" }}
                                    >
                                        &nbsp; Inactive
                                    </span>{" "}
                                    &nbsp;{" "}
                                    <div style={{ fontSize: 14 }}>
                                        {" "}
                                        <span
                                            style={{
                                                padding: "0px 4px 0px 4px",
                                                borderRadius: 3,
                                                color: "rgb(35, 106, 151)",
                                            }}
                                        >
                                            {" "}
                                            {masterState?.leads?.data?.total_inactive_leads}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid> */}

                    <Grid item xs={3} style={{ display: 'flex' }}>


                        <Button startIcon={<UploadIcon />} variant="contained"
                            fullWidth
                            size='large'
                            style={{ background: "#673695", color: "white" }}
                            onClick={handleClickOpenUploadLead} >
                            Upload Leads
                        </Button>


                    </Grid>
                </Grid>
                {/* <Grid container justifyContent="space-between" xs={12} spacing={0} style={{ marginBottom: "10px" }} >
                    <Grid item xs={12} >
                        <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: 'white', color: 'black' }} >
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <CustomTab style={{ textTransform: 'none' }} label={<b>Active</b>} {...a11yProps(0)} onClick={() => handleTabCall("active")} />
                                <CustomTab style={{ textTransform: 'none' }} label={<b>Inactive</b>} {...a11yProps(1)} onClick={() => handleTabCall("inactive")} />
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid item xs={3}>
                        <SearchBar style={{ width: '100%' }}
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                        />
                    </Grid>
                </Grid> */}

                {/* <TabPanel value={value} index={0} style={{ padding: '0px !important' }}> */}

                <Card variant="outlined">
                    <CardContent className={classes.custInput}>
                        {masterDataLoader === true && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}

                        {(noMasterTableData === true) && (
                            <div style={{ textAlign: "center" }}><h1>No Data Found</h1></div>
                        )}

                        {(filteredMasterTableData.length > 0 && masterDataLoader === false) && (
                            <Grid item xs={12} >
                                <TableContainer >
                                    <Table className={classes.table} size="small" aria-label="customized table" >
                                        <TableHead>
                                            <TableRow className={classes.custRow} >
                                                {columns.map((column) => (
                                                    <StyledTableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth: column.minWidth,
                                                            color: "#000",
                                                            background: "#9f9c9c",
                                                            fontWeight: "700",
                                                            padding: 10
                                                        }}
                                                    >
                                                        {column.label}
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredMasterTableData.map(row => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.master_id}>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            {row.mid}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            {row.master_lead_id}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            XXXXXX{row.user_phone[row.user_phone.length - 4]}{row.user_phone[row.user_phone.length - 3]}{row.user_phone[row.user_phone.length - 2]}{row.user_phone[row.user_phone.length - 1]}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            {row.source}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            {row.language}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                            {row.pincode}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ marginTop: 10 }}
                                >
                                    <Box justifyContent="flex-end" display="flex">
                                        <Pagination
                                            count={totalPage}
                                            page={page}
                                            onChange={handleChangePage}
                                            color="primary"
                                            variant="outlined"
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
                {/* </TabPanel> */}
                {/* <TabPanel value={value} index={1} style={{ padding: '0px !important' }}>
                    {masterDataLoader === true && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    )}

                    {(noMasterTableData === true && value === 1) && (
                      <div style={{ textAlign: "center" }}><h1>No Data Found</h1></div>
                    )}

                    {(filteredMasterTableData.length > 0 && masterDataLoader === false) && (
                        <Grid item xs={12} >
                            <TableContainer >
                                <Table className={classes.table} size="small" aria-label="customized table" >
                                    <TableHead>
                                        <TableRow className={classes.custRow} >
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        color: "#000",
                                                        background: "#9f9c9c",
                                                        fontWeight: "700",
                                                        padding: 10
                                                    }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredMasterTableData.map(row => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.master_id}>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.mid}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.master_lead_id}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.user_phone}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.source}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.language}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ color: '#000000' }}>
                                                        {row.pincode}
                                                    </StyledTableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid
                                xs={12}
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ marginTop: 10 }}
                            >
                                <Box justifyContent="flex-end" display="flex">
                                    <Pagination
                                        count={totalPage}
                                        page={page}
                                        onChange={handleChangePage}
                                        color="primary"
                                        variant="outlined"
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </TabPanel> */}
            </div>

            <MasteruploadLead
                handleCloseUploadLead={handleCloseUploadLead}
                setMasterDataLoader={setMasterDataLoader}
                openUploadLead={openUploadLead}
                value={value}
            />
        </>
    )
})

export default withRouter(MasterTable)