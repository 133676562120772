import {
  USER_IMAGE_UPLOAD,
  USER_IMAGE_UPLOAD_ERR,
} from "../../actionTypes/userType";
import { createImageUrl } from 'api/Api';
import axios from "axios";
export const createUrlForImage = (payload) => async (dispatch) => {
    try {
        
        let results = await axios.post(`${createImageUrl}`, payload)
        let data = results && results.data;
        
        dispatch({
            type: USER_IMAGE_UPLOAD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: USER_IMAGE_UPLOAD_ERR,
            payload: error.response,
        });
    }
};


