import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { styles } from "./ProjectTableStyle";
import PlusIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import { bookings } from "../../../store/actions/bookingAction";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "../../../App.css";
import { Avatar } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import { Input } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { localRoute } from "routes/localRoutes";
import { GlobalContext } from "../../../context/GlobalState";
import { getProjects } from "../../../context/actions/projectAction/getProjects";
import { updateProjectStatus } from "../../../context/actions/projectAction/updateProjectStatus";
import { getAssignProjects } from "../../../context/actions/projectAction/getAssignProjects";
import moment from "moment";

const useStyles = makeStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: "#B8B8B8",
  },
  selected: {
    color: "#186DA6",
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: "none",
    color: "#888888",
    background: "#F2F2F2",
  },
  body: {
    fontSize: 14,
    //border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: 'white',
    },
  },
}))(TableRow);

const columns = [
  { id: "projectId", label: "Project ID", minWidth: 100 },
  {
    id: "brandName",
    label: "Brand Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "startEndDate",
    label: "Start date",
    minWidth: 200,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: 'projectLead',
  //   label: 'Project Lead',
  //   minWidth: 60,
  //   align: 'left',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: "location",
    label: "Location",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "JobCategories",
    label: "Job Categories",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 40,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const completed_columns = [
  { id: "projectId", label: "Project ID", minWidth: 100 },
  {
    id: "brandName",
    label: "Brand Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "startEndDate",
    label: "Start & End date",
    minWidth: 200,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: 'projectLead',
  //   label: 'Project Lead',
  //   minWidth: 60,
  //   align: 'left',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: "location",
    label: "Location",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "JobCategories",
    label: "Job Categories",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: 'action',
  //   label: 'Action',
  //   minWidth: 40,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

const assigned_columns = [
  { id: "projectId", label: "Project ID", minWidth: 100 },
  {
    id: "brandName",
    label: "Brand Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Tasker Name",
    label: "Tasker Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "location",
    label: "Tasker Mobile",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Project Date",
    label: "Project Date",
    minWidth: 60,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: 'action',
  //   label: 'Action',
  //   minWidth: 40,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

const ProjectTable = React.memo((props) => {
  const { projectState, projectDispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activeProjects, setActiveProjects] = React.useState([]);
  const [nonactiveProjects, setNonActiveProjects] = React.useState([]);
  const [completedProjects, setCompletedProjects] = React.useState([]);
  const [assignProjects, setAssignProjects] = React.useState([]);
  const [projectItem, setProjectItem] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editProjectID, setEditProjectID] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [leadsshow, setLeadsShow] = React.useState(false);
  const history = useHistory();

  const handleAssignClickOpen = (item) => {
    console.log(editProjectID);
    history.push("/assign-project/" + editProjectID);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAssignClose = () => {
    setShow(false);
  };

  const handleLeadsClose = () => {
    setLeadsShow(false);
  };

  useEffect(() => {
    getProjects()(projectDispatch);
  }, []);

  useEffect(() => {
    getAssignProjects()(projectDispatch);
  }, []);

  const openCreateLeads = (row) => {
    console.log(row);
  };

  const edit = (projectID, row) => {
    setEditProjectID(projectID);
    setProjectItem(row);
  };

  const statusChange = (project_status) => {
    //console.log(editProjectID)
    //console.log(project_status)
    let postData = {
      project_id: editProjectID,
      project_status: project_status,
    };
    updateProjectStatus(postData)(projectDispatch);
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(projectState);
    let status = projectState.getProjects.status;
    // SUCCESS RESPONSE
    if (status === true) {
      console.log("projectState() ", projectState.getProjects.data);
      let activeFiltered = projectState.getProjects.data.filter(
        (project) => project.project_status == "active"
      );
      setActiveProjects(activeFiltered);
      let nonActiveFiltered = projectState.getProjects.data.filter(
        (project) => project.project_status == "non-active"
      );
      setNonActiveProjects(nonActiveFiltered);
      let completedFiltered = projectState.getProjects.data.filter(
        (project) => project.project_status == "complete"
      );
      setCompletedProjects(completedFiltered);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getProjects);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getProjects);
    }
  }, [projectState]);

  useEffect(() => {
    console.log(projectState);
    let status = projectState.getAssignProjects.status;
    // SUCCESS RESPONSE
    if (status === true) {
      console.log("projectState() ", projectState.getAssignProjects.data);
      setAssignProjects(projectState.getAssignProjects.data);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getAssignProjects);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getAssignProjects);
    }
  }, [projectState]);

  const ITEM_HEIGHT = 48;

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event, projectID, row) => {
    setAnchorEl(event.currentTarget);
    setEditProjectID(projectID);
    setProjectItem(row);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleCancelAssignClickOpen = (item) => {
    console.log(item);
    setProjectItem(item);
  };

  return (
    <>
      <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
        <Grid
          xs={12}
          container
          alignItems="center"
          style={{ padding: "10px", paddingBottom: "0px" }}
        >
          <Grid item container justify="flex-start" sm={6}>
            <h2 className={classes.pageTitle}>
              <b>Project Details</b>
            </h2>
          </Grid>
          <Grid item direction="row" container justify="flex-end" sm={6}>
            <Button
              variant="contained"
              style={{ textTransform: "none" }}
              size="medium"
              className="button"
              endIcon={<PlusIcon />}
              onClick={() => history.push(localRoute.addProject)}
            >
              Add New Project
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            borderTop: "1px solid #B8B8B8 ",
            marginBottom: "10px",
            margin: "10px",
          }}
        ></div>
        <div className={classes.root}>
          <Grid item container xs={12}>
            <Grid xs={7}>
              <AppBar
                position="static"
                style={{
                  boxShadow: "none",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <CustomTab
                    style={{ textTransform: "none" }}
                    label={<b>Active Project</b>}
                    {...a11yProps(0)}
                  />
                  <CustomTab
                    style={{ textTransform: "none" }}
                    label={<b>Non Active Project</b>}
                    {...a11yProps(1)}
                  />
                  <CustomTab
                    style={{ textTransform: "none" }}
                    label={<b>Assigned Project</b>}
                    {...a11yProps(2)}
                  />
                  <CustomTab
                    style={{ textTransform: "none" }}
                    label={<b>Completed Project</b>}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid xs={2}></Grid>
            <Grid xs={3}>
              <TextField
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TabPanel
            value={value}
            index={0}
            style={{ padding: "0px !important" }}
          >
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.custRow}>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeProjects
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow hover key={index}>
                            <StyledTableCell component="th" scope="row">
                              <Link
                                to={`${localRoute.projectDetail}/${
                                  row && row.project_id
                                }`}
                                style={{
                                  textDecoration: "none",
                                  color: "#0047FF",
                                }}
                              >
                                {row.project_number}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "30%",
                                  color: "#000000",
                                  borderBottom: "none",
                                  padding: "0px",
                                }}
                              >
                                <Avatar src={row.project_logo_image} />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "70%",
                                  color: "#000000",
                                  borderBottom: "none",
                                }}
                              >
                                <label> {row.project_name}</label>
                              </StyledTableCell>
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              style={{ width: "25%", color: "#000000" }}
                            >
                              {row.start_date && row.end_date && (
                                <label>
                                  {moment(row.start_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}
                                </label>
                              )}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.projectLead}</StyledTableCell> */}
                            <StyledTableCell
                              align="left"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              {row.location}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              {row.project_job_category}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              <label
                                style={{
                                  backgroundColor: "#00E0AA",
                                  padding: "5px",
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                }}
                              >
                                ACTIVE
                              </label>
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ width: "15%" }}
                            >
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleClick(e, row.project_id, row)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={menuopen}
                                onClose={handleClose1}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "20ch",
                                  },
                                }}
                              >
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => handleAssignClickOpen(row)}
                                >
                                  ASSIGN PROJECT
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => statusChange("non-active")}
                                >
                                  Move to NON ACTIVE
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => statusChange("complete")}
                                >
                                  Move to COMPLETED
                                </MenuItem>
                              </Menu>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={activeProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.custRow}>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nonactiveProjects
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Link
                                to={`${localRoute.projectDetail}/${
                                  row && row.project_id
                                }`}
                                style={{
                                  textDecoration: "none",
                                  color: "#0047FF",
                                }}
                              >
                                {row.project_number}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "30%",
                                  color: "#000000",
                                  borderBottom: "none",
                                  padding: "0px",
                                }}
                              >
                                <Avatar src={row.project_logo_image} />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "70%",
                                  color: "#000000",
                                  borderBottom: "none",
                                }}
                              >
                                <label> {row.project_name}</label>
                              </StyledTableCell>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "25%", color: "#000000" }}
                            >
                              {row.start_date && row.end_date && (
                                <label>
                                  {moment(row.start_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                                  -{" "}
                                  {moment(row.end_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}
                                </label>
                              )}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.projectLead}</StyledTableCell> */}
                            <StyledTableCell
                              align="left"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              {row.location}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              {row.project_job_category}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              <label
                                style={{
                                  backgroundColor: "#FF7E7E",
                                  padding: "5px",
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                }}
                              >
                                NON ACTIVE
                              </label>
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              style={{ width: "15%" }}
                            >
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleClick(e, row.project_id, row)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={menuopen}
                                onClose={handleClose1}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "20ch",
                                  },
                                }}
                              >
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => handleAssignClickOpen(row)}
                                >
                                  ASSIGN PROJECT
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => statusChange("active")}
                                >
                                  Move to ACTIVE
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: "12px" }}
                                  onClick={() => statusChange("complete")}
                                >
                                  Move to COMPLETED
                                </MenuItem>
                              </Menu>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={nonactiveProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.custRow}>
                      {assigned_columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignProjects
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Link
                                to={`${localRoute.projectDetail}/${
                                  row && row.project_id
                                }`}
                                style={{
                                  textDecoration: "none",
                                  color: "#0047FF",
                                }}
                              >
                                {row.project_number}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "30%",
                                  color: "#000000",
                                  borderBottom: "none",
                                  padding: "0px",
                                }}
                              >
                                <Avatar src={row.project_logo_image} />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "70%",
                                  color: "#000000",
                                  borderBottom: "none",
                                }}
                              >
                                <label> {row.project_name}</label>
                              </StyledTableCell>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              <label>{row.full_name}</label>
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.projectLead}</StyledTableCell> */}
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              {row.mobile_number}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              {row.start_date && row.end_date && (
                                <label>
                                  {moment(row.start_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                                  -{" "}
                                  {moment(row.end_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}
                                </label>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              {row.project_status == "non-active" ? (
                                <label
                                  style={{
                                    backgroundColor: "#FF7E7E",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontSize: "10px",
                                    color: "white",
                                  }}
                                >
                                  NON ACTIVE
                                </label>
                              ) : (
                                ""
                              )}
                              {row.project_status == "active" ? (
                                <label
                                  style={{
                                    backgroundColor: "#00E0AA",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontSize: "10px",
                                  }}
                                >
                                  ACTIVE
                                </label>
                              ) : (
                                ""
                              )}
                              {row.project_status == "complete" ? (
                                <label
                                  style={{
                                    backgroundColor: "#125693",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontSize: "10px",
                                    color: "white",
                                  }}
                                >
                                  COMPLETED
                                </label>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                            {/* <StyledTableCell align="right" style={{ width: '15%' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                             onClick={(e)=>handleClick(e,row.project_id,row)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={menuopen}
                              onClose={handleClose1}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItem onClick={handleClose1} style={{ fontSize: '12px' }} onClick={() => handleLeadClickOpen(row)}>Create Leads</MenuItem>
                            </Menu>
                          </StyledTableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={assignProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.custRow}>
                      {completed_columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedProjects
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Link
                                to={`${localRoute.projectDetail}/${
                                  row && row.project_id
                                }`}
                                style={{
                                  textDecoration: "none",
                                  color: "#0047FF",
                                }}
                              >
                                {row.project_number}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "30%",
                                  color: "#000000",
                                  borderBottom: "none",
                                  padding: "0px",
                                }}
                              >
                                <Avatar src={row.project_logo_image} />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{
                                  width: "70%",
                                  color: "#000000",
                                  borderBottom: "none",
                                }}
                              >
                                <label> {row.project_name}</label>
                              </StyledTableCell>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "25%", color: "#000000" }}
                            >
                              {row.start_date && row.end_date && (
                                <label>
                                  {moment(row.start_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                                  -{" "}
                                  {moment(row.end_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}
                                </label>
                              )}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.projectLead}</StyledTableCell> */}
                            <StyledTableCell
                              align="left"
                              style={{ width: "15%", color: "#000000" }}
                            >
                              {row.location}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%", color: "#000000" }}
                            >
                              {row.project_job_category}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ width: "15%", color: "white" }}
                            >
                              <label
                                style={{
                                  backgroundColor: "#125693",
                                  padding: "5px",
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                }}
                              >
                                COMPLETED
                              </label>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={completedProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
        </div>
      </div>
    </>
  );
});
export default withRouter(ProjectTable);
