export default {
    leads: {
        loading: false,
        error: null,
        data: [],
    },
    uploadLead: {
        loading: false,
        error: null,
        data: null,
    },
}