import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AssignLeadTableStyle";
import AssignLeadTable from "../../components/assignLeadTable";
const Home = React.memo((props) => {
  return (
    <>
     <AssignLeadTable/>
    </>
  );
});

export default withRouter(Home);
