import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styles } from "./TaskerDetailStyle";
import PlusIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'
import '../../../App.css'
import bhim from '../../../assets/images/bhim.png'
import { Avatar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import '../../../App.css'
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { localRoute } from 'routes/localRoutes';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { GlobalContext } from "../../../context/GlobalState";
import { getTasker } from "../../../context/actions/taskerAction/getTasker";
import moment from "moment";
const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: '#B8B8B8',
  },
  selected: {
    color: '#186DA6',
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    color: '#888888',
    background: '#F2F2F2'
  },
  body: {
    fontSize: 14,
    //border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: 'white',

    },
  },
}))(TableRow);



const projects_columns = [
  
  {
    id: 'Project',
    label: 'Project',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'TotalLeadEarning',
    label: 'Total Lead earning',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'TotalBonus',
    label: 'Total Bonus',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Totalpoints',
    label: 'Total points',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];


const leads_columns = [
  { id: 'LeadID', label: 'Lead ID', minWidth: 100 },
  {
    id: 'Project',
    label: 'Project',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Name',
    label: 'Name',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Number',
    label: 'Number',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Email',
    label: 'Email Id',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 40,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];



function createData(UserID, Name, Mobile, Location, Email, status, action) {
  return { UserID, Name, Mobile, Location, Email, status, action };
}


const TaskerDetail = React.memo((props) => {
  const classes = useStyles();
  const { taskerState, taskerDispatch } = useContext(GlobalContext);
  let taskerId = props.location.pathname && props.location.pathname.split('/').pop()
  const [taskerDetail, setTaskerDetail] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const [page, setPage] = React.useState(0);


  useEffect(() => {
    getTasker(taskerId)(taskerDispatch)
  }, [])

  useEffect(() => {
    console.log(taskerState)
    let status = taskerState.getTasker.status
    // SUCCESS RESPONSE
    if (status === true) {
      console.log("taskerState() ", taskerState.getTasker.data)
    setTaskerDetail(taskerState.getTasker.data)
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", taskerState.getTasker)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      console.log("Validation Error:", taskerState.getTasker)
    }
  }, [taskerState])



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const rows_active = [
    createData('100', 'Jiomart', 'David Jacob', '+91 9876543210', 'david@gmail.com', 'Offer Accepted', 'action'),
    createData('100', 'Jiomart', 'David Jacob', '+91 9876543210', 'david@gmail.com', 'Rejected', 'action'), 
    
  ];

  const rows_inactive = [
    createData( 'Jiomart', '15500', '5949',  '200034', 'Inprogress'),
    createData( 'Jiomart', '15500', '5949',  '200034', 'Completed'),
  ];
  const ITEM_HEIGHT = 48;


  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Grid container xs={12} style={{ margin: '10px' }}>
        <Grid xs={8}>
          <div style={{ color: '#888888' }}>
            Taskers  /  User ID {taskerDetail.user_id}
          </div>

        </Grid>
        <Grid xs={4} >
          <Button style={{ color: '#186DA6', float: 'right', textTransform: 'none' }} onClick={event => window.location.href = '/tasker'}>
            <ArrowBackIcon />
            <b>Back</b>

          </Button>

        </Grid>
      </Grid>
      <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', }}>
        <Grid xs={12}
          container
          alignItems="center"
          style={{ paddingBottom: '0px' }}
        >
          <Grid item container justify="flex-start" sm={6}>
            <h2 className={classes.pageTitle}><b>User ID {taskerDetail.user_id}</b></h2>
          </Grid>
          <Grid item direction="row" container justify="flex-end" sm={6}>
          </Grid>
        </Grid>
        <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div>
        <Grid item container xs={12} >
          <Grid xs={1} style={{ justifyContent: "center", display: "flex" }}>
            <Avatar style={{ width: '80px', height: '80px', justifyContent: "center", display: "flex" }} src={taskerDetail.profile_image}/>
          </Grid>
          <Grid xs={10} style={{ marginTop: '15px' }}>
            <div style={{ fontSize: '16px' }}><b>{taskerDetail.full_name}</b></div>
            <div className={classes.fonts}>{taskerDetail.mobile_number}</div>
          </Grid>

        </Grid><br />
        <Grid container spacing={3} className={classes.paddings}  >
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <PhoneIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total Call</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <PhoneInTalkIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total call time</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <PhoneCallbackIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total attended call</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <PhoneDisabledIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total non attended call</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <WorkOutlineIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total lead generated</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <MonetizationOnIcon style={{ color: '#186DA6', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#186DA6' }}>0</div>
                  <div style={{ color: '#186DA6' }}  >Total Earned</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

        </Grid>

        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Personal Details</h4>
        </Grid>
        <Grid item container xs={12} className={classes.aligning}>
          <Grid xs={3}>
            <label className={classes.fonts}><b>Email ID</b></label><br />
            <label className={classes.fonts}><b>Pincode</b></label><br />
            <label className={classes.fonts}><b>Network</b></label><br />
            <label className={classes.fonts}><b>Internet setup</b></label><br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.email_id}</label> &nbsp;&nbsp;<label style={{ border: '0.5px solid #009E78', padding: '5px', borderRadius: '20px', fontSize: '12px', color: '#009E78' }}><CheckCircleIcon style={{ fontSize: '10px' }} />verified</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.pincode}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.mobile_network}  </label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.internet_setup=='Yes' ? 'Available' : 'Not Available'}</label><br />

          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}><b>Date of birth</b></label><br />
            <label className={classes.fonts}><b>Gender </b></label><br />
            <label className={classes.fonts}><b>Mobile </b></label><br />
            <label className={classes.fonts}><b>Laptop/Desktop </b></label><br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{moment(taskerDetail.dob).utc().format('DD-MM-YYYY')}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.gender}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;Samsung M50</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;Laptop</label><br />
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Documents</h4>
        </Grid>
        <Grid container spacing={3} className={classes.paddings}  >

          <Grid item xs={3}>
            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>Adhaar Card Font View</b> </div><br />
            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={taskerDetail.aadhar_front_image} style={{width:'200px',height:'200px'}}/>

                </Grid>

              </Grid>
              <Grid container>
                <Grid item xs={8} > 
                  <TextField
                    id="standard-fullname-input"
                    label='Aadhaar Number'
                    fullWidth
                    autoComplete="current-fullname"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button className={classes.saveBtn} type="submit" size="small" variant="contained" color="primary">
                    verify
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>Adhaar Card Back View</b> </div><br />
            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={taskerDetail.aadhar_back_image} style={{width:'200px',height:'200px'}}/>

                </Grid>

              </Grid>
              <Grid container>
                <Grid item xs={8} >
                  {/* <TextField
                    id="standard-fullname-input"
                    label='Aadhaar Back'
                    fullWidth
                    autoComplete="current-fullname"
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <Button className={classes.saveBtn} type="submit" size="small" variant="contained" color="primary">
                    verify
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>Pan card</b> </div><br />
            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={taskerDetail.pan_image} style={{width:'200px',height:'200px'}}/>

                </Grid>

              </Grid>
              <Grid container>
                <Grid item xs={8} >
                  <TextField
                    id="standard-fullname-input"
                    label='Pan Number'
                    fullWidth
                    autoComplete="current-fullname"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button className={classes.saveBtn} type="submit" size="small" variant="contained" color="primary">
                    verify
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>UPI ID</b> </div><br /><br /><br />
            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={bhim} />

                </Grid>

              </Grid><br /><br />
              <Grid container>
                <Grid item xs={12} >
                  <div>{taskerDetail.upi_id} &nbsp;&nbsp;<CheckCircleIcon style={{ fontSize: '20px', color: '#009E78' }} /></div>
                </Grid>

              </Grid>
            </Paper>
          </Grid>


        </Grid>

        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Professional Details</h4>
        </Grid>
        <Grid item container xs={12} className={classes.aligning}>
          <Grid xs={3}>
            <label className={classes.fonts}><b>Education</b></label><br />
            <label className={classes.fonts}><b>Customer service experience </b></label><br />
            <label className={classes.fonts}><b>Customer support experience</b></label><br />
            <label className={classes.fonts}><b>Language you speak</b></label><br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.highest_education}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.customer_service_exp}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.customer_support_exp}</label><br />
              <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.languages}</label>
            

          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}><b>Telecalling experience </b></label><br />
            <label className={classes.fonts}><b>Business </b></label><br />
            <label className={classes.fonts}><b>Mother Tongue</b></label><br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.telecalling_exp}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.business_process}</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;{taskerDetail.mother_tongue}</label><br />

          </Grid>
        </Grid>
        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Training Results</h4>
        </Grid>
        <Grid container spacing={3} className={classes.paddings}  >
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable1}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <CheckCircleOutlineIcon style={{ color: '#009E78', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#000000' }}>Project Training</div>
                  <div style={{ color: '#000000' }}  >8/10&nbsp;&nbsp;<label style={{ color: '#009E78' }}><b>Pass</b></label></div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable1}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <CheckCircleOutlineIcon style={{ color: '#009E78', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#000000' }}>Earning Training</div>
                  <div style={{ color: '#000000' }}  >8/10&nbsp;&nbsp;<label style={{ color: '#009E78' }}><b>Pass</b></label></div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTabledanger}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <CancelOutlinedIcon style={{ color: '#FF3E3E', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#000000' }}>Earning Training</div>
                  <div style={{ color: '#000000' }}  >3/10&nbsp;&nbsp;<label style={{ color: '#FF3E3E' }}><b>Failed</b></label></div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Language voice test result</h4>
        </Grid>
        <Grid container spacing={3} className={classes.paddings}  >
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTable1}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <CheckCircleOutlineIcon style={{ color: '#009E78', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#000000' }}>English</div>
                  <div style={{ color: '#000000' }}  ><label style={{ color: '#009E78' }}><b>Test Passed</b></label></div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0} className={classes.pointsTabledanger}>
              <Grid container>
                <Grid item xs={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                  <CancelOutlinedIcon style={{ color: '#FF3E3E', width: '50px', height: '50px' }} />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ color: '#000000' }}>Kanada</div>
                  <div style={{ color: '#000000' }}  ><label style={{ color: '#FF3E3E' }}><b>Test Failed</b></label></div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid><br /><br />
        <div className={classes.root}>
          <Grid item container xs={12}>
            <Grid xs={7}>
              <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: 'white', color: 'black' }} >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" >
                  <CustomTab style={{ textTransform: 'none' }} label={<b>Leads</b>} {...a11yProps(0)} />
                  <CustomTab style={{ textTransform: 'none' }} label={<b>Projects</b>} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid xs={2}>

            </Grid>
            <Grid xs={3}>
              <TextField
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

          </Grid>
          <TabPanel value={value} index={0} style={{ padding: '0px !important' }}>
            <Grid item xs={12} >
              <TableContainer >
                <Table className={classes.table} aria-label="customized table" >
                  <TableHead>
                    <TableRow className={classes.custRow} >
                      {leads_columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows_active.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          <StyledTableCell component="th" scope="row">
                            <Link  style={{ textDecoration: "none", color: '#0047FF' }}>
                              {row.UserID}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar /></StyledTableCell>
                            <StyledTableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.Name}</label></StyledTableCell>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Mobile}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Location}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.Email}</StyledTableCell>

                          <StyledTableCell align="center" style={{ width: '15%', color: '#000000', }}><label style={{ backgroundColor: '#00E0AA', padding: '5px', borderRadius: '10px', fontSize: '10px' }}>{row.status}</label></StyledTableCell>
                          <StyledTableCell align="right" style={{ width: '15%' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={menuopen}
                              onClose={handleClose1}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >

                              <MenuItem onClick={handleClose1} style={{ fontSize: '12px' }}>Verify tasker</MenuItem>

                            </Menu>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows_active.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12} >
              <TableContainer >
                <Table className={classes.table} aria-label="customized table" >
                  <TableHead>
                    <TableRow className={classes.custRow} >
                      {projects_columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows_inactive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                         
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar /></StyledTableCell>
                            <StyledTableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.UserID}</label></StyledTableCell>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Name}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Mobile}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.Location}</StyledTableCell>

                          <StyledTableCell align="center" style={{ width: '15%', color: '#000000', }}><label style={{ backgroundColor: '#FF7E7E', padding: '5px', borderRadius: '10px', fontSize: '10px' }}>{row.Email}</label></StyledTableCell>
                          
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows_inactive.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
        </div>
      </div>
    </>
  );
})
export default withRouter(TaskerDetail)
