import { GET_PROJECT, GET_PROJECT_ERR } from '../../actionTypes/projectType'
import { getProjectApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getProject = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getProjectApi}/${payload}`, await accessToken())
        console.log(results)
        let data = results && results.data
        console.log('GET PROJECT', data)
        dispatch({
            type: GET_PROJECT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_PROJECT_ERR,
            payload: error.response,
        });
    }
};


