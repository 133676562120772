import { getMasterLeadsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
import { GET_MASTER_LEADS, GET_MASTER_LEADS_ERR } from 'context/actionTypes/MasterType';
export const getMasterLeads = (payload) => async (dispatch) => {
    try {
        let results = await axios.get(`${getMasterLeadsApi}/${payload.status}/${payload.page}`, await accessToken())
        let data = results && results.data
        console.log('GET Leads', data)
        dispatch({
            type: GET_MASTER_LEADS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MASTER_LEADS_ERR,
            payload: error.response,
        });
    }
};


