import { alpha, makeStyles } from '@material-ui/core/styles';
export const styles = (theme) => ({
    pageTitle: {
        color: "#125693",
        fontWeight: "400",
    },
    root: {
        width: '100%',

    },
    //   container: {
    //     maxHeight: 440,
    //   },
    custInput: {
        '& .MuiTextField-root': {
            // margin: theme.spacing(0),
            marginBottom: "14px",
        },
        '& label': {
            marginTop: '6px',
            // marginTottom: '10px'
        }
    },
    layoutMargin: {
        // marginLeft:"20px",
        // marginRight:"20px",
    },
    custRow: {
        color: '#fff'
    },
    saveBtn: { marginTop: "8px", width: "180px", backgroundColor: '#125693', color: 'white' },
    bookingFormTitle: {
        color: "#125693",
        marginBottom: '-10px',
        marginLeft: '6px',
    },
    custRow: {
        border: "none",
        boxShadow: "none"
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        display: 'none',
    },
    pointsTable:
    {
        border: '0.5px solid #888888',
        padding: '10px',
        borderRadius: '10px',
        // height: '260px',
    },
    aligning:
    {
        padding: '10px',
        marginTop: '20px',
        marginBottom: '10px'
    },
    paddings:
    {
        padding: '10px'
    },
    fonts14:
    {
        fontSize: '14px',
        color: '#888888'

    },
    fonts14color:
    {
        fontSize: '14px',
        color: '#186DA6'

    },
    training:
    {
        backgroundColor: '#F1F8FF',
        borderRadius: '10px',
        padding: '20px'
    }

});