import { UPDATE_PROJECT_STATUS, UPDATE_PROJECT_STATUS_ERR } from '../../actionTypes/projectType'
import { updateProjectStatusApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const updateProjectStatus = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.patch(`${updateProjectStatusApi}`,payload, await accessToken())
        let data = results && results.data
        //console.log('UPDATE PROJECTS', data)
        dispatch({
            type: UPDATE_PROJECT_STATUS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PROJECT_STATUS_ERR,
            payload: error.response,
        });
    }
};


