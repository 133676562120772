import "date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import { useDebounce } from 'use-debounce';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { uploadGeoPointsApi, getGeoPointsApi } from "api/Api";
import axios from "axios";
import { accessToken } from "api/Token";
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import FailureNotification from "components/popUpNotification/FailureNotification";
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: "#673695"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: "none",
        color: "#888888",
        background: "#F2F2F2",
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UploadGeoPointDialog = (props) => {
    const classes = useStyles();
    const [key, setKey] = useState(0);
    const [debounceKey] = useDebounce(key, 1000);
    const [csvFile, setCSVFile] = useState([]);
    const [emptyCsvFile, setEmptyCsvFile] = useState(false);
    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [failureMessage, setFailureMessage] = useState("");

    const [open, setOpen] = React.useState(false);
    const [openFailure, setOpenFailure] = React.useState(false);
    const [defaultCSV, setDefaultCSV] = useState([["location", "city", "pincode", "latitude", "longitude", "radius"]]);
    const [tableLoader, setTableLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [uploadClicked, setUploadClicked] = useState(false);
    const [columns, setColumns] = useState([]);

    async function getGeoPointData() {
        setTableLoader(true);
        const res = await axios.get(`${getGeoPointsApi}`, await accessToken());
        if (res?.data?.status === true) {
            setTableLoader(false);
            setTableData(res?.data?.data);
            setColumns(Object.keys(res?.data?.data[0]));
        }
    }

    const uploadHandle = (e) => {
        setCSVFile(e[0]);
    };

    const handleNotificationClickOpen = () => {
        setOpen(true);
    };

    const handleNotificationClose = () => {
        setOpen(false);
        props.handleCloseUploadLead()
    };

    const handleFailureNotificationClickOpen = () => {
        setOpenFailure(true);
    };

    const handleFailureNotificationClose = () => {
        setOpenFailure(false);
        props.handleCloseUploadLead()
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();
        props.setSelectedOptions([])
        setUploadClicked(!uploadClicked)
        console.log('csvFile', csvFile.length)
        if (csvFile === undefined || csvFile.length === 0) {
            setEmptyCsvFile(true);
        } else {
            setEmptyCsvFile(false);
            setLoader(true);
            let formData = new FormData();
            formData.append("file", csvFile);
            console.log("checkkkk", formData);
            async function fetchData() {
                const res = await axios.post(`${uploadGeoPointsApi}`, formData, await accessToken());
                setSuccessMessage(res?.data?.message)
                if (res?.data?.status == true) {
                    setLoader(false);
                    handleNotificationClickOpen();
                    async function getGeoData() {
                        props.setDataLoader(true);
                        const res = await axios.get(`${getGeoPointsApi}`, await accessToken());
                        if (res.data.status === true) {
                            props.setDataLoader(false);
                            props.setData(res.data.data);
                        }
                    }
                    getGeoData();
                } else if (res?.data?.status == 401 || res?.data?.status == 500) {
                    setLoader(false);
                    setFailureMessage("Something Went Wrong");
                    handleFailureNotificationClickOpen()
                }
            }
            fetchData();
        }


    };

    useEffect(() => {
        getGeoPointData();
    }, [uploadClicked])

    const downloadExcel = () => {
        const data = defaultCSV;
        //console.log('newData', data)
        const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Lead Details");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download

        XLSX.writeFile(workBook, "sample-lead-fields.csv");
    };

    return (
        <Dialog
            fullScreen
            open={props.openUploadLead}
            onClose={props.handleCloseUploadLead}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
                        <div>
                            <h3>Geo Points</h3>
                        </div>
                        <IconButton edge="start" color="inherit" onClick={props.handleCloseUploadLead} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </Toolbar>
            </AppBar>
            <DialogContent>
                <Card variant="outlined" style={{ width: "50%", margin: "auto", marginTop: "30px" }} >
                    <CardContent>
                        <Grid xs={12} container alignItems="center">
                            <Grid item container justify="flex-start" sm={6}>
                                <h2>
                                    <b>Upload Lead</b>
                                </h2>
                            </Grid>
                            <Grid
                                item
                                direction="row"
                                container
                                justify="flex-end"
                                sm={6}
                            >
                                <a href="javascript:void(0)" onClick={downloadExcel} style={{ textDecoration: "none" }} > Download sample .csv format</a>
                            </Grid>
                        </Grid>

                        <Grid item container justify="flex-start" sm={12}>
                            <DropzoneArea
                                key={debounceKey}
                                initialFiles={[csvFile]}
                                showFileNames
                                onChange={(e) => uploadHandle(e)}
                                filesLimit={1}
                                showAlerts={true}
                                minSize={0}
                                maxFileSize={524288000000000000000000}
                                onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                                acceptedFiles={[
                                    ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                                ]}


                            />
                        </Grid>
                        {emptyCsvFile &&
                            <div style={{ textAlign: "center", color: "red", marginTop: 5, fontSize: "12px" }} >
                                Please upload csv file
                            </div>
                        }
                        {loader ? (
                            <LinearProgress style={{ color: "#fff" }} size={28} />)
                            : (
                                <Grid xs={12} container alignItems="center">
                                    <Grid
                                        item
                                        direction="row"
                                        container
                                        justify="center"
                                        style={{ marginTop: 5 }}
                                        sm={12}
                                    >
                                        <Button
                                            // className={classes.saveBtn}
                                            type="submit"
                                            onClick={handleSubmit2}
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            style={{ background: "#673695", color: "white" }}
                                        >
                                            Upload
                                        </Button>
                                    </Grid>

                                    <Grid
                                        item
                                        direction="row"
                                        container
                                        justify="center"
                                        // className={classes.errorMsg}
                                        sm={12}
                                    >
                                        {msg}
                                    </Grid>
                                </Grid>
                            )}

                    </CardContent>
                </Card>
                <div style={{ marginTop: "20px" }} >
                    {!tableLoader &&
                        <TableContainer size="small" aria-label="customized table" >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {(columns || []).map((item) => {
                                            return (
                                                <TableCell align="left" style={{ fontWeight: 600, background: "#eee", whiteSpace: "nowrap" }}>{item.split("_").join(" ")}</TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {(tableData || []).map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.geo_points_id}
                                            >
                                                {Object.keys(row).map(item => (
                                                    item == "updated_at" || item == "created_at" ?

                                                        (<StyledTableCell align="left" >
                                                            {moment(row[item]).format('DD-MM-YYYY')}
                                                        </StyledTableCell>) :
                                                        (<StyledTableCell align="left" style={{ wordBreak: "break-all" }} >
                                                            {row[item]}
                                                        </StyledTableCell>)
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}

                    {tableLoader === true && <div style={{ width: "100%" }} ><LinearProgress /></div>}

                    {((tableLoader === false) && (tableData.length === 0)) &&
                        <h2 style={{ textAlign: 'center', marginTop: '5%', color: '#1818184a' }}>No Data Found</h2>
                    }
                </div>

                <SuccessNotification
                    open={open}
                    msg={successMessage}
                    handleClose={handleNotificationClose}
                />
                <FailureNotification
                    open={openFailure}
                    msg={failureMessage}
                    handleClose={handleFailureNotificationClose}
                />
            </DialogContent>
        </Dialog>
    )
}

export default UploadGeoPointDialog