/***********************  ADD PROJECT ****************************************/

import { RE_ASSIGN_LEAD,RE_ASSIGN_LEAD_ERR} from '../../actionTypes/leadsType'
import { reassignLeadApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'


export const reassignLead = payload => async dispatch => {
    try {
        let results = await axios.patch(`${reassignLeadApi}`, payload, await accessToken())
         console.log("Assign LEAD", results)
        let data = results && results.data
        dispatch({
            type: RE_ASSIGN_LEAD,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: RE_ASSIGN_LEAD_ERR,
            payload: error.response
        });
    }
}

/***********************  ADD PROJECT ****************************************/