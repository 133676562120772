import "date-fns";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import { CSVLink, CSVDownload } from "react-csv";
import { DropzoneArea } from "material-ui-dropzone";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { withRouter, useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { styles } from "./AssignProjectStyle";
import SuccessNotification from "../../popUpNotification/SuccessNotification";
import FailureNotification from "../../popUpNotification/FailureNotification";
import { useForm, Form } from "../../useForm";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../../context/GlobalState";
import { assignProject } from "../../../context/actions/projectAction/assignProject";
import { newProjectUsers } from "context/actions/projectAction/getNewProjectUsers";

toast.configure();
const useStyles = makeStyles(styles);

const BookingTable = React.memo((props) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [leadsFields, setLeadsFields] = useState([]);
  const [openFailure, setOpenFailure] = React.useState(false);
  const [uploadLeadsTab, setUploadLeadsTab] = React.useState(true);
  const [createTable, setCreateTable] = React.useState(false);
  const [assignLeadsTab, setAssignLeadsTab] = React.useState(true);
  const { projectState, projectDispatch } = useContext(GlobalContext);
  let projectId =
    props.location.pathname && props.location.pathname.split("/").pop();
  const [expanded, setExpanded] = React.useState(false);
  const initialFormData = Object.freeze({});
  const [formData, updateFormData] = React.useState(initialFormData);
  const [uploadLeadsArr, setUploadLeadsArr] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let postData = [];
    selected.map((item) => {
      postData.push({
        user_id: item,
        project_id: projectId,
        project_type: "assigned",
      });
    });
    console.log(postData);

    setTimeout(() => {
      console.log(postData);
      assignProject(postData)(projectDispatch);
      setSelected([]);
    }, 500);
  };

  useEffect(() => {
    setLoader(false);

    let status =
      projectState.assignProject &&
      projectState.assignProject.data &&
      projectState.assignProject.data.status;
    // SUCCESS RESPONSE
    console.log("use effect ", status);
    if (status === true) {
      console.log("Project state", projectState);
      setSuccessMessage("Project Assigned successfully");
      handleNotificationClickOpen();
      setLoader(false);
      projectState.assignProject = {
        loading: false,
        error: null,
        data: null,
      };
      const myTimeout = setTimeout(handleNotificationClose, 2000);
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.assignProject);
      setFailureMessage("Already Project Assigned");
      handleFailureNotificationClickOpen();
      setLoader(false);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      setMessage("Worng username or password");
      console.log("Validation Error:", projectState.assignProject);
    }
  }, [projectState]);

  const assigned_columns = [
    { id: "Tasker Name", label: "Tasker Name", minWidth: 100 },
    {
      id: "location",
      label: "Tasker Mobile",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "email",
      label: "Tasker e-mail Id",
      minWidth: 60,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "motherTongue",
      label: "Mother Tongue",
      minWidth: 60,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "knownLanguages",
      label: "Known Languages",
      minWidth: 60,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "Tasker Mobile",
      label: "City",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //   id: 'action',
    //   label: 'Action',
    //   minWidth: 40,
    //   align: 'right',
    //   format: (value) => value.toFixed(2),
    // },
  ];

  const handleNotificationClickOpen = () => {
    setOpen(true);
  };

  const handleNotificationClose = () => {
    newProjectUsers({ project_id: projectId, page: "all" })(projectDispatch);
    setOpen(false);
  };

  const handleFailureNotificationClickOpen = () => {
    setOpenFailure(true);
  };

  const handleFailureNotificationClose = () => {
    setOpenFailure(false);
  };

  /*********************************GET TASKERS  ********************************/

  useEffect(() => {
    newProjectUsers({ project_id: projectId, page: "all" })(projectDispatch);
  }, []);

  useEffect(() => {
    console.log("projectStatesdfsdfsf", projectState.getNewProjectUsers.data);
    let status = projectState.getNewProjectUsers.status;
    if (status === true) {
      const verifyFiltered = projectState.getNewProjectUsers.data.filter(
        (project) => project.onboard_status == "completed"
      );
      setUsersData(verifyFiltered);

      projectState.getNewProjectUsers = {
        loading: false,
        error: null,
        data: null,
      };
    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", projectState.getNewProjectUsers);
    }
    // SERVER VALIDATION ERROR RESPONSE
    if (status === 401) {
      console.log("Validation Error:", projectState.getNewProjectUsers);
    }
  }, [projectState]);

  /*********************************GET TASKERS  ********************************/

  /**********************************ASSIGN LEADS  ****************************/

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {/* {assigned_columns.map((headCell) => {
            return headCell.key != 'created_on' && headCell.key != 'updated_on' ?
              <TableCell className={classes.custRow}
                key={headCell.key}>
                {headCell.key}
              </TableCell>
              : ''
          })} */}
          {assigned_columns.map((column) => (
            <TableCell
              className={classes.custRow}
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          ></Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              {/* <DeleteIcon /> */}
              <Button
                className={classes.saveBtn}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#125693" }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={28} />
                ) : (
                  "Assign"
                )}
              </Button>
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersData.map((n) => n.user_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  /**********************************ASSIGN LEADS  ****************************/
  return (
    <>
      <React.Fragment>
        <div>
          <Typography>
            {usersData.length > 0 ? (
              <Form onSubmit={handleSubmit}>
                <Grid className={classes.layoutMargin1}>
                  <Grid xs={12}>
                    <Card variant="outlined">
                      <CardContent className={classes.custInput}>
                        {/* <Grid md={12}
                              container
                              alignItems="center"
                              style={{ paddingBottom: '0px' }}
                            >
                              <Grid item justify="flex-start" xs={8}>
                                <h2 ><b>Assign Project</b></h2>
                              </Grid>
                              <Grid item direction="row" container xs={4}>
                          
                              </Grid>
                            </Grid> */}
                        {/* <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div> */}
                        <Grid container md={12}>
                          <Box sx={{ width: "100%" }}>
                            <TableContainer>
                              {selected.length != 0 && (
                                <EnhancedTableToolbar
                                  numSelected={selected.length}
                                />
                              )}
                              <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                              >
                                <EnhancedTableHead
                                  numSelected={selected.length}
                                  order={order}
                                  orderBy={orderBy}
                                  onSelectAllClick={handleSelectAllClick}
                                  onRequestSort={handleRequestSort}
                                  rowCount={usersData.length}
                                />

                                <TableBody>
                                  {usersData
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                      const isItemSelected = isSelected(
                                        row.user_id
                                      );

                                      const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <TableRow
                                          hover
                                          onClick={(event) =>
                                            handleClick(event, row.user_id)
                                          }
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={row.user_id}
                                          selected={isItemSelected}
                                        >
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              color="primary"
                                              checked={isItemSelected}
                                              inputProps={{
                                                "aria-labelledby": labelId,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.full_name}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.mobile_number}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.email_id}
                                          </TableCell>

                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.mother_tongue}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.languages.slice(1, -1)}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.city}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  {emptyRows > 0 && (
                                    <TableRow
                                      style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                      }}
                                    >
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <Grid container md={12}>
                              <Grid
                                item
                                xs={6}
                                className={classes.paper}
                              ></Grid>
                              <Grid item xs={6} className={classes.paper}>
                                <TablePagination
                                  rowsPerPageOptions={[25, 50, 100]}
                                  component="div"
                                  count={usersData.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <h2 style={{ textAlign: "center" }}>No Leads Founds</h2>
            )}
          </Typography>
        </div>
        <SuccessNotification
          open={open}
          msg={successMessage}
          handleClose={handleNotificationClose}
        />
        <FailureNotification
          open={openFailure}
          msg={failureMessage}
          handleClose={handleFailureNotificationClose}
        />
      </React.Fragment>
    </>
  );
});
export default withRouter(BookingTable);
