import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { styles } from "./TaskerTableStyle";
import PlusIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'
import { bookings } from "../../../store/actions/bookingAction";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import '../../../App.css'
import { Avatar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from '@material-ui/core';
import { Input } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { localRoute } from 'routes/localRoutes';
import { GlobalContext } from "../../../context/GlobalState";
import { getTaskers } from "../../../context/actions/taskerAction/getTaskers";
import Pagination from '@material-ui/lab/Pagination';
import { updateOnboardingStatus } from "context/actions/taskerAction/updateOnboardingStatus";
import SuccessNotification from "components/popUpNotification/SuccessNotification";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles(styles);
function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: '#B8B8B8',
  },
  selected: {
    color: '#186DA6',
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    color: '#888888',
    background: '#F2F2F2'
  },
  body: {
    fontSize: 14,
    //border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: 'white',

    },
  },
}))(TableRow);



const columns = [
  { id: 'UserID', label: 'User ID', minWidth: 100 },
  {
    id: 'Name',
    label: 'Name',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'mobile',
    label: 'Mobile number ',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },

  {
    id: 'Email',
    label: 'Email Id',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Whats App',
    label: 'Whats App',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: 'action',
  //   label: 'Action',
  //   minWidth: 40,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

const pendingColumns = [
  { id: 'UserID', label: 'User ID', minWidth: 100 },
  {
    id: 'Name',
    label: 'Name',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'mobile',
    label: 'Mobile number ',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },

  {
    id: 'Email',
    label: 'Email Id',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Whats App',
    label: 'Whats App',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 40,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(UserID, Name, Mobile, Location, Email, status, action) {
  return { UserID, Name, Mobile, Location, Email, status, action };
}


const BookingTable = React.memo((props) => {
  const classes = useStyles();
  const { taskerState, taskerDispatch } = useContext(GlobalContext);
  const [page, setPage] = React.useState(0);
  const [pendingPage, setPendingPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pendingrowsPerPage, setPendingRowsPerPage] = React.useState(10);
  const [myBookings, setMyBookings] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showCancel, setShowCancel] = React.useState(false);
  const [bookingItem, setBookingItem] = React.useState([]);
  const [verifyTaskerList, setVerifyTaskerList] = React.useState([]);
  const [pendingTaskerList, setPendingTaskerList] = React.useState([]);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [searched, setSearched] = useState("");
  const [verifiedFilteredData, setVerifiedFilteredData] = useState([]);
  const [pendingFilteredData, setPendingFilteredData] = useState([]);


  const requestSearch = (searchedVal) => {
    if (value === 0) {
      const filteredRows = verifyTaskerList.filter((row) => {
        return (
          row.user_id
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.full_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.mobile_number
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.email_id.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.whatsapp_number
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      });
      setVerifiedFilteredData(filteredRows);
    }
    if (value === 1) {
      const filteredRows = pendingTaskerList.filter((row) => {
        return (
          row.user_id
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.full_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.mobile_number
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row.email_id.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.whatsapp_number
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      });
      setPendingFilteredData(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleNotificationClickOpen = () => {
    setOpenNotification(true);
  };
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };

  useEffect(() => {
    console.log('use effect')
    getTaskers()(taskerDispatch)
  }, [])

  useEffect(() => {
    let status = taskerState.getTaskers.status
    if (status === true) {
      console.log("taskerState() ", taskerState.getTaskers.data)
      let verifyFiltered = taskerState.getTaskers.data.filter(project => project.onboard_status == 'completed')
      setVerifyTaskerList(verifyFiltered)
      setVerifiedFilteredData(verifyFiltered)
      let pendingFiltered = taskerState.getTaskers.data.filter(project => project.onboard_status == 'none' || project.onboard_status == '')
      setPendingTaskerList(pendingFiltered)
      setPendingFilteredData(pendingFiltered);

    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      console.log("Server Error:", taskerState.getTaskers)
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      console.log("Validation Error:", taskerState.getTaskers)
    }


    console.log("taskerState?.updateOnboarding", taskerState)
    let updateStatus = taskerState?.updateOnboarding?.status;

    if (updateStatus === true) {
      setSuccessMessage("Tasker verified successfully");
      handleNotificationClickOpen();
      getTaskers()(taskerDispatch);
      taskerState.updateOnboarding = {
        loading: false,
        error: null,
        data: [],
      }
    }

  }, [taskerState])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePendingChangePage = (event, newPage) => {
    setPendingPage(newPage);
  };
  const handlePendingChangeRowsPerPage = (event) => {
    setPendingRowsPerPage(+event.target.value);
    setPendingPage(0);
  };

  const ITEM_HEIGHT = 48;

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userId, setUserId] = useState(0);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleClick = (event, id, row) => {
    setAnchorEl(event.currentTarget);
    setUserId(id);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleVerifyTasker = () => {
    updateOnboardingStatus({ user_id: userId })(taskerDispatch)
    setAnchorEl(null);
  }

  return (
    <>
      <div style={{ backgroundColor: "white", marginTop: '-25px' }}>
        <div className={classes.root}>
          <Grid item container xs={12} style={{ marginTop: "10px" }}>
            <Grid xs={7}>
              <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: 'white', color: 'black' }} >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" >
                  <CustomTab style={{ textTransform: 'none' }} label={<b>Verified User</b>} {...a11yProps(0)} />
                  <CustomTab style={{ textTransform: 'none' }} label={<b>Pending</b>} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid xs={2}>

            </Grid>

            <Grid xs={3}>
              <SearchBar
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  borderRadius: 0,
                }}
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </Grid>

          </Grid>
          <TabPanel value={value} index={0} style={{ padding: '0px !important' }}>
            <Grid item xs={12} >
              <TableContainer >
                <Table className={classes.table} size="small" aria-label="customized table" >
                  <TableHead>
                    <TableRow className={classes.custRow} >
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {verifiedFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                          <StyledTableCell component="th" scope="row">
                            <Link to={`${localRoute.taskerDetail}/${row && row.user_id && row.user_id}`} style={{ textDecoration: "none", color: '#0047FF' }}>
                              {row.user_id}
                            </Link>
                          </StyledTableCell>

                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar src={row.profile_image} /></StyledTableCell>

                            <StyledTableCell align="left"
                              style={{
                                width: '70%',
                                color: '#000000',
                                borderBottom: 'none',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', maxWidth: '30ch', textOverflow: 'ellipsis'
                              }}
                            >
                              <label> {row.full_name}</label>
                            </StyledTableCell>

                          </StyledTableCell>

                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.mobile_number}</StyledTableCell>

                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.email_id}</StyledTableCell>
                          {console.log(row.whatsapp_number.length, "row.whatsapp_number")}
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            {row.whatsapp_number}
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '15%', color: '#000000', }}><label style={{ backgroundColor: '#00E0AA', padding: '5px', borderRadius: '10px', fontSize: '10px' }}>Verified</label></StyledTableCell>
                          {/* <StyledTableCell align="right" style={{ width: '15%' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={menuopen}
                              onClose={handleClose1}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >

                              <MenuItem onClick={handleClose1} style={{ fontSize: '12px' }}>Verify tasker</MenuItem>

                            </Menu>
                          </StyledTableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={verifiedFilteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12} >
              <TableContainer >
                <Table className={classes.table} aria-label="customized table" >
                  <TableHead>
                    <TableRow className={classes.custRow} >
                      {pendingColumns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingFilteredData.slice(pendingPage * pendingrowsPerPage, pendingPage * pendingrowsPerPage + pendingrowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          <StyledTableCell component="th" scope="row">
                            <Link to={`${localRoute.taskerDetail}/${row && row.user_id && row.user_id}`} style={{ textDecoration: "none", color: '#0047FF' }}>
                              {row.user_id}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                            <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar src={row.profile_image} /></StyledTableCell>
                            <StyledTableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.full_name}</label></StyledTableCell>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.mobile_number}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.email_id}</StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.whatsapp_number}</StyledTableCell>

                          <StyledTableCell align="center" style={{ width: '15%', color: '#000000', }}><label style={{ backgroundColor: '#FF7E7E', padding: '5px', borderRadius: '10px', fontSize: '10px' }}>Pending</label></StyledTableCell>
                          <StyledTableCell align="right" style={{ width: '15%' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              // onClick={handleClick}
                              onClick={(e) =>
                                handleClick(e, row.user_id, row)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={menuopen}
                              onClose={handleClose1}
                              className={classes.menu}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >

                              <MenuItem onClick={handleVerifyTasker} style={{ fontSize: '12px' }}>Verify tasker</MenuItem>
                            </Menu>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pendingFilteredData.length}
                rowsPerPage={pendingrowsPerPage}
                page={pendingPage}
                onPageChange={handlePendingChangePage}
                onRowsPerPageChange={handlePendingChangeRowsPerPage}
              />
            </Grid>
          </TabPanel>
        </div>
      </div>
      <SuccessNotification
        open={openNotification}
        msg={successMessage}
        handleClose={handleNotificationClose}
      />
    </>
  );
})
export default withRouter(BookingTable)
