export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_ERR = 'LOGIN_USER_ERR';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERR = 'GET_USERS_ERR';
export const GET_USER = 'GET_USER';
export const GET_USER_ERR = 'GET_USER_ERR';
export const UPDATE_PAN_STATUS = 'UPDATE_PAN_STATUS';
export const UPDATE_PAN_STATUS_ERR = 'UPDATE_PAN_STATUS_ERR';
export const UPDATE_ADHAR_STATUS = 'UPDATE_ADHAR_STATUS';
export const UPDATE_ADHAR_STATUS_ERR = 'UPDATE_ADHAR_STATUS_ERR';
export const USER_IMAGE_UPLOAD = 'USER_IMAGE_UPLOAD';
export const USER_IMAGE_UPLOAD_ERR = 'USER_IMAGE_UPLOAD_ERR '
