import 'date-fns';
import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { styles } from "./AddVoiceTestStyle";
import SuccessNotification from '../../popUpNotification/SuccessNotification'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LocalConvenienceStoreOutlined, TramRounded } from '@material-ui/icons';
import Controls from "../../controls/Controls";
import { useForm, Form } from '../../useForm';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Avatar } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(styles);
toast.configure();


const d = new Date();
const year = (d.getFullYear()).toString();
const month = ((d.getMonth()) + 101).toString().slice(-2);
const date = ((d.getDate()) + 100).toString().slice(-2);
const hours = ((d.getHours()) + 100).toString().slice(-2);
const mins = ((d.getMinutes()) + 100).toString().slice(-2);
const datenow = `${year}-${month}-${date}T${hours}:${mins}`;

const initialFValues = {
  fullName: '',
  email: '',
  mobile: '',
  altPhone: '',
  gender: 'male',
  dob: new Date(),
  area: '',
  address: '',
  area: '',
  city: '',
  street: '',
  landmark: '',
  pincode: '',
  state: '',
  patientLat: '',
  patientLong: '',
  inchargeName: '',
  inchargePhone: '',
  inchargeEmail: '',
  inchargeDesignation: '',
  testName: '',
  testDescription: '',
  patientDescription: '',
  booking_date: datenow,
  isPermanent: false,
}


export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [bookings, setBookings] = useState([])
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [successMessage, setSuccessMessage] = useState("");
  let date = Moment().toDate()
  console.log("Date**", date)
  const [address, setAddress] = useState('');
  const [addressErr, setAddressErr] = useState(false);
  const [patientLat, setPatientLat] = useState('');
  const [patientLong, setPatientLong] = useState('');
  const [open, setOpen] = React.useState(false);




  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('fullName' in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required."
    if ('email' in fieldValues)
      temp.email = (/$^|.+@.+..+/).test(fieldValues.email) && fieldValues.email ? "" : "Email is not valid."
    if ('mobile' in fieldValues)
      temp.mobile = fieldValues.mobile.length > 9 ? "" : "Min 10 numbers required."
    if ('address' in fieldValues)
      console.log('address', fieldValues.address)
    setAddressErr(values.address ? false : true)
    if ('area' in fieldValues)
      temp.area = fieldValues.area ? "" : "This field is required."
    if ('city' in fieldValues)
      temp.city = fieldValues.city ? "" : "This field is required."
    if ('pincode' in fieldValues)
      temp.pincode = fieldValues.pincode ? "" : "This field is required."
    if ('state' in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required."
    if ('inchargeName' in fieldValues)
      temp.inchargeName = fieldValues.inchargeName ? "" : "This field is required."
    if ('inchargeEmail' in fieldValues)
      temp.inchargeEmail = (/$^|.+@.+..+/).test(fieldValues.inchargeEmail) && fieldValues.inchargeEmail ? "" : "Email is not valid."
    if ('inchargePhone' in fieldValues)
      temp.inchargePhone = fieldValues.inchargePhone?.length > 9 ? "" : "Min 10 numbers required."
    if ('testName' in fieldValues)
      temp.testName = fieldValues.testName ? "" : "This field is required."
    if ('booking_date' in fieldValues)
      temp.booking_date = fieldValues.booking_date ? "" : "This field is required."

    setErrors({
      ...temp
    })

    if (fieldValues == values)
      return Object.values(temp).every(x => x == "")
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handlenumberChange,
    handleaddressChange,
    handleCapture,
    resetForm
  } = useForm(initialFValues, true, validate);



  const handleSubmit = e => {
    e.preventDefault()
    if (validate()) {
      console.log(values)
      setLoader(true)
      let postData = {
        dx_id: "1",
        dx_amount: 500,
        patient_name: values.fullName,
        patient_phone_one: values.mobile,
        patient_phone_two: values.altPhone,
        patient_email: values.email,
        patient_dob: values.dob,
        patient_gender: values.gender,
        patient_address: values.address,
        patient_area: values.area,
        patient_street: values.street,
        patient_landmark: values.landmark,
        patient_pincode: values.pincode,
        patient_city: values.city,
        patient_state: values.state,
        patient_lat: values.patientLat,
        patient_long: values.patientLong,
        patient_test_name: values.testName,
        patient_test_description: values.testDescription,
        patient_description: values.patientDescription,
        patient_incharge_name: values.inchargeName,
        patient_incharge_phone: values.inchargePhone,
        patient_incharge_email: values.inchargeEmail,
        patient_incharge_designation: values.inchargeDesignation,
        booking_date: values.booking_date,
      }
      console.log(postData)
      setTimeout(() => {
        //dispatch(createBooking(postData));
        resetForm()
      }, 2000)
    }
  }

  const handleNotificationClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log(address)
    values.address = address.label
    geocodeByAddress(address && address.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setAddressErr(false)
        setPatientLat(lat)
        setPatientLong(lng)
      }
      );
    geocodeByAddress();
  }, [address])


  const languageCollection = () => ([
    { id: '1', title: 'English' },
    { id: '2', title: 'Tamil' },
    { id: '3', title: 'Kannada' },
    { id: '4', title: 'Hindi' },
    { id: '5', title: 'Telugu' },

  ])

  const pointsCollection = () => ([
    { id: '1', title: '0 Points' },
    { id: '2', title: '1 Points' },
    { id: '3', title: '2 Points' },
    { id: '4', title: '3 Points' },
    { id: '5', title: '4 Points' },
    { id: '6', title: '5 Points' },

  ])



  return (
    <React.Fragment>
      <Grid container xs={12} style={{ margin: '10px' }}>
        <Grid xs={10}>
          <div style={{ color: '#888888' }}>
            Voice test  /  Add voice test
          </div>

        </Grid>
        <Grid xs={2} >
          <Button style={{ color: '#186DA6', float: 'right', textTransform: 'none' }} onClick={event => window.location.href = '/voice-test'}>
            <ArrowBackIcon />
            <b>Back</b>

          </Button>

        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit}>
        <Grid className={classes.layoutMargin1}>
          <Card variant="outlined">
            <CardContent className={classes.custInput}>
              <Grid xs={12} container >
                <Grid justify="flex-start" xs={10}>
                  <h2 ><b>Add voice test</b></h2>
                </Grid>
                <Grid justify="flex-end" xs={2}>
                  <div>
                    <Button className={classes.saveBtn} type="submit" size="large" variant="contained" color="primary" >
                      {
                        loader ? <CircularProgress style={{ color: '#fff' }} size={28} /> : "Save"
                      }
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div>
              <Grid container xs={12} >
                <Grid item xs={6} className={classes.paper}>
                  <Controls.Select
                    name="language"
                    label="Languages"
                    fullWidth
                    value={values.jobCategory}
                    onChange={handleInputChange}
                    options={languageCollection()}
                    error={errors.jobCategory}
                  />
                </Grid>
                <Grid item xs={4} className={classes.paper}>
                  <Controls.Input
                    label="Upload PDF"
                    name="upload"
                    value={values.fileupload}
                    onChange={handleInputChange}
                    error={errors.fileupload}
                  />
                </Grid>
                <Grid item xs={2} className={classes.paper}>
                  <Button className={classes.saveBtn} size="large" variant="contained" color="primary" >
                    Upload
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h4 style={{ color: '#186DA6' }}>Upload video</h4>
                  <Paper className={classes.paper} elevation={0} className={classes.pointsTable} style={{ backgroundColor: '#F2F2F2' }}>
                    <Grid container style={{ textAlign: 'center' }}>
                      <Grid item xs={12} >
                        <GetAppIcon style={{ color: '#B8B8B8', width: '85px', height: '85px', justifyContent: "center", textAlign: 'center', marginTop: 25 }} />
                      </Grid>
                    </Grid>
                    <div className={classes.fonts} style={{ marginTop: '10px', textAlign: 'center', color: '#888888' }}> Upload project video </div>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <h4 style={{ color: '#186DA6' }}>Project Script</h4>
                  <Controls.TextArea
                    name="ProjectScript"
                    label="Enter Project Script"
                    value={values.ProjectScript}
                    onChange={handleInputChange}
                    error={errors.ProjectScript}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Form>
      <SuccessNotification
        open={open}
        msg={successMessage}
        handleClose={handleNotificationClose}
      />
    </React.Fragment>
  );
}
