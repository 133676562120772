import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styles } from "./LeadsDetailStyle";
import PlusIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from "react-redux";
import { bookings } from "../../../store/actions/bookingAction";
import '../../../App.css'
import aadhaar from '../../../assets/images/aadhaar.jpeg'
import { Avatar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { CardMedia } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import '../../../App.css'
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Input } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { localRoute } from 'routes/localRoutes';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles({
  root: {
    color: '#B8B8B8',
  },
  selected: {
    color: '#186DA6',
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    color: '#888888',
    background: '#F2F2F2'
  },
  body: {
    fontSize: 14,
    //border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: 'white',

    },
  },
}))(TableRow);






const leads_columns = [
  { id: 'UserID', label: 'User ID', minWidth: 100 },

  {
    id: 'Name',
    label: 'Name',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Number',
    label: 'Mobile number ',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Datetime',
    label: 'Date & time',
    minWidth: 60,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'CallDuration',
    label: 'Call Duration',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 40,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];



function createData(UserID, Name, Mobile, Location, Email, status, action) {
  return { UserID, Name, Mobile, Location, Email, status, action };
}


const BookingTable = React.memo((props) => {
  const classes = useStyles();  
  const [myBookings, setMyBookings] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [bookingItem, setBookingItem] = React.useState([]);
  const [showCancel, setShowCancel] = React.useState(false);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows_active = [
    createData('100', 'Jacob Mathew', '+91 9876543210', '5.8.2021 - 10:00am', '00:10:00', 'action'),
    createData('100', 'Jacob Mathew', '+91 9876543210', '5.8.2021 - 10:00am', '00:10:00', 'action'),

  ];


  const ITEM_HEIGHT = 48;


  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuopen = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };



  return (
    <>
      <Grid container xs={12} style={{ margin: '10px' }}>
        <Grid xs={8}>
          <div style={{ color: '#888888' }}>
            Leads  /  Lead ID 100
          </div>

        </Grid>
        <Grid xs={4} >
          <Button style={{ color: '#186DA6', float: 'right', textTransform: 'none' }} onClick={event => window.location.href = '/leads'}>
            <ArrowBackIcon />
            <b>Back</b>

          </Button>

        </Grid>
      </Grid>
      <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '10px', }}>
        <Grid xs={12}
          container
          alignItems="center"
          style={{ paddingBottom: '0px' }}
        >
          <Grid item container justify="flex-start" sm={6}>
            <h2 className={classes.pageTitle}><b>Lead ID 100</b></h2>
          </Grid>
          <Grid item direction="row" container justify="flex-end" sm={6}>
          </Grid>
        </Grid>
        <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div>
        <Grid item container xs={12} >
          <Grid xs={1} style={{ justifyContent: "center", display: "flex" }}>
            <Avatar style={{ width: '80px', height: '80px', justifyContent: "center", display: "flex" }} />
          </Grid>
          <Grid xs={10} style={{ marginTop: '15px' }}>
            <div style={{ fontSize: '16px' }}><b>David Jacob</b>&nbsp;&nbsp;<label style={{ border: '0.5px solid #00E0AA', padding: '5px', borderRadius: '20px', fontSize: '12px', color: 'black', backgroundColor: '#00E0AA' }}>Offer Accepted</label></div>
            <div className={classes.fonts}>+91 9876543210</div>
          </Grid>

        </Grid><br />


        <Grid item container xs={12} className={classes.aligning}>
          <Grid xs={3}>
            <label className={classes.fonts}><b>Date of birth</b></label><br />
            <label className={classes.fonts}><b>Email ID</b></label><br />
            <label className={classes.fonts}><b>Location</b></label><br />
          </Grid>
          <Grid xs={3}>
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;4.8.2021 - 10.8.2021</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;jacob@gmail.com</label> <br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;Bangalore</label><br />

          </Grid>
          <Grid xs={3}>

            <label className={classes.fonts}><b>Mobile number </b></label><br />
            <label className={classes.fonts}><b>Exhibition Name </b></label><br />
            <label className={classes.fonts}><b>Pincode </b></label><br />
          </Grid>
          <Grid xs={3}>

            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;+91 9876543210</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;Male</label><br />
            <label className={classes.fonts}>&nbsp;&nbsp; <b>:</b> &nbsp;&nbsp;5678910</label><br />
          </Grid>
        </Grid>
        <div style={{ borderTop: "1px solid #B8B8B8 ", marginBottom: '10px', margin: '10px' }}></div>
        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Documents</h4>
        </Grid>
        <Grid container spacing={3} className={classes.paddings}  >

          <Grid item xs={3}>

            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={aadhaar} />

                </Grid>

              </Grid>
              <div style={{ fontSize: '14px', marginBottom: '10px', color: 'black' }}><b>Adhaar Card Font View</b> </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>

            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={aadhaar} />

                </Grid>

              </Grid>
              <div style={{ fontSize: '14px', marginBottom: '10px', color: 'black' }}><b>Adhaar Card Back View</b> </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>

            <Paper className={classes.paper} elevation={0} >
              <Grid container>
                <Grid item xs={12} >
                  <img src={aadhaar} />

                </Grid>

              </Grid>
              <div style={{ fontSize: '14px', marginBottom: '10px', color: 'black' }}><b>Pan card</b> </div>
            </Paper>
          </Grid>



        </Grid>


        <Grid xs={12} className={classes.paddings}>
          <h4 style={{ color: '#186DA6' }}>Assigned Tasker</h4>
        </Grid>
        <div className={classes.root}>


          <Grid item xs={12} >
            <TableContainer >
              <Table className={classes.table} aria-label="customized table" >
                <TableHead>
                  <TableRow className={classes.custRow} >
                    {leads_columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows_active.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        <StyledTableCell component="th" scope="row">
                          <Link style={{ textDecoration: "none", color: '#0047FF' }}>
                            {row.UserID}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>
                          <StyledTableCell align="left" style={{ width: '30%', color: '#000000', borderBottom: 'none', padding: '0px' }}><Avatar /></StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '70%', color: '#000000', borderBottom: 'none' }}><label> {row.Name}</label></StyledTableCell>
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Mobile}</StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '20%', color: '#000000' }}>{row.Location}</StyledTableCell>
                        <StyledTableCell align="left" style={{ width: '15%', color: '#000000' }}>{row.Email}</StyledTableCell>


                        <StyledTableCell align="right" style={{ width: '15%' }}>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={menuopen}
                            onClose={handleClose1}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >

                            <MenuItem onClick={handleClose1} style={{ fontSize: '12px' }}>Verify tasker</MenuItem>

                          </Menu>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows_active.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

        </div>
      </div>
    </>
  );
})
export default withRouter(BookingTable)
