import { GET_TASKERS, GET_TASKERS_ERR } from '../../actionTypes/taskerType'
import { getUsersApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getTaskers = (payload) => async (dispatch) => {
    try {
        
        let results = await axios.get(`${getUsersApi}`, await accessToken())
        let data = results && results.data
        console.log('GET Taskers', data)
        dispatch({
            type: GET_TASKERS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_TASKERS_ERR,
            payload: error.response,
        });
    }
};


