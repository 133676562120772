export default {
  users: {
    loading: false,
    error: null,
    data: [],
  },
  addUser: {
    loading: false,
    error: null,
    data: [],
  },
  removeUser: {
    loading: false,
    error: null,
    data: [],
  },
  editUser: {
    loading: false,
    error: null,
    data: [],
  },
  userImageUpload: {
    loading: false,
    error: null,
    data: []
  }
};
