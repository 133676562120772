import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AddProjectStyle";
import AddProject from "../../../components/project/addProject";
const Project = React.memo((props) => {
  return (
    <>
     <AddProject />
    </>
  );
});

export default withRouter(Project);
